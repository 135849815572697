import { Dictionary, reduce } from "lodash"

// if returns untruthy value. item will not be added to the dictionary
export function dictBy<T>(arr: T[], predicate: (v: T) => string | false | undefined | null) {
    return reduce(
        arr,
        (acc: Dictionary<T>, v: T) => {
            const key = predicate(v)
            if (key) {
                acc[key] = v
            }

            return acc
        },
        {}
    )
}

export function dictByValue<T, V>(
    arr: T[],
    predicates: {
        keyBy: (v: T) => string
        valueBy: (v: T) => V
    }
) {
    const { keyBy, valueBy } = predicates
    return reduce(
        arr,
        (acc: Dictionary<V>, v: T) => {
            const key = keyBy(v)
            if (key) {
                acc[key] = valueBy(v)
            }

            return acc
        },
        {}
    )
}

export function mapBy<K, T>(arr: T[], predicate: (v: T) => K) {
    const map = new Map<K, T>()
    for (const v of arr) {
        map.set(predicate(v), v)
    }
    return map
}

export function mapValueBy<K, T, V>(
    arr: T[],
    predicates: {
        keyBy: (v: T) => K
        valueBy: (v: T) => V
    }
) {
    const map = new Map<K, V>()
    for (const v of arr) {
        map.set(predicates.keyBy(v), predicates.valueBy(v))
    }
    return map
}
