import { $enum } from "ts-enum-util"

export enum LANGUAGE {
    CHINESE = "chinese",
    ENGLISH = "english",
    SPANISH = "spanish"
}

export const LANGUAGE_ABBR = {
    [LANGUAGE.CHINESE]: "CN",
    [LANGUAGE.ENGLISH]: "EN",
    [LANGUAGE.SPANISH]: "ES"
}

export enum LANGUAGE_CODE {
    CHINESE_SIMPLIFIED = "zh_CN",
    CHINESE_TRADITIONAL = "zh_HK",
    SPANISH = "es",
    ENGLISH = "en"
}

export const LANGUAGE_ARR = $enum(LANGUAGE).getValues()
export const LANGUAGE_CODE_ARR = $enum(LANGUAGE_CODE).getValues()

export const LANGUAGE_ID = {
    [LANGUAGE_CODE.CHINESE_SIMPLIFIED]: "1",
    [LANGUAGE_CODE.CHINESE_TRADITIONAL]: "2",
    [LANGUAGE_CODE.SPANISH]: "3",
    [LANGUAGE_CODE.ENGLISH]: "4"
}

// we can't display zh_CN and zh_HK in the app
export const LANGUAGE_CODE_DISPLAY = {
    [LANGUAGE_CODE.CHINESE_SIMPLIFIED]: "CN-S",
    [LANGUAGE_CODE.CHINESE_TRADITIONAL]: "CN-T",
    [LANGUAGE_CODE.SPANISH]: "ES",
    [LANGUAGE_CODE.ENGLISH]: "EN"
}
