type Fn = ((...args: any[]) => void) | ((...args: any[]) => Promise<void>)

export default function debounce(inner: Fn, ms = 0) {
    let timer: NodeJS.Timeout | undefined = undefined
    let resolves: ((result: any) => void)[] = []
    let rejects: ((reason: any) => void)[] = []

    let isRunning = false

    return function (...args: any[]) {
        if (isRunning) {
            return new Promise((resolve, reject) => {
                resolves.push(resolve)
                rejects.push(reject)
            })
        }

        // Run the function after a certain amount of time
        if (timer) {
            clearTimeout(timer)
            timer = undefined
        }
        timer = setTimeout(async () => {
            // Get the result of the inner function, then apply it to the resolve function of
            // each promise that has been created since the last time the inner function was run
            try {
                isRunning = true
                const result = await inner(...args)
                resolves.forEach(r => r(result))
            } catch (e) {
                rejects.forEach(r => r(e))
            } finally {
                resolves = []
                rejects = []
                isRunning = false
            }
        }, ms)

        return new Promise((resolve, reject) => {
            resolves.push(resolve)
            rejects.push(reject)
        })
    }
}
