export enum GAME_INSTANCE_TRACKED_STAT {
    PARROT_CORRECT_LISTENING = "PARROT_CORRECT_LISTENING",
    PARROT_CORRECT_SPEAKING = "PARROT_CORRECT_SPEAKING",
    PARROT_SECONDS_REMAINING = "PARROT_SECONDS_REMAINING",
    GOAL_CORRECT = "GOAL_CORRECT",
    GOAL_SECONDS_REMAINING = "GOAL_SECONDS_REMAINING"
}

export interface AppTuningLevelInfo {
    xp: number
    bonusCoins: number
}

export interface AppTuningReward {
    trackedStat: GAME_INSTANCE_TRACKED_STAT
    pts: string
    xp: string
    coins: string
    chainedPercentageBonus?: AppTuningReward
}

export interface AppTuningParrotMode {
    secondsPerTurn: number
    duringGame: {
        correctListening: AppTuningReward
        correctListeningTime: AppTuningReward
        correctSpeaking: AppTuningReward
    }
    endOfGameBonuses: {
        correctListening: AppTuningReward
        correctListeningTime: AppTuningReward
        correctSpeaking: AppTuningReward
    }
}

export interface AppTuningQuest {
    secondsPerGoal: number
    duringGame: {
        correct: AppTuningReward
    }
    endOfGameBonuses: {
        correct: AppTuningReward
        time: AppTuningReward
    }
}

export interface AppTuningReading {
    maxBooksPerWeek: number

    // these are only rewarded the first time the user takes a test
    flat: {
        xp: number
        coin: number
    }
    // these numbers are used when the user has never taken the test before
    // the percentage the user scored is multiplied by these numbers
    // for example, if the user scores 0.5 (50%) on a test they've never taken before,
    // they will be rewarded with ceil(0.5 * 10) coins, which will be 5
    percentage: {
        xp: number
        coin: number
    }
    // these numbers only apply when the user scores 100% on a test
    // (and they've never scored 100% on the same test previously)
    fullCorrect: {
        xp: number
        coin: number
    }
}

export interface AppTuningWriting {
    flat: {
        xp: number
        coins: number
    }
    percentage: {
        coin: number
        xp: number
    }
    fullCorrect: {
        coin: number
        xp: number
    }
}

export interface AppTuning {
    maxXpToMaxStar: string
    percXpToStars: number[]
    level: AppTuningLevelInfo[]
    parrotMode: AppTuningParrotMode
    quest: AppTuningQuest
    reading: AppTuningReading
    writing: AppTuningWriting
    phonics: {
        coins: { min: number; max: number }
        xp: { min: number; max: number }
    }
}
