type EventBusListenerFn<T> = (value: T) => any

export class EventBus<T> {
    private onListeners: EventBusListenerFn<T>[] = []
    private onceListeners: EventBusListenerFn<T>[] = []

    emit = (value: T) => {
        this.onListeners.forEach(fn => {
            try {
                fn(value)
            } catch (e) {
                console.error(e)
            }
        })
        this.onceListeners.forEach(fn => {
            try {
                fn(value)
            } catch (e) {
                console.error(e)
            }
        })
        this.onceListeners = []
    }

    on = (fn: EventBusListenerFn<T>) => {
        this.onListeners.push(fn)
        return () => this.off(fn)
    }

    once = (fn: EventBusListenerFn<T>) => {
        this.onceListeners.push(fn)
        return () => this.off(fn)
    }

    off = (fn: EventBusListenerFn<T>) => {
        this.onListeners = this.onListeners.filter(v => v !== fn)
        this.onceListeners = this.onceListeners.filter(v => v !== fn)
    }
}
