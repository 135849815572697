import { BaseRecord, District, Faculty, GameGroup, PeopleProfile, School, Student } from "."

export enum ROSTER_SOURCE {
    CLEVER = "clever",
    CLASSLINK = "classlink"
}

export enum ROSTER_OBJECT_TYPE {
    DISTRICT = "district",
    SCHOOL = "school",
    STUDENT_GROUP = "studentGroup",
    STUDENT = "student",
    FACULTY = "faculty",
    USER = "user"
}

// can we derive/compute these states rather than assigning them to the RosterLink object?

// UNLINKED_CAN_LINK_BY_CREATE: id is blank (indicating no link), target is blank
// UNLINKED_CAN_LINK_BY_MANUAL_ASSOCIATION: same as above
// UNLINKED_CAN_LINK_BY_AUTO_MATCH: id is blank, target is blank, match is populated
// UNLINKED_CANNOT_LINK: id is blank, target is blank
// LINKED_NO_CHANGE: id is populated, diff_object() function (yet to be written) returns false between source/target
// LINKED_CHANGE: id is populated, diff_object() function (yet to be written) returns true between source/target

export enum ROSTER_OBJECT_STATE {
    UNLINKED = "UNLINKED", // link id is "" and target is not populated
    MATCH_FOUND = "MATCH_FOUND", // link id is "" and target IS populated
    PROBLEM = "PROBLEM", // link.unresolvable is not RESOLVABLE.OK
    LINKED_NO_CHANGE = "LINKED_NO_CHANGE", // link id is populated, target is populated, change is false
    LINKED_CHANGE = "LINKED_CHANGE", // link is is populated, target is populated, change is true
    LOCAL_MISSING = "LOCAL_MISSING", // link was defined but the target it refers to does not exist
    DELETED_BY_REMOTE = "DELETED_BY_REMOTE" // link was defined but the source it refers to no longer exists
}

export enum ROSTER_PROBLEM {
    OK = "OK",
    SOURCE_DUPLICATE = "PROBLEM_DUPLICATE_SOURCE_OBJECTS",
    MULTIPLE_MATCH = "PROBLEM_MATCHED_MULTIPLE_LOCAL",
    FIELD_CONFLICT = "PROBLEM_FIELD_CONFLICT",
    FIELD_INVALID = "PROBLEM_FIELD_INVALID",
    INCOMPLETE_RECORD = "INCOMPLETE_RECORD",
    PAID_SEATS_EXCEEDED = "PROBLEM_PAID_SEATS_EXCEEDED",
    PAID_SEATS_NOT_SETUP = "PROBLEM_PAID_SEATS_NOT_SETUP",
    ORPHAN_RECORD = "PROBLEM_ORPHAN_RECORD",
    DESCENDANT_PROBLEMS = "PROBLEM_DESCENDANT",
    EMAIL_MISSING = "PROBLEM_EMAIL_MISSING"
}

export interface RosterMeta {
    district: number
    school: number
    studentGroup: number
    student: number
    faculty: number
}

export interface RosterLink {
    // db id of roster_objectlink record
    id: string

    // is this a district, school, group, student, or faculty?
    type: ROSTER_OBJECT_TYPE

    // where did we get the source data from? clever, classlink?
    sourceName: ROSTER_SOURCE

    // what is the "source" of this object?
    // that is, what is the incoming data? (from classlink, clever, etc)
    sourceId: string
    source?: RosterObject

    // adding this for broad filtering
    sourceDistrictId: string

    // what is the "target" of this object?
    // that is, if we've found a link, what is the local db equivalent of the remote data?
    targetId: string
    target?: RosterObject

    // the idea here is to represent what's incoming from the remote source
    // but with relationships using local ids (so school id, group ids, etc, are the local ones,
    // but represent the changes coming in from the remote source)
    // so the lookup on those things only needs to be done once during the diff, and
    // any operations afterwards can use the values without needing to do additional lookups
    incomingLocal?: RosterObject

    // what are the total number of descendants this object has?
    metaTotal?: RosterMeta

    // what is the "linked" status of descendants of this object?
    // (ie, if this is a district, how many linked students does it have?)
    metaLinked?: RosterMeta

    // information about descendants and their unresolved states
    metaProblems?: RosterMeta

    // is there a problem with this object (or, in the case of paid seats, its children)?
    problem: ROSTER_PROBLEM

    // what other objects does the staff user need in order to inspect/resolve this conflict?
    // (ie, if two students' emails collide, list the other student here)
    problemContext: RosterObject[]

    // if this object contains a link (id and target fields are populated), did anything change between the
    // remote and local data?
    change: boolean

    // if this object was deleted by the remote source
    deleted: boolean

    // a match for the source object
    match?: RosterObject
}

export interface RosterLinkCollection {
    [ROSTER_OBJECT_TYPE.DISTRICT]: RosterLink[]
    [ROSTER_OBJECT_TYPE.SCHOOL]: RosterLink[]
    [ROSTER_OBJECT_TYPE.STUDENT_GROUP]: RosterLink[]
    [ROSTER_OBJECT_TYPE.STUDENT]: RosterLink[]
    [ROSTER_OBJECT_TYPE.FACULTY]: RosterLink[]
}

export interface RosterObject {
    district?: RosterDistrict
    school?: School
    studentGroup?: GameGroup
    user?: PeopleProfile
    student?: Student
    faculty?: Faculty
    id: string
    type: ROSTER_OBJECT_TYPE
    meta?: RosterMeta
}

export interface RosterObjectCollection {
    district: RosterObject[]
    school: RosterObject[]
    studentGroup: RosterObject[]
    student: RosterObject[]
    faculty: RosterObject[]
}

export interface RosterObjectIdAndType extends BaseRecord {
    type: ROSTER_OBJECT_TYPE
}
export interface RosterSourceDataRaw {
    id: string
    source: ROSTER_SOURCE
    json: string
}

export interface RosterDiff {
    unassociated: RosterLink[]
    changes: RosterLink[]
}

export interface RosterDistrict extends District {
    pauseStartMs: number
    pauseEndMs: number
}

export enum ROSTER_FILTER {
    SCHOOL_NAME = "SCHOOL_NAME",
    GROUP_NAME = "GROUP_NAME",
    USER_NAME = "USER_NAME",
    USER_ROLE = "USER_ROLE",
    USER_EMAIL = "USER_EMAIL",
    USER_GRADE = "USER_GRADE",
    USER_REFERENCE_ID = "USER_REFERENCE_ID",
    USER_COUNT = "USER_COUNT",
    STATUS = "STATUS",
    LINKED = "LINKED",
    PROBLEM = "PROBLEM"
}
export interface RosterPageFilter {
    field: ROSTER_FILTER
    value: string
}

export const rosterStateExplainations = {
    [ROSTER_OBJECT_STATE.LOCAL_MISSING]: "Local target missing",
    [ROSTER_OBJECT_STATE.LINKED_CHANGE]: "Linked",
    [ROSTER_OBJECT_STATE.LINKED_NO_CHANGE]: "Linked",
    [ROSTER_OBJECT_STATE.UNLINKED]: "Not Linked",
    [ROSTER_OBJECT_STATE.PROBLEM]: "Problem Found",
    [ROSTER_OBJECT_STATE.MATCH_FOUND]: "Match Found",
    [ROSTER_OBJECT_STATE.DELETED_BY_REMOTE]: "Deleted by remote"
}
