export interface LogWithFnName {
    info: (...args: any) => void
    error: (...args: any) => void
    err: (msg: string, name?: string) => Error
}
export abstract class ClassWithLog {
    protected log = (...args: any) => console.log(this.className, ...args)
    protected logError = (...args: any) => console.error(this.className, ...args)
    protected logWithFnName = (fnName: string): LogWithFnName => {
        return {
            info: (...args: any) => this.log(`${fnName}::`, ...args),
            error: (...args: any) => this.logError(`${fnName}::`, ...args),
            err: (msg: string, name?: string) => this.err(`${fnName}::${msg}`, name)
        }
    }
    protected err = (msg: string, name?: string) => {
        const error = new Error(msg)
        if (name) {
            error.name = name
        }
        if (error.stack) {
            const arr = error.stack.split("\n")
            arr.splice(1, 1)
            error.stack = arr.join("\n")
        }
        return error
    }

    abstract className: string
}
