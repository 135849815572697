import { PHONICS_PRODUCT_IDS, Product } from "../../types"

export const isPhonicsProduct = (product: Product) => {
    if (product.relatedCourses?.find(c => c.slug.includes("phonics"))) {
        return true
    }
    return !!PHONICS_PRODUCT_IDS.map(v => v.toString()).includes(product.id)
}

export const getProductType = (product: Product) => {
    if (isPhonicsProduct(product)) return "phonics"
    return product.skill || "reading"
}
