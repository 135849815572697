import Axios from "axios"
import { configure as mobxConfigure } from "mobx"
import { setRootDeviceClass } from "./helpers/device"

/* make axios send cookies by default */
Axios.defaults.withCredentials = true

/* turn off strict mode before we do anything. Otherwise, some value changes may not be applied */
mobxConfigure({
    enforceActions: "never"
})

/* set the device classes on the root body element */
setRootDeviceClass()
window.addEventListener("resize", () => setRootDeviceClass())
window.addEventListener("orientationchange", () => setRootDeviceClass())

export const main = () => import("./main")
main()
