import { AvatarData } from "./avatarTypes"
import { BaseRecord, LANGUAGE, LANGUAGE_CODE, PaidSeatsCourse, PaidSeatsProduct, Product } from "./baseTypes"
import { Course, COURSE_TYPE } from "./learningTypes"
import { PeopleSegment } from "./peopleTypes"

export type BATTLEMODE_TEAM_NAME = "RED" | "BLUE" | "NONE"
export type BATTLEMODE_TIER = "1" | "2" | "3" | "NONE"

export enum FACULTY_MEMBERSHIP_TYPE {
    ADMINISTRATOR = "administrator",
    DEFAULT = "default"
}

export interface GamesPlayedRecord {
    played: number
    won: number
    ptsEarnedLifetime: number
}

export interface PeopleProfileQuizReward extends BaseRecord {
    createdAtMs: number
    updatedAtMs: number
    percentageScored: number
    xpAwarded: number
    coinsAwarded: number
    profileXpAfterReward: number
    profileCoinsAfterReward: number
    assessmentId: string
    profileId: string
    numQuizRewardsRemainingThisWeek: number
}

export interface PeopleProfile extends BaseRecord {
    dateCreated: string
    name: string
    avatarData: AvatarData
    xp: number // determines level
    coins: number // can buy stuff from this
    firstName?: string
    lastName?: string
    email?: string | null
    username?: string
    forcePasswordChange?: boolean
    referenceId?: string
    isStaff?: boolean
    isSuperuser?: boolean
    isActive?: boolean
    password?: string
    allowDemoAccountCreation: boolean
    isOnline: boolean
    multiplayerPartyId: string | null
    currentGameId: string | null
    currentPath: string | null
}

export interface PeopleProfileGroup extends BaseRecord {
    profileId: string
    groupId: string
}

export interface AuthGroup extends BaseRecord {
    name: string
}

export interface PeopleProfileWithHeartbeat extends PeopleProfile {
    dateHeartbeatMs: number
}

export interface Student extends PeopleProfile {
    classroomId: string
    districtId: string
    district?: District
    schoolId: string | null
    groupIds: string[]
    groups?: GameGroup[]
    school?: School
    grade?: string
    gradeId?: string
    uuid?: string
    segmentIds?: string[]
    segments?: PeopleSegment[]
    cohort: number | null
}

export interface MasteryLevel {
    studentId: string
    courseId: string
    topicId: string
    topicName: string
    courseType: COURSE_TYPE
    courseDisplayName: string
}

// faculty has the same data as student...for now
// this exists so we don't call Faculty objects a Student and cause confusion
// we may need to either a) extend this or b) completely separate it from Student at some point
export interface Faculty extends Omit<Student, "school" | "schoolId" | "districtId"> {
    displayName?: string
    schoolIds: string[]
    schools?: School[]
    schoolAdmin?: string[]
    districtAdmin?: string[]
    uuid: string
    slug?: string
    districtIds: string[]
    districts?: District[]
}

export enum NOTIFICATION_TYPE {
    NONE = "NONE",
    MESSAGE = "MESSAGE",

    CALL_INVITE = "CALL_INVITE",
    CALL_ACCEPT = "CALL_ACCEPT",
    CALL_NEGOTIATE = "CALL_NEGOTIATE",
    CALL_RESTART = "CALL_RESTART",

    MULTIPLAYER_SESSION_INVITE = "MULTIPLAYER_SESSION_INVITE",
    PLAYER_LEFT_MULTIPLAYER_SESSION = "PLAYER_LEFT_MULTIPLAYER_SESSON",
    REWARD = "REWARD",

    GAME_INVITE = "GAME_INVITE",
    PLAYER_LEFT_GAME = "PLAYER_LEFT_GAME",

    LEVEL_UP_REWARD_STATE = "LEVEL_UP_REWARD_STATE",
    WHATS_NEW = "WHATS_NEW",
    GENERIC = "GENERIC",

    ASSIGNMENT_GRADED = "ASSIGNMENT_GRADED"
}

export enum ROSTERING_NOTIFICATION_TYPE {
    STATUS_UPDATE_START = "STATUS_UPDATE_START",
    STATUS_UPDATE_END = "STATUS_UPDATE_END",
    STATUS_UPDATE_ERROR = "STATUS_UPDATE_ERROR",
    ERROR_PAID_SEATS = "ERROR_PAID_SEATS",
    ERROR_MERGE_CONFLICT = "ERROR_MERGE_CONFLICT"
}

export enum LEVEL_UP_REWARD_TYPE {
    GAME_COINS = "GAME_COINS"
}

export interface NotificationMessage {
    body: {
        text: string
        urlImage?: string
        urlEmbed?: string
    }
    subject: string
}

export interface NotificationRosteringPayload {
    type: ROSTERING_NOTIFICATION_TYPE
    schoolName?: string
    districtName?: string
    message: string
}

export interface MultiplayerSessionInvitePayload {
    languageCode: LANGUAGE_CODE
    message: string
}

export interface LevelUpReward {
    type: LEVEL_UP_REWARD_TYPE
    value: number
}

export interface RewardNotificationPayload {
    coins: number
    xp: number
    message?: string
}

export interface AssignmentGradedPayload {
    takeId: string
    productId: string
}

export interface NotificationState {
    id?: string
    notificationId: string
    forStudentId: string
    seen: boolean
}

export interface Notification {
    id: string
    type: NOTIFICATION_TYPE
    message: string
    createdDate: string
    msDate: number
    forStudentId: string
    createdBy: string
    expireOnSeenMs: number
    forRoomId: string
    seen?: boolean
}

export interface AnalyticsUser extends BaseRecord {
    name: string
    school: string
}

export interface StudentGamesPlayedHistory extends BaseRecord {
    pts: number
    gamesPlayed: number
    gamesParrot1P: GamesPlayedRecord
    gamesParrot2P: GamesPlayedRecord
    gamesPairs1P: GamesPlayedRecord
    gamesPairs2P: GamesPlayedRecord
    gamesScenario: GamesPlayedRecord
}

export interface GameGroup extends BaseRecord {
    name: string
    grade?: string
    gradeId?: string
    schoolYear?: string
    classroom?: string
    identifier?: string
    studentIds: string[]
    students?: Student[]
    facultyIds: string[]
    faculty?: Faculty[]
    productIds: string[]
    products?: Product[]
    courseIds: string[]
    courses?: Course[]
    isHidden?: boolean
    isActive?: boolean
    schoolId: string
    school?: School
    slug?: string
    uuid: string
    remoteIdentifier: string
    remoteName: string
}
export interface ProgressProfile extends BaseRecord {
    name: string
    course: Course
    progressGroups?: ProgressGroup[]
    isActive: boolean
    school: School
    date: string
    labels: {
        noData: string
        below: string
        approaching: string
        meets: string
        exceeds: string
        farExceeds: string
    }
}

export enum LEARNING_RESOURCE_KIND {
    BOTH = "both",
    ONLY_LEARNING_RESOURCE = "only_learning_resource"
}

// todo andrew, replace old CatalogTopic with this
// i created a model for catalogtopic in order to do the library filters,
// i'm creating this new interface so i don't need to reengineer all the stuff
// that already uses the old CatalogTopic type
export interface CatalogTopic extends BaseRecord {
    isActive: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    name: string
    slug: string
    uuid: string
    unitId: string
    description: string
    order: number
    shortIdentifier: string
    learningResourceKind: LEARNING_RESOURCE_KIND
    masteryThreshold: number
}
export interface RangeSetLinked extends BaseRecord {
    isActive: boolean
    itemType: number
    min: number
    max: number
    maxItem: CatalogTopic | null
    minItem: CatalogTopic | null
    progressType: string
}

export interface RangeSet extends BaseRecord {
    isActive: boolean
    itemType: number
    min: number
    max: number
    maxItem: number | null
    minItem: number | null
    progressType: string
}

export interface ProgressGroup extends BaseRecord {
    name: string
    rangeSet: (RangeSetLinked | RangeSet)[]
    studentGroups: GameGroup[]
    enableTargetSetting: boolean
}

export interface GameGroupWithStudents extends GameGroup {
    students: Student[]
}

export interface District extends BaseRecord {
    name: string
    isActive?: boolean
    slug?: string
    uuid?: string
    schools?: School[]
    schoolIds: string[]
    disableSyncGroupsStudents: boolean
    disableSyncGroupsFaculty: boolean
    disableSync: boolean
    disableSyncGroupGrades: boolean
}
export interface School extends BaseRecord {
    name: string
    city?: string
    state?: string
    isActive?: boolean
    groups?: GameGroup[]
    groupIds: string[]
    district?: District
    districtId: string
    slug: string
    paidSeatsProduct?: PaidSeatsProduct[]
    paidSeatsCourse?: PaidSeatsCourse[]
    facultyIds: string[]
    studentIds: string[]
    faculty?: Faculty[]
    student?: Student[]
    uuid?: string
    stateId?: string
    deployLocation: string
    createdAtMs?: number
    updatedAtMs?: number
    createdById?: string
    updatedById?: string
    sourceDemoSchoolName: string | null
}

export interface StudentGroupMembership extends BaseRecord {
    id: string
    studentId: string
    studentGroupId: string
}

export enum FACULTY_ROLE {
    TEACHER = "t",
    OWNER = "o"
}
export interface GroupFacultyMembership extends BaseRecord {
    id: string
    facultyId: string
    studentGroupId: string
    role: FACULTY_ROLE
}
export interface DistrictFacultyMembership extends BaseRecord {
    id: string
    districtId: string
    facultyId: string
    membershipType: FACULTY_MEMBERSHIP_TYPE
}
export interface SchoolFacultyMembership extends BaseRecord {
    id: string
    facultyId: string
    schoolId: string
    membershipType: FACULTY_MEMBERSHIP_TYPE
}

export interface PaidSeats extends BaseRecord {
    isActive: boolean
    schoolId: string
    courseId: string
    productId: string
    seatCount: number
}

export interface StudentGroupCourse extends BaseRecord {
    courseId: string
    studentGroupId: string
}

export interface StudentGroupProduct extends BaseRecord {
    productId: string
    studentGroupId: string
}

export enum DEPLOY_LOCATION {
    CHINA = "china",
    GLOBAL = "global"
}

export interface DemoAccount {
    id: string
    schoolName: string
    createdAtMs: number
    dateExpiredMs: number | null
    isActive: boolean
    createdById: string | null
    schoolId: string | null
    emailDomainName: string
}

export interface ClassroomGroupLanguageSettings {
    id: string
    hintAudio: LANGUAGE
    scenarioGameContextAudio: LANGUAGE
    targetLanguage: LANGUAGE
    nativeLanguage: LANGUAGE
    studentGroupId: string | null
    segmentId: string | null
}

export interface StudentStars extends BaseRecord {
    progress: number
    studentId: string | null
    deckId: string | null
    goalId: string | null
}
