type acceptableFn = (params: any) => any | Promise<any>

export default function promiseChain(promiseFns: acceptableFn[], startingParam?: any, rawErrors?: boolean) {
    return new Promise(async (resolve, reject) => {
        let lastOutput: any = startingParam
        let idx = 0
        // doing it this way means less memory used.
        // no memory allocated for recursive fn headers.
        for (const fn of promiseFns) {
            try {
                lastOutput = await fn(lastOutput)
                idx++
            } catch (e) {
                return rawErrors
                    ? reject(e)
                    : reject({
                          chainIdx: idx,
                          error: e
                      })
            }
        }
        resolve(lastOutput)
    })
}
