import { type Components, type Theme } from "@mui/material"
import type {} from "@mui/x-data-grid-premium/themeAugmentation"
import type {} from "@mui/x-data-grid-pro/themeAugmentation"
import type {} from "@mui/x-data-grid/themeAugmentation"
import type {} from "@mui/x-date-pickers/themeAugmentation"

interface StyleOverride {
    theme: Theme
    ownerState?: Record<string, unknown>
}

const components: Components<Omit<Theme, "components">> = {
    MuiTextField: {
        defaultProps: {
            variant: "standard"
        }
    },
    MuiDialog: {
        styleOverrides: {
            root: {
                "& [role='presentation']": {
                    height: "100%"
                }
            },
            paper: {
                height: "auto"
            },
            paperFullScreen: {
                height: "100vh"
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }: StyleOverride) => ({
                "&.Mui-selected": {
                    color: "black"
                }
            })
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.25)",
                "& .MuiDataGrid-topContainer": {
                    height: "auto !important"
                },
                "& .MuiDataGrid-bottomContainer": {
                    height: "0 !important"
                }
            },
            columnHeaderTitle: {
                fontWeight: "600 !important"
            },
            cell: {
                wordBreak: "break-word",
                padding: "8px",
                display: "flex",
                alignItems: "center"
            },
            iconButtonContainer: {
                "&:has(.MuiDataGrid-filterIcon)": {
                    display: "none"
                }
            }
        }
    },
    MuiMultiSectionDigitalClockSection: {
        styleOverrides: {
            root: ({ theme }: StyleOverride) => ({
                variants: [], // Add an empty array for the variants property
                width: "60px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowY: "overlay"
            })
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                "& .MuiFormControl-root": {
                    height: "40px"
                }
            },
            inputRoot: {
                height: "100%"
            }
        }
    },
    MuiTooltip: {
        defaultProps: {
            enterDelay: 700,
            enterNextDelay: 700,
            leaveDelay: 200
        }
    }
}

export default components
