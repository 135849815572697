import { BaseRecord } from "./baseTypes"

export enum BATCH_JOB_TYPE {
    AUDIO_PROCESSING = "AUDIO_PROCESSING",
    BOOK_USAGE_EXPORT = "BOOK_USAGE_EXPORT",
    DATA_MIGRATION = "DATA_MIGRATION",
    ANALYTICS = "ANALYTICS",
    DEMO_CLONE_ALL_TAKES = "DEMO_CLONE_ALL_TAKES",
    COPY_MASTERY_PROGRESS = "COPY_MASTERY_PROGRESS",
    DEMO_CLONE_SCHOOL = "DEMO_CLONE_SCHOOL",
    WORKSHEETS_DOWNLOAD = "WORKSHEETS_DOWNLOAD",
    WORKSHEETS_UPLOAD = "WORKSHEETS_UPLOAD",
    RESOURCES_UPLOAD = "RESOURCES_UPLOAD"
}

export enum BATCH_JOB_STATUS {
    NOT_STARTED = "NOT_STARTED",
    IN_PROGRESS = "IN_PROGRESS",
    ERROR = "ERROR",
    COMPLETED = "COMPLETED"
}

export interface BatchJobProgress extends BaseRecord {
    type: BATCH_JOB_TYPE
    status: BATCH_JOB_STATUS
    message: string
    progress: number | null
    dateCreatedMs: number
    dateStartedMs: number | null
    dateFinishedMs: number | null
    createdById: string | null
    result: string | null
}
