import { sortBy, isString, isFunction, isEqual } from "lodash"

export default function (arr1: Array<any>, arr2: Array<any>, sorter?: string | Function, equality?: string | Function) {
    let a = arr1.slice().filter(Boolean)
    let b = arr2.slice().filter(Boolean)

    if (isFunction(sorter) || isString(sorter)) {
        a = sortBy(a, sorter)
        b = sortBy(b, sorter)
    } else {
        a = a.sort()
        b = b.sort()
    }

    if (isString(equality)) {
        a = a.map(a => a[equality])
        b = b.map(a => a[equality])
        return isEqual(a, b)
    }

    if (isFunction(equality)) {
        a = a.map(equality)
        b = b.map(equality)
        return isEqual(a, b)
    }

    return isEqual(a, b)
}
