import { BaseRecordWithMeta } from "./baseTypes"
import { LearningResource } from "./learningTypes"
import { V3Take } from "./testTypes"

export enum ADAPTIVE_INITIAL_DIAGNOSTIC_STATUS {
    ASSIGNED = "ASSIGNED", // pick pdf
    IN_PROGRESS = "IN_PROGRESS", // has taken 1 or many
    DONE = "DONE"
}

export interface AdaptiveInitialDiagnostic extends BaseRecordWithMeta {
    id: string
    studentId: string
    productId: string
    takenTakes: V3Take[]
    startingQuiz?: LearningResource
    startingV3TestId: string | null
    currentAssignmentId?: string | null
    isActive: boolean
    status: ADAPTIVE_INITIAL_DIAGNOSTIC_STATUS
}

export const AID_PRODUCT_IDS = ["7", "14", "15", "16"]
export const AID_SPANISH_READING_CATALOG_ITEM_TYPE_ID = "108"
