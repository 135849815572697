export enum CONTENTFUL_MODEL {
    CARD = "card",
    DECK = "deck",
    HOTSPOT = "igHotspot",
    LAYERED_DECK_STEP = "igLayeredDeckStep",
    QUEST_TREE = "igQuestTree",
    QUEST_TREE_GOAL = "igQuestTreeVariant", // yes i know the contentTypeId doesn't say goal. this is because we changed stuff around and removed variants (a thing of the past)
    QUEST_TREE_STEP = "igQuestTreeStep",
    SCENE = "igScene",
    LAYERED_DECK = "layeredDeck",
    RELATIONSHIP_DECK = "relationshipDeck",
    CONDITION = "igCondition",
    CONDITION_HAS_ITEM = "igHasItemCondition",
    CONDITION_TIME = "igTimeCondition",
    ACTION_TO_QUEST_TREE = "igToQuestTreeAction",
    ACTION_DIMINISH_REMAINING_TIME = "igDiminishRemainingTimeAction",
    ACTION_END_QUEST_TREE = "igEndQuestTreeAction",
    ACTION_ITEM = "igItemAction",
    ACTION_MOVE_TO_SCENE = "igMoveToSceneAction",
    ACTION_GIVE_QUEST = "igGiveQuestAction",
    ACTION_QUEST_TREE_PREV_STEP = "igQuestPrevStepAction",
    ACTION_QUEST_TREE_NEXT_STEP = "igQuestNextStep",
    ACTION_QUEST_TREE = "igQuestTreeAction",
    ACTION_TIME_CHANGE = "igTimeAction",
    GENERIC_ASSET = "genericAsset",
    PHONICS_CARD = "phonicsCard",
    PHONICS_DECK = "phonicsDeck",
    PHONICS_WORLD = "phonicsWorld",
    PHONICS_CONTENT_TYPE_TO_GAME_CONTENT_TYPE_MAPPING = "phonicsContentTypeToGameContentTypeMapping",
    MINI_LESSON = "mini_lesson"
}
