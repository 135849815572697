import { isEqual, isArray, transform, isObject } from "lodash"

const objDiff = (modification: any, original: any) => {
    function changes(object: any, base: any) {
        return transform(object, (result: any, value: any, key: string) => {
            if (!isEqual(value, base[key])) {
                // custom stuff. Arrays, we want the whole thing. Not just the diff
                if (isArray(value)) {
                    result[key] = value
                } else {
                    result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value
                }
            }
        })
    }
    return changes(modification, original)
}

export default objDiff
