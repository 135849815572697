export enum AVATAR_ELEMENT_NAME {
    HEAD = "HEAD",
    OUTFIT = "OUTFIT",
    EYES = "EYES",
    NOSE = "NOSE",
    MOUTH = "MOUTH",
    HAIR = "HAIR",
    ACCESSORY_HEAD = "ACCESSORY_HEAD",
    ACCESSORY_TORSO = "ACCESSORY_TORSO",
    ACCESSORY_LEFT_ARM = "ACCESSORY_LEFT_ARM",
    ACCESSORY_RIGHT_ARM = "ACCESSORY_RIGHT_ARM",
    ACCESSORY_LEFT_LEG = "ACCESSORY_LEFT_LEG",
    ACCESSORY_RIGHT_LEG = "ACCESSORY_RIGHT_LEG",
    PET = "PET"
}

export interface ElementColor {
    skin?: string
    primary?: string
    secondary?: string
    tertiary?: string
    accent1?: string
    accent2?: string
}

export interface Outfit {
    id?: string // optional because name is unique and we kind of use name as the id?? weird maybe?
    name: string
    cost?: number
    parts: {
        leftArm: string
        rightArm: string
        torso: string
        leftLeg: string
        rightLeg: string
    }
}

export interface AvatarAsset {
    id?: string // optional because name is unique and we kind of use name as the id?? weird maybe
    name: string
    cost?: number
    part: string
    assetType?: AVATAR_ELEMENT_NAME
}

export interface AvatarAssets {
    HEAD: string
    OUTFIT: Outfit[]
    EYES: AvatarAsset[]
    NOSE: AvatarAsset[]
    MOUTH: AvatarAsset[]
    HAIR: AvatarAsset[]
    ACCESSORY_HEAD: AvatarAsset[]
    ACCESSORY_TORSO: AvatarAsset[]
    ACCESSORY_LEFT_ARM: AvatarAsset[]
    ACCESSORY_RIGHT_ARM: AvatarAsset[]
    ACCESSORY_LEFT_LEG: AvatarAsset[]
    ACCESSORY_RIGHT_LEG: AvatarAsset[]
    PET: AvatarAsset[]
}

export interface AvatarDataPiece {
    name: string
    color: ElementColor
}
export interface AvatarData {
    outfit: {
        name: string
        color: ElementColor
        accessories?: {
            leftArm?: AvatarDataPiece[]
            rightArm?: AvatarDataPiece[]
            leftLeg?: AvatarDataPiece[]
            rightLeg?: AvatarDataPiece[]
            torso?: AvatarDataPiece[]
        }
    }
    face: {
        eyes?: string
        nose?: string
        mouth?: string
        hair?: AvatarDataPiece
        accessories?: AvatarDataPiece[]
    }
    pet: AvatarDataPiece
    skinColor?: string
}

export interface OwnedOutfit {
    id: string
    profileId: string
    outfitId: string
    outfitName: string
}

export interface OwnedAsset {
    id: string
    profileId: string
    assetId: string
    assetType: AVATAR_ELEMENT_NAME
    assetName: string
}

export interface OwnedAssets {
    face: {
        eyes: string[]
        nose: string[]
        mouth: string[]
        hair: string[]
        accessories: string[]
    }
    outfit: {
        name: string[]
        accessories: {
            leftArm: string[]
            rightArm: string[]
            leftLeg: string[]
            rightLeg: string[]
            torso: string[]
        }
    }
    pet: string[]
    colors: string[]
}

export interface OwnershipCostEntry {
    [name: string]: number
}
export interface OwnershipCostMap {
    colors: OwnershipCostEntry
    face: {
        accessories: OwnershipCostEntry
        eyes: OwnershipCostEntry
        hair: OwnershipCostEntry
        mouth: OwnershipCostEntry
        nose: OwnershipCostEntry
    }
    outfit: {
        accessories: {
            leftArm: OwnershipCostEntry
            rightArm: OwnershipCostEntry
            leftLeg: OwnershipCostEntry
            rightLeg: OwnershipCostEntry
            torso: OwnershipCostEntry
        }
        name: OwnershipCostEntry
    }
    pet: OwnershipCostEntry
}

export type ColorName = "skin" | "primary" | "secondary" | "tertiary" | "accent1" | "accent2" | ""

export interface AvatarPieceUploadParams {
    type: AVATAR_ELEMENT_NAME
    assetUrl: string
    name: string
    cost?: number
}

export interface AvatarUploadOutfitParams {
    name: string
    cost?: number
    assetUrls: {
        leftArm: string
        rightArm: string
        torso: string
        leftLeg: string
        rightLeg: string
    }
}
