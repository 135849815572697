import { ROLES } from "../types"

const NOTIFICATION_ROOM_ID_PREFIX = "room"
const NOTIFICATION_ROOM_ID_SEPARATOR = "--"
const EVERYONE = "everyone"
const ROOM_EVERYONE = `${NOTIFICATION_ROOM_ID_PREFIX}${NOTIFICATION_ROOM_ID_SEPARATOR}${EVERYONE}`
export const ROOM_ADMIN = `${NOTIFICATION_ROOM_ID_PREFIX}${NOTIFICATION_ROOM_ID_SEPARATOR}${ROLES.ADMIN}`

export const getNotificationRoomIds = (role: ROLES | undefined, productIds: string[]) => {
    const prefix = NOTIFICATION_ROOM_ID_PREFIX
    const sep = NOTIFICATION_ROOM_ID_SEPARATOR

    const rooms = [ROOM_EVERYONE]

    productIds.forEach(pid => {
        rooms.push(`${ROOM_EVERYONE}${sep}${pid}`)
        rooms.push(getNotificationRoomId(role, pid))
    })

    if (role) {
        rooms.push(`${prefix}${sep}${role}`)
    }

    return rooms
}

export const getNotificationRoomId = (role?: ROLES, productId?: string): string => {
    const prefix = NOTIFICATION_ROOM_ID_PREFIX
    const sep = NOTIFICATION_ROOM_ID_SEPARATOR

    return `${prefix}${sep}${role || EVERYONE}` + (productId ? `${sep}${productId}` : "")
}
