export enum SMART_TIMEOUT_STATE {
    RUNNING = "RUNNING",
    PAUSED = "PAUSED",
    CANCELED = "CANCELED",
    FINISHED = "FINISHED"
}

const { RUNNING, PAUSED, CANCELED, FINISHED } = SMART_TIMEOUT_STATE

let ID = 0

type ResolveFn = () => void
type RejectFn = (reason?: any) => void

/* pausable, extendable & awaitable timeout 😮 */
export function smartTimeout(fn: () => any, durationMs: number, opts?: { verbose: boolean; resolveOnCancel: boolean }) {
    const id = ID++
    const verbose = opts?.verbose
    let timeoutId: NodeJS.Timeout | undefined
    let startTimeMs = -1
    let triggered = false

    let resolve: ResolveFn | undefined
    let reject: RejectFn | undefined

    const promise = new Promise<void>((pResolve, pReject) => {
        resolve = pResolve
        reject = pReject
    })

    const log = (msg: string) => console.log(`smartTimeout ${id}:: ${msg}`)

    const currentState = () => {
        if (triggered) {
            return SMART_TIMEOUT_STATE.FINISHED
        } else {
            if (durationMs === -1) {
                return SMART_TIMEOUT_STATE.CANCELED
            } else if (timeoutId !== undefined) {
                return SMART_TIMEOUT_STATE.RUNNING
            } else {
                return SMART_TIMEOUT_STATE.PAUSED
            }
        }
    }

    const onTrigger = async () => {
        triggered = true
        timeoutId = undefined
        startTimeMs = -1

        if (verbose) {
            log(`Finished at: ${new Date().toLocaleString()}`)
        }
        try {
            await fn()
            resolve && resolve()
        } catch (e) {
            const err = e as any
            reject && reject(err)
        }
    }

    // just a helper
    const future = (futureMs: number) => new Date(new Date().getTime() + futureMs)

    const pause = () => {
        const state = currentState()
        if (state !== RUNNING) {
            return
        }

        clearTimeout(timeoutId)
        timeoutId = undefined

        const elapsedMs = Math.max(0, new Date().getTime() - startTimeMs)
        startTimeMs = -1

        durationMs = Math.max(0, durationMs - elapsedMs)
        if (verbose) {
            log(`Paused at: ${new Date().toLocaleString()} with ${durationMs}ms remaining`)
        }
    }

    const cancel = () => {
        const state = currentState()
        if (state === CANCELED || state === FINISHED) {
            return
        } else {
            pause()
            if (verbose) {
                log(`Canceled at: ${new Date().toLocaleString()} with ${durationMs}ms remaining`)
            }
            durationMs = -1 // means canceled
            if (opts?.resolveOnCancel) {
                resolve && resolve()
            } else {
                reject && reject(`smartTimeout ${id}:: Canceled by user at ${new Date().toLocaleString()}`)
            }
        }
    }

    const resume = (isStart?: boolean) => {
        const state = currentState()
        if (state !== PAUSED) {
            return
        }

        startTimeMs = new Date().getTime()
        timeoutId = setTimeout(onTrigger, durationMs)

        if (verbose) {
            log(
                [
                    `${isStart ? "started" : "resumed "} at: ${new Date().toLocaleString()} with ${durationMs}ms remaining.`,
                    `Expected to trigger at ${future(durationMs).toLocaleString()}`
                ].join(" ")
            )
        }
    }

    const extend = (extraMs: number) => {
        const state = currentState()
        if ([CANCELED, FINISHED].includes(state)) {
            return
        }

        if (state === PAUSED) {
            durationMs = Math.max(0, durationMs + extraMs)
            if (verbose) {
                log(`Extended by ${extraMs}. ${durationMs}ms remaining`)
            }
        } else {
            pause() // this fn does nothing if not running
            durationMs = Math.max(0, durationMs + extraMs)
            resume() // this fn does nothing if running

            if (verbose) {
                log([`Extended by ${extraMs}. ${durationMs}ms remaining.`, `Expected to trigger at ${future(durationMs).toLocaleString()}`].join(" "))
            }
        }
    }

    const remaining = () => {
        const state = currentState()
        if (state === CANCELED || state === FINISHED) {
            return 0
        } else if (state === PAUSED) {
            return durationMs
        } else {
            const elapsedMs = Math.max(0, new Date().getTime() - startTimeMs)
            return Math.max(0, durationMs - elapsedMs)
        }
    }

    resume(true)
    return {
        currentState,
        pause,
        resume,
        extend,
        cancel,
        remaining,
        promise: () => promise
    }
}

export type SmartTimeout = ReturnType<typeof smartTimeout>
