import { BaseRecord, LANGUAGE, LANGUAGE_CODE, PROF_LEVEL } from "./baseTypes"

export enum PEOPLE_SESSION_EVENT_TYPE {
    PARROT_MODE = "PARROT_MODE",
    CARD_PAIRS_MODE = "CARD_PAIRS_MODE",
    QUEST = "QUEST",
    OVERWORLD = "OVERWORLD"
}

export interface PeopleSession extends BaseRecord {
    dateStartMs: number
    dateEndMs: number | null
    studentGroupId: string | null
    playerId: string
}

export interface PeopleSessionEvent extends BaseRecord {
    type: PEOPLE_SESSION_EVENT_TYPE
    dateStartMs: number
    dateEndMs: number | null
    dateFinishedAudioProcessingMs: number | null
    language: LANGUAGE
    sceneId: string
    profLevel: PROF_LEVEL
    sessionId: string
    playerIds: string[]
    friendlyName: string | null
    eventId: string
    studentGroupId: string | null
    segmentId: string | null
}

export interface PeopleSessionComposite extends PeopleSession {
    events: PeopleSessionEvent[]
}

export enum MULTIPLAYER_PARTY_TYPE {
    CLASSROOM = "CLASSROOM",
    SEGMENT = "SEGMENT"
}

export interface PeopleMultiplayerParty {
    id: string
    overworldId: string
    studentGroupId: string | null
    segmentId: string | null
    languageCode: LANGUAGE_CODE | null
}

export interface PeopleMultiplayerPartyWithPlayerIds extends PeopleMultiplayerParty {
    playerIds: string[]
}

export enum SEGMENT_TYPE {
    LEARNING = "LEARNING",
    GAME_GROUP = "GAME_GROUP"
}

export interface PeopleSegment {
    id: string
    name: string
    teacherId: string
    schoolId: string
    districtId: string
    type: SEGMENT_TYPE
}

export interface PeopleSegmentMembership {
    id: string
    segmentId: string
    studentId: string
}

export interface PeopleSegmentWithStudentIds {
    id: string
    name: string
    teacherId: string
    schoolId: string
    districtId: string
    type: SEGMENT_TYPE
    studentIds: string[]
}

export interface PeopleProfileUserPermissions {
    id: string
    profileId: string
    permissionId: string
}
