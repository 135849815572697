import { CourseConfig, COURSE_TYPE, Product, ProductWithCoursesDeep } from "../../types"

export const getCourseTypeFromDisplayName = (dn: string): COURSE_TYPE => {
    const cleaned = dn.toLowerCase().replace(/[^a-zA-Z]/g, "")
    if (cleaned.includes("nonfiction")) return COURSE_TYPE.NON_FICTION
    return COURSE_TYPE.FICTION
}


export const courseType = (courseId: string, product?: Product | ProductWithCoursesDeep) => {
    const courseDisplayName = product?.relatedCoursesConfig?.find((c: CourseConfig) => c.courseId === courseId)?.displayName || ""
    return getCourseTypeFromDisplayName(courseDisplayName)
}
