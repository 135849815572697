import "./sass/animation.scss"
import "./sass/border.scss"
import "./sass/color.scss"
import "./sass/flex.scss"
import "./sass/globals.scss"
import "./sass/handy.scss"
import "./sass/marginPadding.scss"
import "./sass/opacity.scss"
import "./sass/positional.scss"
import "./sass/shadow.scss"
import "./sass/textAlignment.scss"
import "./sass/transform.scss"

import { LANGUAGE, LANGUAGE_CODE } from "@levellearning/base"
import { Theme as MuiTheme } from "@mui/material"
import jss from "jss"
import jssPluginGlobal from "jss-plugin-global"
import { isNumber, isString } from "lodash"
import breakpoints from "./config/breakpoints"
import { SpecialColors } from "./config/palette"
import props from "./config/props"
import spacing from "./config/spacing"
import typography from "./config/typography"
import GameTheme from "./themes/game"
import LightTheme from "./themes/light"

jss.use(jssPluginGlobal())

export const CUSTOM_COLORS = {
    NO_DATA: "#cccccc",
    MASTERY: {
        NOT_MASTERED: "#8d009e",
        MASTERED: "#74c345"
    },
    TEST: {
        PLACEMENT: "#26cadc",
        ASSIGNED: "#26cadc",
        NEEDS_GRADING: "#f7d20e"
    },
    LANGUAGE_CODE: {
        [LANGUAGE_CODE.SPANISH]: "#097969",
        [LANGUAGE_CODE.ENGLISH]: "#366ec5",
        [LANGUAGE_CODE.CHINESE_SIMPLIFIED]: "#7b559f",
        [LANGUAGE_CODE.CHINESE_TRADITIONAL]: "#7b559f"
    }
}

export const px = (val: string | number | undefined) => {
    if (isNumber(val)) {
        return `${val}px`
    } else if (isString(val)) {
        return val
    } else {
        return "!!EMPTY!!"
    }
}

export function bootGlobalStyleVariables(theme?: MuiTheme) {
    if (!theme) theme = LightTheme

    const vars = [
        `--font-family: ${theme.typography.fontFamily}`,
        `--font-family-es: "Spartan", sans-serif`,

        `--primary: ${theme.palette.primary.main}`,
        `--primary-dark: ${theme.palette.primary.dark}`,
        `--primary-light: ${theme.palette.primary.light}`,
        `--primary-contrast: ${theme.palette.primary.contrastText}`,
        `--primary-state: rgba(0, 83, 139, 0.8)`,
        `--primary-sidenav: ${theme.palette.grey[100]}`,

        `--secondary: ${theme.palette.secondary.main}`,
        `--secondary-dark: ${theme.palette.secondary.dark}`,
        `--secondary-light: ${theme.palette.secondary.light}`,
        `--secondary-state: rgba(0, 0, 0, 0.04)`,
        `--secondary-contrast: ${theme.palette.secondary.contrastText}`,

        `--tertiary: #ffffff`,
        `--tertiary-dark: #d6d6d6`,
        `--tertiary-state: #e6e6e6`,

        `--disabled: #e0e0e0`,
        `--shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1)`,
        `--card-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)`,

        `--success: ${theme.palette.success.main}`,
        `--success-dark: ${theme.palette.success.dark}`,
        `--success-light: ${theme.palette.success.light}`,
        `--success-contrast: ${theme.palette.success.contrastText}`,

        `--info: ${theme.palette.info.main}`,
        `--info-dark: ${theme.palette.info.dark}`,
        `--info-light: ${theme.palette.info.light}`,
        `--info-contrast: ${theme.palette.info.contrastText}`,

        `--warning: ${theme.palette.warning.main}`,
        `--warning-dark: ${theme.palette.warning.dark}`,
        `--warning-light: ${theme.palette.warning.light}`,
        `--warning-contrast: ${theme.palette.warning.contrastText}`,

        `--error: ${theme.palette.error.main}`,
        `--error-dark: ${theme.palette.error.dark}`,
        `--error-light: ${theme.palette.error.light}`,
        `--error-contrast: ${theme.palette.error.contrastText}`,

        `--background: ${theme.palette.background.default}`,
        `--background-paper: ${theme.palette.background.paper}`,

        `--black: ${theme.palette.common.black}`,
        `--border-card:#e0e0e0`,
        `--white: ${theme.palette.common.white}`,

        `--divider: ${theme.palette.divider}`,

        `--text-primary: ${theme.palette.text.primary}`,
        `--text-secondary: ${theme.palette.text.secondary}`,
        `--text-label: #616161`,
        `--text-hint: ${theme.palette.text.primary}`, // TODO wolf/andrew/greg this was changed
        `--text-disabled: rgba(0, 0, 0, 0.38)`,

        `--grey-50: ${theme.palette.grey[50]}`,
        `--grey-100: ${theme.palette.grey[100]}`,
        `--grey-200: ${theme.palette.grey[200]}`,
        `--grey-300: ${theme.palette.grey[300]}`,
        `--grey-400: ${theme.palette.grey[400]}`,
        `--grey-500: ${theme.palette.grey[500]}`,
        `--grey-600: ${theme.palette.grey[600]}`,
        `--grey-700: ${theme.palette.grey[700]}`,
        `--grey-800: ${theme.palette.grey[800]}`,
        `--grey-900: ${theme.palette.grey[900]}`,
        `--grey-A100: ${theme.palette.grey.A100}`,
        `--grey-A200: ${theme.palette.grey.A200}`,
        `--grey-A400: ${theme.palette.grey.A400}`,
        `--grey-A700: ${theme.palette.grey.A700}`,

        `--breakpoint-xs: ${theme.breakpoints.values.xs}px`,
        `--breakpoint-sm: ${theme.breakpoints.values.sm}px`,
        `--breakpoint-md: ${theme.breakpoints.values.md}px`,
        `--breakpoint-lg: ${theme.breakpoints.values.lg}px`,
        `--breakpoint-xl: ${theme.breakpoints.values.xl}px`,

        `--spacing: ${theme.spacing(1)}`,
        `--spacing-half: calc(${theme.spacing(1)} * 0.5)`,
        `--spacing2: calc(${theme.spacing(1)} * 2)`,
        `--spacing3: calc(${theme.spacing(1)} * 3)`,
        `--spacing4: calc(${theme.spacing(1)} * 4)`,
        `--spacing5: calc(${theme.spacing(1)} * 5)`,
        `--spacing6: calc(${theme.spacing(1)} * 6)`,
        `--spacing7: calc(${theme.spacing(1)} * 7)`,
        `--spacing8: calc(${theme.spacing(1)} * 8)`,
        `--spacing9: calc(${theme.spacing(1)} * 9)`,
        `--spacing10: calc(${theme.spacing(1)} * 10)`,

        `--radius: ${theme.shape.borderRadius}px`,

        `--h1-font-family: ${theme.typography.h1.fontFamily}`,
        `--h1-font-size: ${theme.typography.h1.fontSize}px`,
        `--h1-font-weight: ${theme.typography.h1.fontWeight}`,
        `--h1-font-style: ${theme.typography.h1.fontStyle}`,
        `--h1-line-height: ${theme.typography.h1.lineHeight}`,
        `--h1-letter-spacing: ${px(theme.typography.h1.letterSpacing)}`,
        `--h1-text-transform: ${px(theme.typography.h1.textTransform)}`,

        `--h2-font-family: ${theme.typography.h2.fontFamily}`,
        `--h2-font-size: ${theme.typography.h2.fontSize}px`,
        `--h2-font-weight: ${theme.typography.h2.fontWeight}`,
        `--h2-font-style: ${theme.typography.h2.fontStyle}`,
        `--h2-line-height: ${theme.typography.h2.lineHeight}`,
        `--h2-letter-spacing: ${px(theme.typography.h2.letterSpacing)}`,
        `--h2-text-transform: ${px(theme.typography.h2.textTransform)}`,

        `--h3-font-family: ${theme.typography.h3.fontFamily}`,
        `--h3-font-size: ${theme.typography.h3.fontSize}px`,
        `--h3-font-weight: ${theme.typography.h3.fontWeight}`,
        `--h3-font-style: ${theme.typography.h3.fontStyle}`,
        `--h3-line-height: ${theme.typography.h3.lineHeight}`,
        `--h3-letter-spacing: ${px(theme.typography.h3.letterSpacing)}`,
        `--h3-text-transform: ${px(theme.typography.h3.textTransform)}`,

        `--h4-font-family: ${theme.typography.h4.fontFamily}`,
        `--h4-font-size: ${theme.typography.h4.fontSize}px`,
        `--h4-font-weight: ${theme.typography.h4.fontWeight}`,
        `--h4-font-style: ${theme.typography.h4.fontStyle}`,
        `--h4-line-height: ${theme.typography.h4.lineHeight}`,
        `--h4-letter-spacing: ${px(theme.typography.h4.letterSpacing)}`,
        `--h4-text-transform: ${px(theme.typography.h4.textTransform)}`,

        `--h5-font-family: ${theme.typography.h5.fontFamily}`,
        `--h5-font-size: ${theme.typography.h5.fontSize}px`,
        `--h5-font-weight: ${theme.typography.h5.fontWeight}`,
        `--h5-font-style: ${theme.typography.h5.fontStyle}`,
        `--h5-line-height: ${theme.typography.h5.lineHeight}`,
        `--h5-letter-spacing: ${px(theme.typography.h5.letterSpacing)}`,
        `--h5-text-transform: ${px(theme.typography.h5.textTransform)}`,

        `--h6-font-family: ${theme.typography.h6.fontFamily}`,
        `--h6-font-size: ${theme.typography.h6.fontSize}px`,
        `--h6-font-weight: ${theme.typography.h6.fontWeight}`,
        `--h6-font-style: ${theme.typography.h6.fontStyle}`,
        `--h6-line-height: ${theme.typography.h6.lineHeight}`,
        `--h6-letter-spacing: ${px(theme.typography.h6.letterSpacing)}`,
        `--h6-text-transform: ${px(theme.typography.h6.textTransform)}`,

        `--body1-font-family: ${theme.typography.body1.fontFamily}`,
        `--body1-font-size: ${theme.typography.body1.fontSize}px`,
        `--hbody16-font-weight: ${theme.typography.body1.fontWeight}`,
        `--hbody16-font-style: ${theme.typography.body1.fontStyle}`,
        `--body1-line-height: ${theme.typography.body1.lineHeight}`,
        `--body1-letter-spacing: ${px(theme.typography.body1.letterSpacing)}`,
        `--body1-text-transform: ${px(theme.typography.body1.textTransform)}`,

        `--body2-font-family: ${theme.typography.body2.fontFamily}`,
        `--body2-font-size: ${theme.typography.body2.fontSize}px`,
        `--body2-font-weight: ${theme.typography.body2.fontWeight}`,
        `--body2-font-style: ${theme.typography.body2.fontStyle}`,
        `--body2-line-height: ${theme.typography.body2.lineHeight}`,
        `--body2-letter-spacing: ${px(theme.typography.body2.letterSpacing)}`,
        `--body2-text-transform: ${px(theme.typography.body2.textTransform)}`,

        `--subtitle1-font-family: ${theme.typography.subtitle1.fontFamily}`,
        `--subtitle1-font-size: ${theme.typography.subtitle1.fontSize}px`,
        `--subtitle1-font-weight: ${theme.typography.subtitle1.fontWeight}`,
        `--subtitle1-font-style: ${theme.typography.subtitle1.fontStyle}`,
        `--subtitle1-line-height: ${theme.typography.subtitle1.lineHeight}`,
        `--subtitle1-letter-spacing: ${px(theme.typography.subtitle1.letterSpacing)}`,
        `--subtitle1-text-transform: ${px(theme.typography.subtitle1.textTransform)}`,

        `--subtitle2-font-family: ${theme.typography.subtitle2.fontFamily}`,
        `--subtitle2-font-size: ${theme.typography.subtitle2.fontSize}px`,
        `--subtitle2-font-weight: ${theme.typography.subtitle2.fontWeight}`,
        `--subtitle2-font-style: ${theme.typography.subtitle2.fontStyle}`,
        `--subtitle2-line-height: ${theme.typography.subtitle2.lineHeight}`,
        `--subtitle2-letter-spacing: ${px(theme.typography.subtitle2.letterSpacing)}`,
        `--subtitle2-text-transform: ${px(theme.typography.subtitle2.textTransform)}`,

        `--font-weight-light: ${theme.typography.fontWeightLight}`,
        `--font-weight-regular: ${theme.typography.fontWeightRegular}`,
        `--font-weight-medium: ${theme.typography.fontWeightMedium}`,
        `--font-weight-bold: ${theme.typography.fontWeightBold}`,

        `--color-mastery-not-mastered: ${CUSTOM_COLORS.MASTERY.NOT_MASTERED}`,
        `--color-mastery-not-mastered: ${CUSTOM_COLORS.MASTERY.NOT_MASTERED}`,
        `--color-mastery-mastered: ${CUSTOM_COLORS.MASTERY.MASTERED}`,
        `--color-test-placement: ${CUSTOM_COLORS.TEST.PLACEMENT}`,
        `--color-test-assigned: ${CUSTOM_COLORS.TEST.ASSIGNED}`,
        `--color-test-needs-grading: ${CUSTOM_COLORS.TEST.NEEDS_GRADING}`,
        `--color-no-data: ${CUSTOM_COLORS.NO_DATA}`,

        `--color-${LANGUAGE_CODE.SPANISH}: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.SPANISH]}`,
        `--color-${LANGUAGE_CODE.ENGLISH}: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.ENGLISH]}`,
        `--color-${LANGUAGE_CODE.CHINESE_SIMPLIFIED}: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.CHINESE_SIMPLIFIED]}`,
        `--color-${LANGUAGE_CODE.CHINESE_TRADITIONAL}: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.CHINESE_TRADITIONAL]}`,

        `--color-${LANGUAGE_CODE.SPANISH}-secondary: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.SPANISH]}80`,
        `--color-${LANGUAGE_CODE.ENGLISH}-secondary: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.ENGLISH]}80`,
        `--color-${LANGUAGE_CODE.CHINESE_SIMPLIFIED}-secondary: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.CHINESE_SIMPLIFIED]}80`,
        `--color-${LANGUAGE_CODE.CHINESE_TRADITIONAL}-secondary: ${CUSTOM_COLORS.LANGUAGE_CODE[LANGUAGE_CODE.CHINESE_TRADITIONAL]}80`
    ]

    vars.forEach(line => {
        const [name, value] = line.split(":")
        if (value === "" || value === "!!EMPTY!!") {
            return
        }

        document.body.style.setProperty(name, value)
    })

    console.log(`bootGlobalStyleVariables::finished`)
}

export { SpecialColors, GameTheme, LightTheme as Theme, typography, breakpoints, spacing, props }

// TODO add chinese fonts here
export function getFontFromLanguage(language: LANGUAGE) {
    switch (language) {
        case LANGUAGE.SPANISH:
            return "Heinemann"
        case LANGUAGE.ENGLISH:
            return "Heinemann"
        default:
            return "Proxima Nova"
    }
}

export enum RCC {
    ANIMATION_SHAKE = "rcc-animation-shake",
    ANIMATION_BOUNCE = "rcc-animation-bounce",
    ANIMATION_LEVEL_UP = "rcc-animation-level-up",
    ANIMATION_SCALE_UP = "rcc-animation-scale-up",
    ANIMATION_SCALE_DOWN = "rcc-animation-scale-down",
    ANIMATION_SCALE_HALF = "rcc-animation-scale-half",
    ANIMATION_SCALE_SPIN_DOWN = "rcc-animation-scale-spin-down",
    ANIMATION_BREATHE = "rcc-animation-breathe",
    BORDER_NONE = "rcc-border-none",
    DIVIDER = "rcc-divider",
    BORDERED = "rcc-bordered",
    ROUNDED_BORDER = "rcc-rounded-border",
    FLAT_BORDER = "rcc-flat-border",
    CIRCLE_BORDER = "rcc-circle-border",
    COLOR_PRIMARY = "rcc-color-primary",
    COLOR_PRIMARY_LIGHT = "rcc-color-primary-light",
    COLOR_PRIMARY_DARK = "rcc-color-primary-dark",
    COLOR_PRIMARY_CONTRAST = "rcc-color-primary-contrast",
    COLOR_SECONDARY = "rcc-color-secondary",
    COLOR_SECONDARY_LIGHT = "rcc-color-secondary-light",
    COLOR_SECONDARY_DARK = "rcc-color-secondary-dark",
    COLOR_SECONDARY_CONTRAST = "rcc-color-secondary-contrast",
    COLOR_ERROR = "rcc-color-error",
    COLOR_ERROR_LIGHT = "rcc-color-error-light",
    COLOR_ERROR_DARK = "rcc-color-error-dark",
    COLOR_ERROR_CONTRAST = "rcc-color-error-contrast",
    COLOR_WHITE = "rcc-color-white",
    COLOR_BLACK = "rcc-color-black",
    COLOR_BACKGROUND = "rcc-color-background",
    COLOR_BACKGROUND_PAPER = "rcc-color-background-paper",
    COLOR_TEXT = "rcc-color-text",
    COLOR_TEXT_SECONDARY = "rcc-color-text-secondary",
    COLOR_TEXT_HINT = "rcc-color-text-hint",
    COLOR_TEXT_DISABLED = "rcc-color-text-disabled",
    BG_PRIMARY = "rcc-bg-primary",
    BG_PRIMARY_LIGHT = "rcc-bg-primary-light",
    BG_PRIMARY_DARK = "rcc-bg-primary-dark",
    BG_PRIMARY_CONTRAST = "rcc-bg-primary-contrast",
    BG_SECONDARY = "rcc-bg-secondary",
    BG_SECONDARY_LIGHT = "rcc-bg-secondary-light",
    BG_SECONDARY_DARK = "rcc-bg-secondary-dark",
    BG_SECONDARY_CONTRAST = "rcc-bg-secondary-contrast",
    BG_ERROR = "rcc-bg-error",
    BG_ERROR_LIGHT = "rcc-bg-error-light",
    BG_ERROR_DARK = "rcc-bg-error-dark",
    BG_ERROR_CONTRAST = "rcc-bg-error-contrast",
    BG_WHITE = "rcc-bg-white",
    BG_COMMON_BLACK = "rcc-bg-CommonBlack",
    BG_BACKGROUND = "rcc-bg-background",
    BG_BACKGROUND_PAPER = "rcc-bg-background-paper",
    BG_TEXT = "rcc-bg-text",
    BG_TEXT_SECONDARY = "rcc-bg-text-secondary",
    BG_TEXT_HINT = "rcc-bg-text-hint",
    BG_TEXT_DISABLED = "rcc-bg-text-disabled",
    ROW = "rcc-row",
    ROW_MODERN = "rcc-row-modern",
    COLUMN = "rcc-column",
    COLUMN_MODERN = "rcc-column-modern",
    WRAP = "rcc-wrap",
    NOWRAP = "rcc-nowrap",
    ITEMS_START = "rcc-items-start",
    ITEMS_CENTER = "rcc-items-center",
    ITEMS_END = "rcc-items-end",
    ITEMS_STRETCH = "rcc-items-stretch",
    JUSTIFY_START = "rcc-justify-start",
    JUSTIFY_CENTER = "rcc-justify-center",
    JUSTIFY_END = "rcc-justify-end",
    JUSTIFY_BETWEEN = "rcc-justify-between",
    JUSTIFY_EVENLY = "rcc-justify-evenly",
    JUSTIFY_STRETCH = "rcc-justify-stretch",
    SELF_START = "rcc-self-start",
    SELF_CENTER = "rcc-self-center",
    SELF_END = "rcc-self-end",
    SELF_STRETCH = "rcc-self-stretch",
    FLEX = "rcc-flex",
    FLEX_GROW = "rcc-flexGrow",
    FLEX_SHRINK = "rcc-flexShrink",
    FULL_WIDTH = "rcc-full-width",
    FULL_HEIGHT = "rcc-full-height",
    NO_SELECT = "rcc-no-select",
    POINTER = "rcc-pointer",
    BLOCK = "rcc-block",
    INLINE_BLOCK = "rcc-inline-block",
    INVISIBLE = "rcc-invisible",
    NO_DISPLAY = "rcc-no-display",
    SEPARATOR_X = "rcc-separator-x",
    SEPARATOR_Y = "rcc-separator-y",
    OVERFLOW_HIDDEN = "rcc-overflow-hidden",
    OVERFLOW_AUTO = "rcc-overflow-auto",
    MA_0 = "rcc-ma0",
    MT_0 = "rcc-mt0",
    MR_0 = "rcc-mr0",
    MB_0 = "rcc-mb0",
    ML_0 = "rcc-ml0",
    MH_0 = "rcc-mh0",
    MV_0 = "rcc-mv0",
    MA_1 = "rcc-ma1",
    MT_1 = "rcc-mt1",
    MR_1 = "rcc-mr1",
    MB_1 = "rcc-mb1",
    ML_1 = "rcc-ml1",
    MH_1 = "rcc-mh1",
    MV_1 = "rcc-mv1",
    MA_2 = "rcc-ma2",
    MT_2 = "rcc-mt2",
    MR_2 = "rcc-mr2",
    MB_2 = "rcc-mb2",
    ML_2 = "rcc-ml2",
    MH_2 = "rcc-mh2",
    MV_2 = "rcc-mv2",
    MA_3 = "rcc-ma3",
    MT_3 = "rcc-mt3",
    MR_3 = "rcc-mr3",
    MB_3 = "rcc-mb3",
    ML_3 = "rcc-ml3",
    MH_3 = "rcc-mh3",
    MV_3 = "rcc-mv3",
    MA_4 = "rcc-ma4",
    MT_4 = "rcc-mt4",
    MR_4 = "rcc-mr4",
    MB_4 = "rcc-mb4",
    ML_4 = "rcc-ml4",
    MH_4 = "rcc-mh4",
    MV_4 = "rcc-mv4",
    MA_5 = "rcc-ma5",
    MT_5 = "rcc-mt5",
    MR_5 = "rcc-mr5",
    MB_5 = "rcc-mb5",
    ML_5 = "rcc-ml5",
    MH_5 = "rcc-mh5",
    MV_5 = "rcc-mv5",
    MA_6 = "rcc-ma6",
    MT_6 = "rcc-mt6",
    MR_6 = "rcc-mr6",
    MB_6 = "rcc-mb6",
    ML_6 = "rcc-ml6",
    MH_6 = "rcc-mh6",
    MV_6 = "rcc-mv6",
    MA_7 = "rcc-ma7",
    MT_7 = "rcc-mt7",
    MR_7 = "rcc-mr7",
    MB_7 = "rcc-mb7",
    ML_7 = "rcc-ml7",
    MH_7 = "rcc-mh7",
    MV_7 = "rcc-mv7",
    MA_8 = "rcc-ma8",
    MT_8 = "rcc-mt8",
    MR_8 = "rcc-mr8",
    MB_8 = "rcc-mb8",
    ML_8 = "rcc-ml8",
    MH_8 = "rcc-mh8",
    MV_8 = "rcc-mv8",
    MA_9 = "rcc-ma9",
    MT_9 = "rcc-mt9",
    MR_9 = "rcc-mr9",
    MB_9 = "rcc-mb9",
    ML_9 = "rcc-ml9",
    MH_9 = "rcc-mh9",
    MV_9 = "rcc-mv9",
    MA_10 = "rcc-ma10",
    MT_10 = "rcc-mt10",
    MR_10 = "rcc-mr10",
    MB_10 = "rcc-mb10",
    ML_10 = "rcc-ml10",
    MH_10 = "rcc-mh10",
    MV_10 = "rcc-mv10",
    MA_11 = "rcc-ma11",
    MT_11 = "rcc-mt11",
    MR_11 = "rcc-mr11",
    MB_11 = "rcc-mb11",
    ML_11 = "rcc-ml11",
    MH_11 = "rcc-mh11",
    MV_11 = "rcc-mv11",
    MA_12 = "rcc-ma12",
    MT_12 = "rcc-mt12",
    MR_12 = "rcc-mr12",
    MB_12 = "rcc-mb12",
    ML_12 = "rcc-ml12",
    MH_12 = "rcc-mh12",
    MV_12 = "rcc-mv12",
    MA_13 = "rcc-ma13",
    MT_13 = "rcc-mt13",
    MR_13 = "rcc-mr13",
    MB_13 = "rcc-mb13",
    ML_13 = "rcc-ml13",
    MH_13 = "rcc-mh13",
    MV_13 = "rcc-mv13",
    MA_14 = "rcc-ma14",
    MT_14 = "rcc-mt14",
    MR_14 = "rcc-mr14",
    MB_14 = "rcc-mb14",
    ML_14 = "rcc-ml14",
    MH_14 = "rcc-mh14",
    MV_14 = "rcc-mv14",
    MA_15 = "rcc-ma15",
    MT_15 = "rcc-mt15",
    MR_15 = "rcc-mr15",
    MB_15 = "rcc-mb15",
    ML_15 = "rcc-ml15",
    MH_15 = "rcc-mh15",
    MV_15 = "rcc-mv15",
    MA_16 = "rcc-ma16",
    MT_16 = "rcc-mt16",
    MR_16 = "rcc-mr16",
    MB_16 = "rcc-mb16",
    ML_16 = "rcc-ml16",
    MH_16 = "rcc-mh16",
    MV_16 = "rcc-mv16",
    MA_17 = "rcc-ma17",
    MT_17 = "rcc-mt17",
    MR_17 = "rcc-mr17",
    MB_17 = "rcc-mb17",
    ML_17 = "rcc-ml17",
    MH_17 = "rcc-mh17",
    MV_17 = "rcc-mv17",
    MA_18 = "rcc-ma18",
    MT_18 = "rcc-mt18",
    MR_18 = "rcc-mr18",
    MB_18 = "rcc-mb18",
    ML_18 = "rcc-ml18",
    MH_18 = "rcc-mh18",
    MV_18 = "rcc-mv18",
    MA_19 = "rcc-ma19",
    MT_19 = "rcc-mt19",
    MR_19 = "rcc-mr19",
    MB_19 = "rcc-mb19",
    ML_19 = "rcc-ml19",
    MH_19 = "rcc-mh19",
    MV_19 = "rcc-mv19",
    MA_20 = "rcc-ma20",
    MT_20 = "rcc-mt20",
    MR_20 = "rcc-mr20",
    MB_20 = "rcc-mb20",
    ML_20 = "rcc-ml20",
    MH_20 = "rcc-mh20",
    MV_20 = "rcc-mv20",
    P_0 = "rcc-p0",
    PA_0 = "rcc-pa0",
    PT_0 = "rcc-pt0",
    PR_0 = "rcc-pr0",
    PB_0 = "rcc-pb0",
    PL_0 = "rcc-pl0",
    PH_0 = "rcc-ph0",
    PV_0 = "rcc-pv0",
    P_1 = "rcc-p1",
    PA_1 = "rcc-pa1",
    PT_1 = "rcc-pt1",
    PR_1 = "rcc-pr1",
    PB_1 = "rcc-pb1",
    PL_1 = "rcc-pl1",
    PH_1 = "rcc-ph1",
    PV_1 = "rcc-pv1",
    P_2 = "rcc-p2",
    PA_2 = "rcc-pa2",
    PT_2 = "rcc-pt2",
    PR_2 = "rcc-pr2",
    PB_2 = "rcc-pb2",
    PL_2 = "rcc-pl2",
    PH_2 = "rcc-ph2",
    PV_2 = "rcc-pv2",
    P_3 = "rcc-p3",
    PA_3 = "rcc-pa3",
    PT_3 = "rcc-pt3",
    PR_3 = "rcc-pr3",
    PB_3 = "rcc-pb3",
    PL_3 = "rcc-pl3",
    PH_3 = "rcc-ph3",
    PV_3 = "rcc-pv3",
    P_4 = "rcc-p4",
    PA_4 = "rcc-pa4",
    PT_4 = "rcc-pt4",
    PR_4 = "rcc-pr4",
    PB_4 = "rcc-pb4",
    PL_4 = "rcc-pl4",
    PH_4 = "rcc-ph4",
    PV_4 = "rcc-pv4",
    P_5 = "rcc-p5",
    PA_5 = "rcc-pa5",
    PT_5 = "rcc-pt5",
    PR_5 = "rcc-pr5",
    PB_5 = "rcc-pb5",
    PL_5 = "rcc-pl5",
    PH_5 = "rcc-ph5",
    PV_5 = "rcc-pv5",
    P_6 = "rcc-p6",
    PA_6 = "rcc-pa6",
    PT_6 = "rcc-pt6",
    PR_6 = "rcc-pr6",
    PB_6 = "rcc-pb6",
    PL_6 = "rcc-pl6",
    PH_6 = "rcc-ph6",
    PV_6 = "rcc-pv6",
    P_7 = "rcc-p7",
    PA_7 = "rcc-pa7",
    PT_7 = "rcc-pt7",
    PR_7 = "rcc-pr7",
    PB_7 = "rcc-pb7",
    PL_7 = "rcc-pl7",
    PH_7 = "rcc-ph7",
    PV_7 = "rcc-pv7",
    P_8 = "rcc-p8",
    PA_8 = "rcc-pa8",
    PT_8 = "rcc-pt8",
    PR_8 = "rcc-pr8",
    PB_8 = "rcc-pb8",
    PL_8 = "rcc-pl8",
    PH_8 = "rcc-ph8",
    PV_8 = "rcc-pv8",
    P_9 = "rcc-p9",
    PA_9 = "rcc-pa9",
    PT_9 = "rcc-pt9",
    PR_9 = "rcc-pr9",
    PB_9 = "rcc-pb9",
    PL_9 = "rcc-pl9",
    PH_9 = "rcc-ph9",
    PV_9 = "rcc-pv9",
    P_10 = "rcc-p10",
    PA_10 = "rcc-pa10",
    PT_10 = "rcc-pt10",
    PR_10 = "rcc-pr10",
    PB_10 = "rcc-pb10",
    PL_10 = "rcc-pl10",
    PH_10 = "rcc-ph10",
    PV_10 = "rcc-pv10",
    P_11 = "rcc-p11",
    PA_11 = "rcc-pa11",
    PT_11 = "rcc-pt11",
    PR_11 = "rcc-pr11",
    PB_11 = "rcc-pb11",
    PL_11 = "rcc-pl11",
    PH_11 = "rcc-ph11",
    PV_11 = "rcc-pv11",
    P_12 = "rcc-p12",
    PA_12 = "rcc-pa12",
    PT_12 = "rcc-pt12",
    PR_12 = "rcc-pr12",
    PB_12 = "rcc-pb12",
    PL_12 = "rcc-pl12",
    PH_12 = "rcc-ph12",
    PV_12 = "rcc-pv12",
    P_13 = "rcc-p13",
    PA_13 = "rcc-pa13",
    PT_13 = "rcc-pt13",
    PR_13 = "rcc-pr13",
    PB_13 = "rcc-pb13",
    PL_13 = "rcc-pl13",
    PH_13 = "rcc-ph13",
    PV_13 = "rcc-pv13",
    P_14 = "rcc-p14",
    PA_14 = "rcc-pa14",
    PT_14 = "rcc-pt14",
    PR_14 = "rcc-pr14",
    PB_14 = "rcc-pb14",
    PL_14 = "rcc-pl14",
    PH_14 = "rcc-ph14",
    PV_14 = "rcc-pv14",
    P_15 = "rcc-p15",
    PA_15 = "rcc-pa15",
    PT_15 = "rcc-pt15",
    PR_15 = "rcc-pr15",
    PB_15 = "rcc-pb15",
    PL_15 = "rcc-pl15",
    PH_15 = "rcc-ph15",
    PV_15 = "rcc-pv15",
    P_16 = "rcc-p16",
    PA_16 = "rcc-pa16",
    PT_16 = "rcc-pt16",
    PR_16 = "rcc-pr16",
    PB_16 = "rcc-pb16",
    PL_16 = "rcc-pl16",
    PH_16 = "rcc-ph16",
    PV_16 = "rcc-pv16",
    P_17 = "rcc-p17",
    PA_17 = "rcc-pa17",
    PT_17 = "rcc-pt17",
    PR_17 = "rcc-pr17",
    PB_17 = "rcc-pb17",
    PL_17 = "rcc-pl17",
    PH_17 = "rcc-ph17",
    PV_17 = "rcc-pv17",
    P_18 = "rcc-p18",
    PA_18 = "rcc-pa18",
    PT_18 = "rcc-pt18",
    PR_18 = "rcc-pr18",
    PB_18 = "rcc-pb18",
    PL_18 = "rcc-pl18",
    PH_18 = "rcc-ph18",
    PV_18 = "rcc-pv18",
    P_19 = "rcc-p19",
    PA_19 = "rcc-pa19",
    PT_19 = "rcc-pt19",
    PR_19 = "rcc-pr19",
    PB_19 = "rcc-pb19",
    PL_19 = "rcc-pl19",
    PH_19 = "rcc-ph19",
    PV_19 = "rcc-pv19",
    P_20 = "rcc-p20",
    PA_20 = "rcc-pa20",
    PT_20 = "rcc-pt20",
    PR_20 = "rcc-pr20",
    PB_20 = "rcc-pb20",
    PL_20 = "rcc-pl20",
    PH_20 = "rcc-ph20",
    PV_20 = "rcc-pv20",
    OPA_1 = "rcc-opa1",
    OPA_2 = "rcc-opa2",
    OPA_3 = "rcc-opa3",
    OPA_4 = "rcc-opa4",
    OPA_5 = "rcc-opa5",
    OPA_6 = "rcc-opa6",
    OPA_7 = "rcc-opa7",
    OPA_8 = "rcc-opa8",
    OPA_9 = "rcc-opa9",
    V_MIDDLE = "rcc-v-middle",
    RELATIVE = "rcc-relative",
    ABSOLUTE = "rcc-absolute",
    FIXED = "rcc-fixed",
    SHADOW = "rcc-shadow",
    TEXT_LEFT = "rcc-text-left",
    TEXT_CENTER = "rcc-text-center",
    TEXT_RIGHT = "rcc-text-right",
    TEXT_NOWRAP = "rcc-text-nowrap",
    ELLIPSIS = "rcc-ellipsis",
    SCALE_0 = "rcc-scale0",
    SCALE_0_25 = "rcc-scale0_25",
    SCALE_0_5 = "rcc-scale0_5",
    SCALE_0_75 = "rcc-scale0_75",
    SCALE_QUARTER = "rcc-scale-quarter",
    SCALE_HALF = "rcc-scale-half",
    SCALE_THREE_QUARTERS = "rcc-scale-three-quarters",
    SCALE_1 = "rcc-scale1",
    SCALE_2 = "rcc-scale2",
    SCALE_3 = "rcc-scale3",
    SCALE_4 = "rcc-scale4",
    ROTATE_X_90 = "rcc-rotate-x90",
    ROTATE_X_180 = "rcc-rotate-x180",
    ROTATE_X_270 = "rcc-rotate-x270",
    FLIP_H = "rcc-flip-h",
    FLIP_V = "rcc-flip-v",
    SCALE_X_0 = "rcc-scale-x0",
    SCALE_X_025 = "rcc-scale-x-025",
    SCALE_X_05 = "rcc-scale-x-05",
    SCALE_X_0_75 = "rcc-scale-x0_75",
    SCALE_X_QUARTER = "rcc-scale-x-quarter",
    SCALE_X_HALF = "rcc-scale-x-half",
    SCALE_X_THREE_QUARTERS = "rcc-scale-x-three-quarters",
    SCALE_X_1 = "rcc-scale-x1",
    SCALE_X_2 = "rcc-scale-x2",
    SCALE_X_3 = "rcc-scale-x3",
    SCALE_X_4 = "rcc-scale-x4",
    SCALE_Y_0 = "rcc-scale-y0",
    SCALE_Y_0_25 = "rcc-scale-y0_25",
    SCALE_Y_0_5 = "rcc-scale-y0_5",
    SCALE_Y_0_75 = "rcc-scale-y0_75",
    SCALE_Y_QUARTER = "rcc-scale-y-quarter",
    SCALE_Y_HALF = "rcc-scale-y-half",
    SCALE_Y_THREE_QUARTERS = "rcc-scale-y-three-quarters",
    SCALE_Y_1 = "rcc-scale-y1",
    SCALE_Y_2 = "rcc-scale-y2",
    SCALE_Y_3 = "rcc-scale-y3",
    SCALE_Y_4 = "rcc-scale-y4",
    GAP_HALF = "rcc-gap-half",
    GAP_1 = "rcc-gap1",
    GAP_2 = "rcc-gap2",
    GAP_3 = "rcc-gap3",
    GAP_4 = "rcc-gap4",
    GAP_5 = "rcc-gap5",
    GAP_6 = "rcc-gap6",
    GAP_7 = "rcc-gap7",
    GAP_8 = "rcc-gap8",
    FONT_SIZE_H1 = "rcc-font-36",
    FONT_SIZE_H2 = "rcc-font-18",
    FONT_SIZE_H3 = "rcc-font-12",
    FONT_SIZE_H4 = "rcc-font-10",
    FONT_SIZE_H5 = "rcc-font-10",
    FONT_SIZE_H6 = "rcc-font-20",
    FONT_SIZE_BODY1 = "rcc-font16",
    FONT_BOLD = "rcc-font-bold",
    FONT_ITALIC = "rcc-font-italic"
}
