import { AccessTokenPayload, RefreshTokenPayload } from "./authTypes"

export enum ROLES {
    PUBLIC_STUDENT = "public_student",
    STUDENT = "student",
    FACULTY = "faculty",
    ADMIN = "admin"
}

export interface DecodedJwt {
    token: string
    tokenRefresh: string
    payload: AccessTokenPayload
    payloadRefresh: RefreshTokenPayload
}

export interface JwtPayload {
    userId?: number
    role?: ROLES
    issued?: string
    issuedFormat?: string
    durationMinutes?: number
    exp?: number
}

export enum RESOURCE_URL_TYPE {
    STUDENT_CHECK_LIST = "STUDENT_CHECK_LIST",
    ASSIGNMENT_TAKE_IMAGE = "ASSIGNMENT_TAKE_IMAGE",
    PROFILE_UPLOAD = "PROFILE_UPLOAD"
}

export const LOCAL_STORAGE_LOGGED_IN_USER_JWT = "loggedInUserJWT"
export const LOCAL_STORAGE_LOGGED_IN_USER_ACCESS_TOKEN = "accessToken"
