import { difference, intersection, uniq } from "lodash"
import { notEmpty } from "../helpers"
import { GameGroup, PaidSeatsCourse, PaidSeatsProduct, Student } from "../types"

// checkStudentsPaidSeats
//
// used by main checkExistingPaidSeats function below, called for each "paid seat course" and "paid seat product" record
// for a given school
const checkStudentsPaidSeats = (groupIds: string[], paidSeats: PaidSeatsCourse | PaidSeatsProduct, incomingStudents: Student[]) => {
    const studentIdsAlreadyMembers = uniq(paidSeats.studentIds).sort()

    const allStudentsInGroups = incomingStudents.filter(student => intersection(student.groupIds, groupIds).length)
    const studentIds = uniq(allStudentsInGroups.filter(student => student.id).map(student => student.id)).sort()
    const studentIdsToAdd = difference(studentIds, studentIdsAlreadyMembers)

    // this function also handles students not yet created, students without ids.
    // so it can be used to check if paid seats would be violated before students are created
    const newStudents = allStudentsInGroups.filter(student => !student.id).filter(notEmpty)

    if (studentIdsToAdd.length + newStudents.length + studentIdsAlreadyMembers.length > paidSeats.total) {
        return groupIds
    }

    return []
}

export interface CheckPaidSeatsParams {
    paidSeatsCourse: PaidSeatsCourse[]
    paidSeatsProduct: PaidSeatsProduct[]
    incomingGroups: GameGroup[]
    incomingStudents: Student[]
}

const checkMissingPaidSeats = (params: CheckPaidSeatsParams) => {
    const { paidSeatsCourse, paidSeatsProduct, incomingGroups } = params

    const paidSeatsCourseIds = uniq(paidSeatsCourse.map(ps => ps.courseId))
    const paidSeatsProductIds = uniq(paidSeatsProduct.map(ps => ps.productId))

    const groupsMissingCourses = incomingGroups.filter(g => {
        return g.isActive && intersection(g.courseIds, paidSeatsCourseIds).length !== g.courseIds.length
    })

    const groupsMissingProducts = incomingGroups.filter(g => {
        return g.isActive && intersection(g.productIds, paidSeatsProductIds).length !== g.productIds.length
    })

    return uniq([...groupsMissingCourses.map(g => g.id), ...groupsMissingProducts.map(g => g.id)])
}

const checkExistingPaidSeats = (params: CheckPaidSeatsParams) => {
    const { paidSeatsCourse, paidSeatsProduct, incomingGroups, incomingStudents } = params
    let problemGroupIds: string[] = []

    paidSeatsCourse.forEach(paidSeats => {
        const groupIdsWithCourse = incomingGroups.filter(group => group.isActive && group.courseIds.includes(paidSeats.courseId)).map(g => g.id)
        problemGroupIds = [...problemGroupIds, ...checkStudentsPaidSeats(groupIdsWithCourse, paidSeats, incomingStudents)]
    })

    paidSeatsProduct.forEach(paidSeats => {
        const groupIdsWithProduct = incomingGroups.filter(group => group.isActive && group.productIds.includes(paidSeats.productId)).map(g => g.id)
        problemGroupIds = [...problemGroupIds, ...checkStudentsPaidSeats(groupIdsWithProduct, paidSeats, incomingStudents)]
    })

    return uniq(problemGroupIds)
}

//
// checkPaidSeats
//
// checks if paid seats would be violated, given a set of groups, and a set of students
//
// input: paidSeatsCourse/paidSeatsProduct, which come from the School model
//
// input: an array of Group objects, so we can check which courses/products they have
//
// input: an array of Student objects, so we can check which students are in the groups
//        (important note: you can also supply students with no IDs, that is, students which are
//        not yet part of the groups, but which we want to add. this is part of why this function
//        doesn't simply accept an array of student IDs, and also why we check membership
//        based on the groupIds property of the Student object, rather than the existing
//        membership in the Group object)
//
// output: an array of group IDs that would violate paid seats if all the proposed students were imported
//
// rules for paid seats:
//      schools are associated with "paid seat course" or "paid seat product" records (people_paidseats in db)
//      a single seat for any given course or product is occupied
//      if a student belongs to one or more groups in a school that has one of the courses/products
//      associated with paid seats for that school, and at least one of the groups is active
//      that is, if a student belongs to two groups with the same course, and that course has a paid seat
//      record defined for the school, it is still just one seat. it isn't counted twice.
//
export const checkPaidSeats = (params: CheckPaidSeatsParams) => {
    const groupIdsMissingPaidSeats = checkMissingPaidSeats(params)
    const groupIdsProblemPaidSeats = checkExistingPaidSeats(params)

    return uniq([...groupIdsMissingPaidSeats, ...groupIdsProblemPaidSeats])
}
