import { isArray } from "lodash"
import { v4 as uuid } from "uuid"
import {
    AdaptiveInitialDiagnostic,
    ADAPTIVE_INITIAL_DIAGNOSTIC_STATUS,
    AnalyticsFilter,
    AnalyticsMastery,
    AssignmentBlueprintWithStudents,
    ASSIGNMENT_BLUEPRINT_WITH_STUDENTS_STATUS,
    ASSIGNMENT_TAKE_STATUS,
    BatchJobProgress,
    BATCH_JOB_STATUS,
    BATCH_JOB_TYPE,
    Book,
    BookObjectDeep,
    BOOK_USAGE_REWARD_MESSAGE,
    CatalogTopic,
    CATALOG_LEVEL,
    Comment,
    CONCEPT_TYPE,
    Course,
    CourseWithUnits,
    Deck,
    LearningCenterBookUsage,
    LearningResource,
    LearningResourceFlat,
    LEARNING_CENTER_ASSIGNMENT_STATUS,
    LEARNING_MODULE_SKILL,
    LEARNING_RESOURCE_TYPE,
    LLCatalog,
    PeopleProfile,
    PROMPT_TYPE,
    RosterDistrict,
    RosterLink,
    RosterLinkCollection,
    RosterMeta,
    RosterObjectCollection,
    ROSTER_OBJECT_TYPE,
    ROSTER_PROBLEM,
    ROSTER_SOURCE,
    TakeBlueprintWithStudents,
    TAKE_RESPONSE_TYPE,
    TOPIC_STATUS,
    UserDefinedDateRange,
    V3MasteryStatusChangeEvent,
    V3Prompt,
    V3Take,
    V3_MASTERY_STATUS
} from "../types"
import { AppConfig, FeatureFlags } from "../types/appConfigTypes"
import { AvatarAssets, AvatarData, OwnedAssets } from "../types/avatarTypes"
import {
    LANGUAGE,
    LANGUAGE_CODE,
    MetaData,
    Product,
    ProfileSettings,
    PROF_LEVEL,
    SettingsAndFeatureFlags,
    SETTINGS_AND_FEATURE_FLAGS
} from "../types/baseTypes"
import { BaseGameInstance, GAME_VARIANT } from "../types/gameTypes"
import { PhonicsCard, PhonicsDeck, PhonicsWorld, PhonicsWorldParticle } from "../types/phonicTypes"
import {
    DEPLOY_LOCATION,
    District,
    Faculty,
    GameGroup,
    LEARNING_RESOURCE_KIND,
    NotificationMessage,
    NotificationState,
    PeopleProfileWithHeartbeat,
    School,
    Student,
    StudentGamesPlayedHistory
} from "../types/studentTypes"
import {
    ElectronicResponse,
    FluencyResponse,
    MultipleChoiceResponse,
    ObjectiveGrade,
    OBJECTIVE_MASTERY_STATUS,
    ReadingResponse,
    WrittenResponse
} from "../types/testTypes"
import notEmpty from "./notEmpty"
import { removeUndefined } from "./removeUndefined"

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>
}

export const BASE_BLANK = {
    BATCH_JOB_PROGRESS: (params?: Partial<BatchJobProgress>): BatchJobProgress => {
        return {
            id: "",
            status: BATCH_JOB_STATUS.NOT_STARTED,
            type: BATCH_JOB_TYPE.ANALYTICS,
            message: "",
            progress: 0,
            dateCreatedMs: new Date().getTime(),
            dateFinishedMs: null,
            dateStartedMs: null,
            createdById: null,
            result: null,
            ...removeUndefined(params)
        }
    },
    AVATAR_ASSETS: (params?: Partial<AvatarAssets>): AvatarAssets => {
        return {
            ACCESSORY_HEAD: [],
            ACCESSORY_LEFT_ARM: [],
            ACCESSORY_LEFT_LEG: [],
            ACCESSORY_RIGHT_ARM: [],
            ACCESSORY_RIGHT_LEG: [],
            ACCESSORY_TORSO: [],
            EYES: [],
            HAIR: [],
            HEAD: "",
            MOUTH: [],
            NOSE: [],
            OUTFIT: [],
            PET: [],
            ...removeUndefined(params)
        }
    },
    OWNED_ASSETS: (params?: Partial<OwnedAssets>): OwnedAssets => {
        return {
            colors: [],
            face: {
                eyes: [],
                accessories: [],
                hair: [],
                mouth: [],
                nose: []
            },
            outfit: {
                accessories: {
                    leftArm: [],
                    leftLeg: [],
                    torso: [],
                    rightArm: [],
                    rightLeg: []
                },
                name: []
            },
            pet: [],
            ...removeUndefined(params)
        }
    },
    AVATAR_DATA: (params?: Partial<AvatarData>): AvatarData => {
        return {
            face: {
                eyes: "closed",
                nose: "basic",
                mouth: "mouth",
                hair: {
                    name: "basic",
                    color: {}
                },
                accessories: []
            },
            outfit: {
                name: "basic",
                color: {}
            },
            pet: {
                name: "",
                color: {}
            },
            ...removeUndefined(params)
        }
    },
    DECK: (params?: Partial<Deck>): Deck => {
        return {
            id: "",
            name: "",
            friendlyName: "",
            profLevel: PROF_LEVEL.NONE,
            glossaryType: undefined,
            cards: [],
            ...removeUndefined(params)
        }
    },
    BASE_GAME_INSTANCE: (params?: Partial<BaseGameInstance>): BaseGameInstance => {
        const now = new Date()
        return {
            id: "",
            name: "Parrot",
            params: {
                id: "",
                cardsPerTurn: params?.params?.cardsPerTurn || 0,
                dateCreated: now.toISOString(),
                deck: BASE_BLANK.DECK(),
                language: LANGUAGE.CHINESE,
                multiplayer: false,
                playerIds: [],
                playerSessionIds: [],
                turnLimit: 0,
                variant: GAME_VARIANT.NONE,
                withHelpAudioForCards: false,
                friendlyName: "",
                profLevel: PROF_LEVEL.NONE,
                sceneId: "",
                segmentId: null,
                studentGroupId: null,
                ...params?.params
            },
            state: {
                finished: false,
                loserIds: [],
                playerIds: [],
                playerStates: {},
                turn: { activePlayers: [], dateReady: "", number: 0, phase: "" },
                winnerIds: [],
                ...params?.state
            },
            ...removeUndefined(params)
        }
    },
    GAME_GROUP: (params?: Partial<GameGroup>): GameGroup => {
        return {
            id: "",
            name: "",
            remoteIdentifier: "",
            remoteName: "",
            studentIds: [],
            facultyIds: [],
            productIds: [],
            courseIds: [],
            schoolId: "",
            uuid: "",
            ...removeUndefined(params)
        }
    },
    PEOPLE_PROFILE: (params?: Partial<PeopleProfile>): PeopleProfile => {
        return {
            id: "",
            xp: 0,
            coins: 0,
            dateCreated: new Date().toISOString(),
            name: "",
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            referenceId: "",
            isActive: true,
            avatarData: BASE_BLANK.AVATAR_DATA(params?.avatarData),
            allowDemoAccountCreation: false,
            isOnline: false,
            multiplayerPartyId: null,
            currentGameId: null,
            currentPath: null,
            ...removeUndefined(params)
        }
    },
    PEOPLE_PROFILE_WITH_HEARTBEAT: (params?: Partial<PeopleProfileWithHeartbeat>): PeopleProfileWithHeartbeat => {
        return {
            dateHeartbeatMs: 0,
            ...BASE_BLANK.PEOPLE_PROFILE(params),
            ...removeUndefined(params)
        }
    },
    STUDENT: (params?: Partial<Student>): Student => {
        return {
            ...BASE_BLANK.PEOPLE_PROFILE(params),
            classroomId: "",
            districtId: "",
            schoolId: "",
            groups: [],
            groupIds: [],
            cohort: null,
            ...removeUndefined(params)
        }
    },
    DISTRICT: (params?: Partial<District>): District => {
        return {
            id: "",
            name: "",
            disableSync: false,
            disableSyncGroupsFaculty: false,
            disableSyncGroupsStudents: false,
            disableSyncGroupGrades: false,
            schoolIds: [],
            isActive: true,
            ...removeUndefined(params)
        }
    },
    ROSTER_DISTRICT: (params?: Partial<RosterDistrict>): RosterDistrict => {
        return {
            ...BASE_BLANK.DISTRICT(),
            pauseEndMs: 0,
            pauseStartMs: 0,
            ...removeUndefined(params)
        }
    },
    SCHOOL: (params?: Partial<School>): School => {
        return {
            id: "",
            name: "",
            slug: "",
            paidSeatsCourse: [],
            paidSeatsProduct: [],
            deployLocation: DEPLOY_LOCATION.GLOBAL,
            studentIds: [],
            facultyIds: [],
            groupIds: [],
            districtId: "",
            sourceDemoSchoolName: null,
            isActive: true,
            ...removeUndefined(params)
        }
    },
    FACULTY: (params?: Partial<Faculty>): Faculty => {
        return {
            ...BASE_BLANK.STUDENT(params),
            schools: [],
            schoolIds: [],
            groupIds: [],
            districtIds: [],
            uuid: "",
            ...removeUndefined(params)
        }
    },
    STUDENT_GAMES_PLAYED_HISTORY: (params?: Partial<StudentGamesPlayedHistory>): StudentGamesPlayedHistory => {
        return {
            id: "",
            pts: 0,
            gamesPairs1P: { played: 0, ptsEarnedLifetime: 0, won: 0 },
            gamesPairs2P: { played: 0, ptsEarnedLifetime: 0, won: 0 },
            gamesParrot1P: { played: 0, ptsEarnedLifetime: 0, won: 0 },
            gamesParrot2P: { played: 0, ptsEarnedLifetime: 0, won: 0 },
            gamesScenario: { played: 0, ptsEarnedLifetime: 0, won: 0 },
            gamesPlayed: 0,
            ...removeUndefined(params)
        }
    },
    NOTIFICATION_STATE: (params?: Partial<NotificationState>): NotificationState => {
        return {
            notificationId: "",
            forStudentId: "",
            seen: false,
            ...removeUndefined(params)
        }
    },
    NOTIFICATION_MESSAGE: (): NotificationMessage => {
        return {
            body: {
                text: ""
            },
            subject: ""
        }
    },
    COURSE: (params?: Partial<Course>): Course => {
        return {
            id: "",
            name: "",
            slug: "",
            is_active: true,
            nothing_mastered_label: "",
            masteryThreshold: 0,
            order: 0,
            assessmentTierId: "",
            courseAdminId: "",
            createdAtMs: new Date().getTime(),
            createdById: null,
            isPremium: false,
            languageId: "",
            orderedConcepts: false,
            orderedObjectives: false,
            orderedTopics: false,
            orderedUnits: false,
            sendToEdfi: false,
            showLearningCenter: false,
            subjectId: "",
            updatedAtMs: new Date().getTime(),
            updatedById: null,
            uuid: "",
            ...removeUndefined(params)
        }
    },
    COURSE_WITH_UNITS: (params?: Partial<CourseWithUnits>): CourseWithUnits => {
        return {
            ...BASE_BLANK.COURSE(params),
            units: [],
            ...removeUndefined(params)
        }
    },
    PRODUCT: (params?: Partial<Product>): Product => {
        return {
            id: "",
            name: "",
            skill: LEARNING_MODULE_SKILL.READING,
            languageCode: LANGUAGE_CODE.CHINESE_SIMPLIFIED,
            ...removeUndefined(params)
        }
    },
    V3_PROMPT: (params?: Partial<V3Prompt>): V3Prompt => {
        return {
            id: "",
            name: "",
            type: PROMPT_TYPE.WRITING,
            topicId: "",
            sourceId: "",
            courseId: "",
            objectives: [],
            studentChecklistUrl: "",
            prompt: "",
            isActive: false,
            isCurated: false,
            metaData: BASE_BLANK.METADATA(params?.metaData),
            multipleChoiceCorrectAnswerKey: null,
            multipleChoiceOptions: null,
            quizInstructionAudioEn: null,
            quizInstructionAudioEs: null,
            quizInstructionAudioCn: null,
            quizInstructionTextEn: null,
            quizInstructionTextEs: null,
            quizInstructionTextCn: null,
            quizImageUrl: null,
            questionImageUrl: null,
            questionAudioEn: null,
            questionAudioEs: null,
            questionAudioCn: null,
            quizId: null,
            v3TestId: null,
            isStatic: false,
            ...removeUndefined(params)
        }
    },
    COURSE_TOPIC: (params?: Partial<CatalogTopic>): CatalogTopic => {
        return {
            id: "",
            name: "",
            slug: "",
            isActive: false,
            unitId: "",
            createdAtMs: 0,
            updatedAtMs: 0,
            createdById: "",
            updatedById: "",
            uuid: "",
            description: "",
            order: 0,
            shortIdentifier: "",
            learningResourceKind: LEARNING_RESOURCE_KIND.ONLY_LEARNING_RESOURCE,
            masteryThreshold: 0,
            ...removeUndefined(params)
        }
    },
    METADATA: (params?: Partial<MetaData>): MetaData => {
        return {
            dateCreatedMs: new Date().getTime(),
            dateUpdatedMs: new Date().getTime(),
            deleted: false,
            districtId: "",
            schoolId: "",
            classroomId: "",
            createdByUserId: "",
            updatedByUserId: "",
            ...removeUndefined(params)
        }
    },
    V3_TAKE: (params?: Partial<V3Take>): V3Take => {
        const now = new Date().getTime()
        return {
            id: "",
            prompts: [],
            sourceId: null,
            courseIds: [],
            objectiveGrades: [],
            status: ASSIGNMENT_TAKE_STATUS.ASSIGNED,
            student: BASE_BLANK.STUDENT(params?.student),
            studentId: params?.student?.id || "",
            responses: [],
            dateDueMs: 0,
            dateAssignedMs: now,
            metaData: BASE_BLANK.METADATA(params?.metaData),
            dateReleaseMs: now,
            dateSubmittedMs: 0,
            enableBookAudio: true,
            hasFluency: false,
            hasPhonics: false,
            hasReading: false,
            hasReadingQuiz: false,
            hasSpeaking: false,
            hasWriting: false,
            isAssessment: false,
            aidId: null,
            passed: false,
            score: null,
            totalScore: null,
            secondsTaken: null,
            v3TestId: "",
            dateSubmitRetryAfterMs: 0,
            numSubmitRetries: 0,
            miniLessonIds: null,
            ...removeUndefined(params)
        }
    },
    LEARNING_RESOURCE_FLAT: (params?: Partial<LearningResourceFlat>): LearningResourceFlat => {
        const now = new Date().toISOString()
        return {
            id: "",
            bookFileId: null,
            bookSeriesId: null,
            countPerPdfPage: null,
            dateCreated: now,
            dateUpdated: now,
            slug: "",
            description: null,
            docFileId: null,
            endPage: null,
            endPageCount: null,
            hasWritingPrompts: false,
            isActive: true,
            multiLevelSetId: null,
            numPages: null,
            productId: "",
            publisherId: null,
            startPage: null,
            startPageCount: null,
            textTypeId: null,
            thumbnailId: null,
            title: null,
            titleTranslation: null,
            topicId: null,
            type: LEARNING_RESOURCE_TYPE.BOOK,
            hasReadingQuiz: false,
            ...removeUndefined(params)
        }
    },
    LEARNING_RESOURCE: (params?: Partial<LearningResource>): LearningResource => {
        return {
            ...BASE_BLANK.LEARNING_RESOURCE_FLAT(params),
            topic: BASE_BLANK.COURSE_TOPIC(params?.topic),
            tagsIds: params?.tagsIds || [],
            thumbnailUrl: "",
            ...removeUndefined(params)
        }
    },
    BOOK: (params?: Partial<Book>): Book => {
        return {
            ...BASE_BLANK.LEARNING_RESOURCE(params),
            type: LEARNING_RESOURCE_TYPE.BOOK,
            numPages: 0,
            startPage: 0,
            endPage: 0,
            startPageCount: 0,
            endPageCount: 0,
            ...removeUndefined(params)
        }
    },
    COMMENT: (params?: Partial<Comment>): Comment => {
        return {
            end: 0,
            start: 0,
            id: "",
            message: "",
            threadId: "",
            metaData: BASE_BLANK.METADATA(params?.metaData),
            objectiveId: null,
            writingPromptId: null,
            writingAssignmentTakeId: null,
            ...removeUndefined(params)
        }
    },
    TAKE_BLUEPRINT_WITH_STUDENTS: (params?: Partial<TakeBlueprintWithStudents>): TakeBlueprintWithStudents => {
        let quiz: V3Prompt | V3Prompt[] = BASE_BLANK.V3_PROMPT()
        if (isArray(params?.quiz)) {
            if (params?.quiz.length) {
                quiz = params.quiz.map(BASE_BLANK.V3_PROMPT)
            }
        } else if (params?.quiz) {
            quiz = BASE_BLANK.V3_PROMPT(params.quiz)
        }

        return {
            dateReleaseMs: 0,
            dateDueMs: 0,
            hasFluency: false,
            hasPhonics: false,
            hasReading: false,
            hasReadingQuiz: false,
            hasSpeaking: false,
            hasWriting: false,
            id: "",
            metaData: BASE_BLANK.METADATA(params?.metaData),
            studentIds: [],
            source: BASE_BLANK.BOOK(params?.source),
            quiz,
            ...removeUndefined(params)
        }
    },
    ASSIGNMENT_BLUEPRINT_WITH_STUDENTS: (params?: Partial<AssignmentBlueprintWithStudents>): AssignmentBlueprintWithStudents => {
        return {
            assignmentBlueprintId: "",
            id: "",
            dateAssignedMs: 0,
            dateDueMs: 0,
            dateReleaseMs: 0,
            sources: [],
            takeBlueprintsWithStudents: [],
            metaData: BASE_BLANK.METADATA(params?.metaData),
            responseType: TAKE_RESPONSE_TYPE.ELECTRONIC,
            enableBookAudio: true,
            enableFluency: false,
            status: ASSIGNMENT_BLUEPRINT_WITH_STUDENTS_STATUS.DRAFT,
            productId: null,
            ...removeUndefined(params)
        }
    },
    APP_CONFIG: (params?: Partial<AppConfig>): AppConfig => {
        return {
            id: "app",
            contentfulDataVersion: 0,
            version: "",
            sessionIdleTimeMs: 1000 * 60 * 10, // 10 minutes
            dateAllBooksJsonUpdatedMs: 0,
            allBooksCounts: {},
            useNewGQLSubscriptionEndpoint: false,
            appMessage: undefined,
            ...removeUndefined(params)
        }
    },
    FEATURE_FLAGS: (params?: Partial<FeatureFlags>): FeatureFlags => {
        return {
            id: "featureflags",
            AUTO_PLAY_ENABLED: false,
            LOG_PANE_ENABLED: true,
            LOG_PANE_MAX_LOGS: 10,
            LOG_PANE_USE_CSS_WILL_CHANGE: true,
            DEV_MODE_PW: "panda",
            LOGGING_USE_LOG_ROCKET: false,
            SIZE_ZOOM_CARD: [512, 512],
            FIREBASE_EXPERIMENTAL_AUTODETECT_LONG_POLLING: false,
            ...removeUndefined(params)
        }
    },
    ADAPTIVE_INTEGRATED_DIAGNOSTIC: (params?: Partial<AdaptiveInitialDiagnostic>): AdaptiveInitialDiagnostic => {
        return {
            id: "",
            isActive: true,
            productId: "",
            studentId: "",
            startingV3TestId: null,
            takenTakes: [],
            status: ADAPTIVE_INITIAL_DIAGNOSTIC_STATUS.ASSIGNED,
            metaData: BASE_BLANK.METADATA(params?.metaData),
            ...removeUndefined(params)
        }
    },
    ROSTER_META: (params?: Partial<RosterMeta>): RosterMeta => {
        return {
            district: 0,
            school: 0,
            studentGroup: 0,
            student: 0,
            faculty: 0,
            ...removeUndefined(params)
        }
    },
    ROSTER_LINK: (params?: Partial<RosterLink>): RosterLink => {
        return {
            id: "",
            type: ROSTER_OBJECT_TYPE.STUDENT,
            sourceName: ROSTER_SOURCE.CLEVER,
            sourceId: "",
            sourceDistrictId: "",
            targetId: "",
            problem: ROSTER_PROBLEM.OK,
            problemContext: [],
            change: false,
            deleted: false,
            metaLinked: BASE_BLANK.ROSTER_META(),
            metaTotal: BASE_BLANK.ROSTER_META(),
            ...removeUndefined(params)
        }
    },
    ROSTER_LINK_COLLECTION: (params?: Partial<RosterLinkCollection>): RosterLinkCollection => {
        return { district: [], school: [], studentGroup: [], student: [], faculty: [], ...removeUndefined(params) }
    },
    ROSTER_OBJECT_COLLECTION: (params?: Partial<RosterObjectCollection>): RosterObjectCollection => {
        return { district: [], school: [], studentGroup: [], student: [], faculty: [], ...removeUndefined(params) }
    },
    BOOK_OBJECT_DEEP: (partial?: Partial<BookObjectDeep>): BookObjectDeep => {
        return {
            ...BASE_BLANK.BOOK(),
            level: "",
            types: [""],
            topics: [{ topic: "", translation: "", type: "" }],
            series: { topic: "", translation: "", type: "" },
            publisher: "",
            publisherTranslation: "",
            quizId: "",
            quizObjectiveIds: [],
            quizObjectiveStaticIds: [],
            audioPageIds: [],
            writingPromptIds: [],
            ...partial
        }
    },
    PROFILE_SETTINGS: (params?: Partial<ProfileSettings>): ProfileSettings => {
        return {
            ADVANCED_TABLES: false,
            ...params
        }
    },
    SETTINGS_AND_FEATURE_FLAGS: (params?: RecursivePartial<SettingsAndFeatureFlags>): SettingsAndFeatureFlags => {
        return {
            id: "",
            districtId: null,
            groupId: null,
            productId: null,
            profileId: null,
            schoolId: null,
            ...removeUndefined(params),
            settings: {
                [SETTINGS_AND_FEATURE_FLAGS.PROFILE_SETTINGS]: {
                    ADVANCED_TABLES: false
                },
                [SETTINGS_AND_FEATURE_FLAGS.SHOW_AUTO_ASSESSMENT_IL_CONTROL]: false,
                [SETTINGS_AND_FEATURE_FLAGS.ASSESSMENTS_VISIBLE]: true,
                [SETTINGS_AND_FEATURE_FLAGS.INCLUDE_FLUENCY]: false,
                [SETTINGS_AND_FEATURE_FLAGS.INCLUDE_AUDIO]: true,
                [SETTINGS_AND_FEATURE_FLAGS.INDEPENDENT_LEARNING_SETTING_UNLOCKED]: true,
                [SETTINGS_AND_FEATURE_FLAGS.INDEPENDENT_LEARNING_ENABLED]: true,
                ...removeUndefined(params?.settings)
            }
        }
    },
    LEARNING_CENTER_BOOK_USAGE: (params?: Partial<LearningCenterBookUsage>): LearningCenterBookUsage => {
        return {
            bookId: "",
            coinsEarned: 0,
            xpEarned: 0,
            createdAtMs: 0,
            updatedAtMs: 0,
            furthestProgress: 0,
            id: "",
            lastReadMs: 0,
            pageViewCount: 0,
            publisherId: "",
            resumeAt: 0,
            rewardMessage: BOOK_USAGE_REWARD_MESSAGE.REWARD_NONE,
            status: LEARNING_CENTER_ASSIGNMENT_STATUS.ASSIGNED,
            isBackfilled: false,
            userId: "",
            v3TakeId: "",
            ...removeUndefined(params)
        }
    },
    TAKE_RESPONSE_READING: (params?: Partial<ReadingResponse>): ReadingResponse => {
        return {
            type: TAKE_RESPONSE_TYPE.READING,
            furthestProgress: 0,
            pageViewCount: 0,
            resumeAt: 0,
            totalPages: 0,
            endPageCount: 0,
            finished: false,
            lastReadMs: 0,
            ...removeUndefined(params)
        }
    },
    TAKE_RESPONSE_FLUENCY: (params?: Partial<FluencyResponse>): FluencyResponse => {
        return {
            type: TAKE_RESPONSE_TYPE.FLUENCY,
            audioUrl: "",
            ...removeUndefined(params)
        }
    },
    TAKE_RESPONSE_ELECTRONIC: (params?: Partial<ElectronicResponse>): ElectronicResponse => {
        return {
            type: TAKE_RESPONSE_TYPE.ELECTRONIC,
            value: "",
            ...params
        }
    },
    TAKE_RESPONSE_HANDWRITTEN: (params?: Partial<WrittenResponse>): WrittenResponse => {
        return {
            type: TAKE_RESPONSE_TYPE.HANDWRITTEN,
            value: [],
            ...params
        }
    },
    TAKE_RESPONSE_MULTIPLE_CHOICE: (params?: Partial<MultipleChoiceResponse>): MultipleChoiceResponse => {
        return {
            type: TAKE_RESPONSE_TYPE.MULTIPLE_CHOICE,
            value: "",
            score: undefined,
            profileQuizRewardId: "",
            ...params
        }
    },
    OBJECTIVE_GRADE: (params?: Partial<ObjectiveGrade>): ObjectiveGrade => {
        return {
            id: uuid(),
            objectiveId: "",
            quizId: "",
            grade: OBJECTIVE_MASTERY_STATUS.NOT_MASTERED,
            ...removeUndefined(params)
        }
    },
    PHONICS_CARD: (params?: Partial<PhonicsCard>): PhonicsCard => {
        return {
            id: "",
            name: "",
            images: [],
            imagesS3: [],
            numWords: 1,

            chineseAudio: "",
            chineseAudioS3: "",
            chineseText: "",
            chineseSyllables: [],
            chineseLetters: [],

            englishAudio: "",
            englishAudioS3: "",
            englishText: "",
            englishSyllables: [],
            englishLetters: [],

            spanishAudio: "",
            spanishAudioS3: "",
            spanishText: "",
            spanishSyllables: [],
            spanishLetters: [],

            englishOnsetRime: [],
            englishOnsetRimeSound: [],
            englishCompoundWords: [],
            spanishOnsetRime: [],
            spanishOnsetRimeSound: [],
            spanishCompoundWords: [],
            chineseOnsetRime: [],
            chineseOnsetRimeSound: [],
            chineseCompoundWords: [],
            englishLettersSound: [],
            spanishLettersSound: [],
            chineseLettersSound: [],
            chineseSyllablesSound: [],
            englishSyllablesSound: [],
            spanishSyllablesSound: [],

            ...removeUndefined(params)
        }
    },
    PHONICS_DECK: (params?: Partial<PhonicsDeck>): PhonicsDeck => {
        return {
            id: "",
            cards: [],
            contentMappingId: "",
            name: "",
            shortId: "",
            redHerrings: [],
            redHerringsByCard: {},
            dontRandomize: false,
            constraints: [],
            answerGroups: [],
            ...params
        }
    },
    PHONICS_WORLD_PARTICLE: (params?: RecursivePartial<PhonicsWorldParticle>): PhonicsWorldParticle => {
        return {
            title: "CherryBlossom",
            direction: "right",
            gravity: 0,
            image: "",
            lifeTimeMs: 0,
            maxCount: 0,
            size: 1,
            speed: 1,
            ...params
        }
    },
    PHONICS_WORLD: (params?: RecursivePartial<PhonicsWorld>): PhonicsWorld => {
        return {
            id: params?.id || "",
            name: params?.name || "",
            background: {
                images: params?.background?.images?.filter(notEmpty) || [],
                speed: params?.background?.speed || 0.8
            },
            midground: {
                images: params?.midground?.images?.filter(notEmpty) || [],
                speed: params?.midground?.speed || 0.75
            },
            foreground: {
                images: params?.foreground?.images?.filter(notEmpty) || [],
                speed: params?.background?.speed || 0.65
            },
            particles: (params?.particles || []).map(BASE_BLANK.PHONICS_WORLD_PARTICLE),
            active: false,
            titlescreen: ""
        }
    },
    DATE_RANGE: (params?: RecursivePartial<UserDefinedDateRange>): UserDefinedDateRange => {
        return {
            id: "",
            dateEndMs: 0,
            dateStartMs: 0,
            createdAtMs: 0,
            createdById: "",
            name: "",
            districtId: "",
            districtName: "",
            ...params
        }
    },
    ANALYTICS_FILTER: (params?: RecursivePartial<AnalyticsFilter>): AnalyticsFilter => {
        return {
            schoolIds: [],
            gradeIds: [],
            courses: [],
            groupIds: [],
            dateRanges: [],
            ...removeUndefined(params)
        }
    },
    ANALYTICS_MASTERY: (params?: RecursivePartial<AnalyticsMastery>): AnalyticsMastery => {
        return {
            ...BASE_BLANK.V3_MASTERY_STATUS_CHANGE_EVENT(),
            dateRangeId: "",
            dateRangeName: "",

            courseId: "",
            courseName: "",

            // student info
            studentId: "",
            groupId: "",
            groupName: "",
            schoolId: "",
            districtId: "",

            // mastery info
            mastered: false,
            approachingMastery: false,

            catalogLevel: CATALOG_LEVEL.OBJECTIVE,
            objectiveId: "",
            objectiveShortIdentifier: "",
            objectiveNameTranslated: "",
            objectiveOrder: 0,
            conceptId: "",
            topicId: "",
            topicOrder: 0,

            instructionalLevelId: "",
            instructionalLevelName: "",
            instructionalLevelStatus: TOPIC_STATUS.NO_RECORD,
            benchmarkId: "",
            benchmarkStatus: TOPIC_STATUS.NO_RECORD,
            conceptType: CONCEPT_TYPE.NONE,
            ...removeUndefined(params)
        }
    },
    LL_CATALOG: (params?: RecursivePartial<LLCatalog>): LLCatalog => {
        return {
            courses: [],
            units: [],
            topics: [],
            concepts: [],
            objectives: [],
            ...removeUndefined(params)
        }
    },
    V3_MASTERY_STATUS_CHANGE_EVENT: (params?: RecursivePartial<V3MasteryStatusChangeEvent>): V3MasteryStatusChangeEvent => {
        return {
            id: "",
            studentUUID: "",
            studentName: "",
            studentFirstName: "",
            studentLastName: "",
            studentCohort: 0,
            schoolUUID: "",
            schoolName: "",
            districtUUID: "",
            districtName: "",
            groupUUIDs: [],
            groupUUID: "",
            groupNames: [],
            gradeId: "",
            gradeName: "",
            segmentIds: [],
            segmentNames: [],
            teacherUUIDs: [],
            teacherNames: [],
            dateMs: 0,
            skill: LEARNING_MODULE_SKILL.READING,
            productId: "",
            productName: "",
            masteryStatus: V3_MASTERY_STATUS.NO_DATA,
            catalogLevel: CATALOG_LEVEL.OBJECTIVE,
            objectiveUUID: "",
            objectiveName: "",
            conceptUUID: "",
            conceptName: "",
            conceptType: CONCEPT_TYPE.NONE,
            topicUUID: "",
            topicName: "",
            unitUUID: "",
            unitName: "",
            courseUUID: "",
            courseName: "",
            sourceId: "",
            sourceName: "",
            v3PromptId: "",
            v3TakeId: "",
            takePassed: false,
            takeScore: 0,
            takeTotalPossibleScore: 0,
            v3TestId: "",
            v3TestName: "",
            highestLevelMasteredConceptUUID: "",
            highestLevelMasteredConceptName: "",
            instructionalLevelConceptUUID: "",
            instructionalLevelConceptName: "",
            highestLevelMasteredTopicUUID: "",
            highestLevelMasteredTopicName: "",
            instructionalLevelTopicUUID: "",
            instructionalLevelTopicName: "",
            isInstructionalLevelEvent: false,
            ...removeUndefined(params)
        }
    }
}
