import { toNumber } from "lodash"
import { BaseRecord } from "../types"

export function baseRecordId<T extends BaseRecord>(item: T) {
    return item.id
}

export function baseRecordIdAsNumber<T extends BaseRecord>(item: T) {
    return toNumber(item.id)
}
