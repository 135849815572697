import { GAME_INSTANCE_TRACKED_STAT } from "./appTuningTypes"
import { Card, LANGUAGE, PROF_LEVEL } from "./baseTypes"
import { Deck, RelationshipDeck } from "./deckTypes"

export type GAME_TYPE = "Parrot" | "Scenario"

export enum GAME_VARIANT {
    NONE = "NONE",
    PARROT_PAIRS = "PARROT_PAIRS"
}

export interface PlayerStats {
    type: GAME_INSTANCE_TRACKED_STAT
    value: number
    total: number
}

export interface PlayerState {
    playerId: string
    cards: Card[]
    points: number
    ready: boolean
}

export interface BaseGameTurn {
    number: number
    phase: string
    activePlayers: string[]
    dateReady: string
}

export interface BaseGameInstanceState {
    playerIds: string[]
    playerStates: {
        [playerId: string]: PlayerState
    }
    turn: BaseGameTurn
    finished: boolean
    winnerIds: string[]
    loserIds: string[]
}

export interface BaseGameInstanceParams {
    id: string
    multiplayer: boolean
    variant: GAME_VARIANT
    playerIds: string[]
    playerSessionIds: string[]
    language: LANGUAGE
    withHelpAudioForCards: boolean
    turnLimit: number
    dateCreated: string
    deck: Deck | RelationshipDeck
    sceneId: string
    friendlyName: string
    profLevel: PROF_LEVEL
    studentGroupId: string | null
    segmentId: string | null
    cardsPerTurn: number
}

export interface BaseGameInstance {
    id: string
    name: GAME_TYPE
    state: BaseGameInstanceState
    params: BaseGameInstanceParams
}

export const MAX_PEOPLE_SESSION_EVENT_DURATION_MS = 1000 * 60 * 30 // 30 minutes
