import { BaseRecord, BaseRecordWithMeta } from "./baseTypes"
import { Book, Objective } from "./learningTypes"
import { CatalogTopic, Student } from "./studentTypes"

export interface V3Test extends BaseRecord {
    isActive: boolean
    externalResourcePdfUrl: string
    externalResourceAudioUrl: string
    isAssessment: boolean
    isAID: boolean
    versionSortOrder: number
    versionDescription: string
    instructions: string
    instructionsAlternate: string
    instructionsAudio: string
    createdAtMs: number
    updatedAtMs: number
    slug: string
    passingScore: number
    approachingMasteryScore: number
    conceptId: string
    createdById: string
    sourceId: string
    updatedById: string
    prompts: V3Prompt[]
    name: string
    runningRecord: string
    isPractice: boolean
}

export enum USAGE_ASSIGNED_TEST_STATUS {
    ASSIGNED = "a",
    PASSED = "p",
    SUBMITTED = "s",
    FAILED = "f"
}

export enum TAKE_RESPONSE_TYPE {
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    HANDWRITTEN = "HANDWRITTEN",
    ELECTRONIC = "ELECTRONIC",
    READING = "READING",
    FLUENCY = "FLUENCY",
    PHONICS = "PHONICS"
}

// THIS ENUM is special because it is legacy. It doesn't follow our convention of all CAPS
export enum ASSIGNMENT_TAKE_STATUS {
    ASSIGNED = "assigned", // teacher assigned
    IN_PROGRESS = "in-progress", // student opened or added text/content
    SUBMITTED = "submitted", // ready for teacher to put into DONE or IN_PROGRESS
    DONE = "done", // teacher done grading
    ERROR = "error" // something went wrong (currently the only case is that the mastery transaction failed)
}

export enum OBJECTIVE_MASTERY_STATUS {
    SKIP_GRADING = "SKIP_GRADING",
    MASTERED = "MASTERED",
    NOT_MASTERED = "NOT_MASTERED"
}

export interface ObjectiveGrade extends BaseRecord {
    objectiveId: string
    quizId: string
    grade: OBJECTIVE_MASTERY_STATUS | null
    score?: number // only used for fluency. And literally only then just to save and submit later. has no bearing on anything else
    // comment: string <-- replacing this with the comment system
}

export enum PROMPT_TYPE {
    WRITING = "WRITING",
    READING = "READING",
    READING_QUIZ_QUESTION = "READING_QUIZ_QUESTION",
    FLUENCY = "FLUENCY",
    PHONICS = "PHONICS"
}

export interface PromptObjective {
    id: string
    promptId: string
    objectiveId: string
}

export interface V3Prompt extends BaseRecordWithMeta {
    name: string
    type: PROMPT_TYPE
    courseId: string
    topicId: string
    sourceId: string
    source?: Book
    topic?: CatalogTopic
    objectives: Objective[] // will be empty for now I think??? these get copied into assignmentTake
    prompt: string
    // promptPages?: number[]
    studentChecklistUrl: string // s3 url of student checklist
    isActive: boolean
    isCurated: boolean
    multipleChoiceOptions: null | (string | { image: string; text: string })[]
    // multipleChoiceOptionPages?: number[][]
    multipleChoiceCorrectAnswerKey: null | string
    quizInstructionAudioEn: string | null
    quizInstructionAudioEs: string | null
    quizInstructionAudioCn: string | null
    quizInstructionTextEn: string | null
    quizInstructionTextEs: string | null
    quizInstructionTextCn: string | null
    quizImageUrl: string | null
    questionImageUrl: string | null
    questionAudioEn: string | null
    questionAudioEs: string | null
    questionAudioCn: string | null
    quizId: string | null
    v3TestId: string | null
    isStatic: boolean
}

export type V3PromptFlat = Omit<V3Prompt, "source" | "objectives" | "topic"> & {
    topicId: string
    sourceId: string
}

export type V3TakePrompt = Omit<V3Prompt, "isCurated" | "source" | "courseId" | "metaData" | "isActive"> & {
    originalId: string
}

export interface ResponseMedia {
    contentType: string
    url: string
    dateCreatedMs: number
}

interface BaseResponse {
    type: TAKE_RESPONSE_TYPE
    xpAwarded?: number
    coinsAwarded?: number
}

export interface ElectronicResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.ELECTRONIC
    value: string
}
export interface WrittenResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.HANDWRITTEN
    value: ResponseMedia[]
}

export interface MultipleChoiceResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.MULTIPLE_CHOICE
    value: string
    score: number | undefined
    profileQuizRewardId: string | undefined
}

export interface ReadingResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.READING
    totalPages: number
    endPageCount: number
    pageViewCount: number
    resumeAt: number
    furthestProgress: number
    lastReadMs: number
    finished: boolean
}

export interface FluencyResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.FLUENCY
    audioUrl: string
}

export interface PhonicsResponse extends BaseResponse {
    type: TAKE_RESPONSE_TYPE.PHONICS
    responseTimeMs: number
    isCorrect: boolean
    chosen: {
        cardId: string
        answerKey: string
    }
}

export type TakeResponse = ElectronicResponse | WrittenResponse | MultipleChoiceResponse | ReadingResponse | FluencyResponse | PhonicsResponse

export interface V3Take extends BaseRecordWithMeta {
    prompts: V3TakePrompt[] //can store as JSON in DB
    sourceId: string | null
    courseIds: string[]
    objectiveGrades: ObjectiveGrade[] // andrew: do JSON for now in DB, maybe will have to create separate table (ask brett)
    // objectives: can store as JSON string in take table, or can create separate tables for relationships/foreign keys
    // JSON is faster, easier dev, relationship tables give us referential integrity, easier querying, etc.
    status: ASSIGNMENT_TAKE_STATUS
    student: Student // <-- the student this take is assigned to
    studentId: string
    responses: TakeResponse[]
    dateDueMs: number
    dateAssignedMs: number
    dateReleaseMs: number
    dateSubmittedMs: number
    enableBookAudio: boolean
    hasReading: boolean
    hasReadingQuiz: boolean
    hasSpeaking: boolean
    hasWriting: boolean
    hasPhonics: boolean
    hasFluency: boolean
    isAssessment: boolean
    passed: boolean
    score: number | undefined | null
    totalScore: number | undefined | null
    secondsTaken: number | undefined | null
    v3TestId: string | null
    aidId: string | null
    numSubmitRetries: number
    dateSubmitRetryAfterMs: number
    miniLessonIds: string[] | null
}

export interface V3TakeCoursesJoinTableRow {
    id: string
    takeId: string
    courseId: string
}

export interface TakeBlueprint extends BaseRecordWithMeta {
    source: Book // | | someVideo || someImage <-- todo
    quiz: V3Prompt | V3Prompt[] // BookQuiz | GameCreateParams <-- todo
    hasReading: boolean
    hasReadingQuiz: boolean
    hasSpeaking: boolean
    hasWriting: boolean
    hasPhonics: boolean
    hasFluency: boolean
}
export interface TakeBlueprintWithStudents extends TakeBlueprint {
    studentIds: string[]
    dateReleaseMs: number
    dateDueMs: number
}

interface AssignmentBlueprintBase extends BaseRecordWithMeta {
    name: string
}

// This wraps takeblueprint
export interface AssignmentBlueprint extends AssignmentBlueprintBase {
    takeBlueprints: TakeBlueprint[]
    enableBookAudio: boolean
}

export enum ASSIGNMENT_BLUEPRINT_WITH_STUDENTS_STATUS {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED"
}

export interface AssignmentBlueprintWithStudents extends BaseRecordWithMeta {
    assignmentBlueprintId: string // this is for historical purposes only. DO NOT MAKE THIS FOREIGN KEY
    takeBlueprintsWithStudents: TakeBlueprintWithStudents[]
    sources: Book[]
    dateDueMs: number
    dateAssignedMs: number
    dateReleaseMs: number
    responseType: TAKE_RESPONSE_TYPE | TAKE_RESPONSE_TYPE[]
    enableBookAudio: boolean
    enableFluency: boolean
    status: ASSIGNMENT_BLUEPRINT_WITH_STUDENTS_STATUS
    productId: string | null
}

// This is a join table
export interface AssignmentBlueprintWithStudentsSources {
    id: string
    assignmentBlueprintWithStudentsId: string
    sourceId: string
}

// we allow general comments on a take, so objectiveId and questionId can be null
export interface Comment extends BaseRecordWithMeta {
    writingAssignmentTakeId: string | null
    writingPromptId: string | null
    objectiveId: string | null
    threadId: string
    start: number
    end: number
    message: string
}

/* 
//Brett's prophecy at 8/6/2021. We will need this for auto recommend feature of assignmentBlueprintWithStudents wizard
export interface PropheticTakeRecord {
    studentId: string
}
*/
export const ASSIGNMENT_ACTIVE_STATUSES = [ASSIGNMENT_TAKE_STATUS.ASSIGNED, ASSIGNMENT_TAKE_STATUS.IN_PROGRESS, ASSIGNMENT_TAKE_STATUS.SUBMITTED]

export enum TAKE_GRADING_PANEL_TYPE {
    FLUENCY = "GRADING_FLUENCY",
    WRITING = "GRADING_WRITING"
}
