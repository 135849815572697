import { adaptV4Theme, createTheme } from "@mui/material"
import breakpoints from "../config/breakpoints"
import props from "../config/props"
import spacing from "../config/spacing"
import typography from "../config/typography"

export default createTheme(
    adaptV4Theme({
        typography,
        palette: {
            common: { black: "#000", white: "#fff" },
            // default is
            background: { paper: "#fff", default: "#B0D9FF" },
            primary: {
                light: "#61B3FF",
                main: "#006CD0",
                dark: "#00498C",
                contrastText: "#fff"
            },
            secondary: {
                light: "#B0D9FF",
                main: "#8EC9FF",
                dark: "#00365A",
                contrastText: "#010825"
            },
            error: {
                light: "#e57373",
                main: "#f44336",
                dark: "#d32f2f",
                contrastText: "#fff"
            },
            text: {
                primary: "rgba(1, 8, 37, 0.87)",
                secondary: "rgba(1, 8, 37, 0.54)",
                disabled: "rgba(1, 8, 37, 0.38)"
                // TODO WOLF/GREG/ANDREW. What is this now?
                // hint: "rgba(1, 8, 37, 0.45)"
            },
            success: {
                main: "#2A9034",
                light: "#7BD682"
            },
            info: {
                main: "#FFF500"
            }
        },
        breakpoints,
        spacing,
        overrides: {
            MuiFab: {
                root: {
                    boxShadow: "none",
                    // secondary.light
                    backgroundColor: "#B0D9FF",
                    "&:hover": {
                        backgroundColor: "#B0D9FF"
                    }
                },
                // TODO WOLF/GREG/ANDREW. What is this now?
                // label: {
                //     // primary.main
                //     color: "#006CD0"
                // },
                primary: {
                    background: "linear-gradient(180deg, #FFF500 0%, #FAE210 52.08%, #E79800 100%)",
                    width: 80,
                    height: 80,
                    "& .MuiFab-label, & .MuiSvgIcon-fontSizeLarge": {
                        color: "#00365A",
                        fontSize: "3rem"
                    }
                }
            },
            MuiButton: {
                root: {
                    fontSize: 18
                },
                contained: {
                    boxShadow: "inset -4px 4px 1px rgba(255, 255, 255, 0.3)",
                    minWidth: 112,
                    minHeight: 40,
                    padding: "8px 0",
                    borderRadius: 0
                },
                containedPrimary: {
                    background: "linear-gradient(180deg, #FFF500 0%, #FAE210 52.08%, #E79800 100%)"
                },
                containedSecondary: {
                    // background: palette.secondary.main
                    "&:hover": {
                        backgroundColor: "#8EC9FF"
                    }
                }
                // TODO WOLF/GREG/ANDREW. What is this now?
                // label: {
                //     color: "rgba(1, 8, 37, 0.87)"
                // }
            },
            MuiLinearProgress: {
                root: {
                    height: 16,
                    border: "2px solid #A3E3FF"
                },
                colorPrimary: {
                    backgroundColor: "#00365A"
                },
                barColorPrimary: {
                    backgroundColor: "#B640FF",
                    filter: "blur(1px)"
                }
            }
        },
        props
    })
)
