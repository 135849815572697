import { UserDefinedDateRange } from "./analyticsTypes"
import { BaseRecord } from "./baseTypes"
import { CourseWithUnits } from "./learningTypes"
import { CONCEPT_TYPE } from "./masteryTypes"
import { District, School } from "./studentTypes"

export enum PROGRESS_PROFILE_PROGRESS_TYPE {
    NO_DATA = "no-data",
    BELOW = "below",
    APPROACHING = "approaching",
    MEETS = "meet",
    EXCEEDS = "exceed",
    FAR_EXCEEDS = "far-exceed"
}

export interface V3ProgressProfile extends BaseRecord {
    name: string
    isActive: boolean
    conceptType: CONCEPT_TYPE
    assessmentWindowId: string
    schoolId: string
    districtId: string | null
    courseId: string
    labelMinimumNotMet: string
}

interface V3ProgressProfileTargetConfigObject {
    active: boolean
    minTopicId: string | null
    maxTopicId: string | null
}
export interface V3ProgressProfileTargetConfig extends BaseRecord {
    id: string
    name: string
    progressProfileId: string
    enableTargetSetting: boolean
    config: {
        below: V3ProgressProfileTargetConfigObject
        approaching: V3ProgressProfileTargetConfigObject
        meets: V3ProgressProfileTargetConfigObject
        exceeds: V3ProgressProfileTargetConfigObject
        farExceeds: V3ProgressProfileTargetConfigObject
    }
}

export interface V3ProgressProfileTargetConfigMembership extends BaseRecord {
    id: string
    progressProfileTargetConfigId: string
    groupId: string | null
    segmentId: string | null
}

export interface V3ProgressProfileTargetConfigDeep extends V3ProgressProfileTargetConfig {
    membership: V3ProgressProfileTargetConfigMembership[]
}
export interface V3ProgressProfileDeep extends V3ProgressProfile {
    configs: V3ProgressProfileTargetConfigDeep[]
    dateRange: UserDefinedDateRange
}

export interface V3ProgressProfileDeepFundamentalObjectStore extends V3ProgressProfileDeep {
    school?: School
    course?: CourseWithUnits
    district?: District
}
