import { LANGUAGE, LANGUAGE_CODE, LANGUAGE_ID } from "../../types/base/languages"

export const languageCodeToLanguage = (code: LANGUAGE_CODE) => {
    switch (code) {
        case LANGUAGE_CODE.CHINESE_SIMPLIFIED:
            return LANGUAGE.CHINESE
        case LANGUAGE_CODE.CHINESE_TRADITIONAL:
            return LANGUAGE.CHINESE
        case LANGUAGE_CODE.SPANISH:
            return LANGUAGE.SPANISH
        case LANGUAGE_CODE.ENGLISH:
            return LANGUAGE.ENGLISH
        default:
            return LANGUAGE.CHINESE
    }
}

export function languageIdToLanguageCode(id: string | number) {
    switch (id.toString()) {
        case LANGUAGE_ID[LANGUAGE_CODE.CHINESE_SIMPLIFIED]:
            return LANGUAGE_CODE.CHINESE_SIMPLIFIED
        case LANGUAGE_ID[LANGUAGE_CODE.CHINESE_TRADITIONAL]:
            return LANGUAGE_CODE.CHINESE_TRADITIONAL
        case LANGUAGE_ID[LANGUAGE_CODE.SPANISH]:
            return LANGUAGE_CODE.SPANISH
        case LANGUAGE_ID[LANGUAGE_CODE.ENGLISH]:
            return LANGUAGE_CODE.ENGLISH
        default:
            return LANGUAGE_CODE.CHINESE_SIMPLIFIED
    }
}

export const languageToLanguageCode = (language: LANGUAGE) => {
    if (language === LANGUAGE.ENGLISH) {
        return LANGUAGE_CODE.ENGLISH
    } else if (language === LANGUAGE.SPANISH) {
        return LANGUAGE_CODE.SPANISH
    } else {
        return LANGUAGE_CODE.CHINESE_SIMPLIFIED
    }
}
