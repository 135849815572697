import { BaseRecord, BaseRecordWithMeta, LANGUAGE_CODE } from "./baseTypes"
import { COURSE_TYPE, Objective } from "./learningTypes"

export enum V3_MASTERY_STATUS {
    NO_DATA = "NO_DATA",
    INCOMPLETE_DATA = "INCOMPLETE_DATA",
    PLACEMENT_TESTED_OUT = "PLACEMENT_TESTED_OUT",
    NOT_MASTERED = "NOT_MASTERED",
    MASTERED = "MASTERED"
}

export const V3_MASTERED_STATUSES = [V3_MASTERY_STATUS.MASTERED, V3_MASTERY_STATUS.PLACEMENT_TESTED_OUT]

export interface ObjectiveMastery extends BaseRecord {
    id: string
    profileId: string
    objectiveId: string
    rollingAverage: number
    highestRollingAverage: number
    masteryStatus: V3_MASTERY_STATUS
    previousResults: number[]
    totalQuestionsSubmitted: number
    dateUpdatedMs: number
    dateCreatedMs: number
    dateMasteredMs: number | null
    teacherEnforcedFocus: null | {
        id: string // uuid
        remaining: number // the number of questions the kid should submit. decrement each time they submit. Set to null when 0.
        dateStartedMs: number
    }
    placementMode: boolean
    stats: {
        durationMs: number
    }
    isAssessment: boolean // this is readonly
}

export const getObjectiveMasteryId = (objectiveId: string, profileId: string, isAssessment = true) =>
    `objective-mastery-${objectiveId}-${profileId}${isAssessment ? "" : "-practice"}`
export const getConceptMasteryId = (conceptId: string, profileId: string, isAssessment = true) =>
    `concept-mastery-${conceptId}-${profileId}${isAssessment ? "" : "-practice"}`
export const getTopicMasteryId = (topicId: string, profileId: string, isAssessment = true) =>
    `topic-mastery-${topicId}-${profileId}${isAssessment ? "" : "-practice"}`
export const getCourseMasteryId = (courseId: string, profileId: string, isAssessment = true) =>
    `course-mastery-${courseId}-${profileId}${isAssessment ? "" : "-practice"}`
export const getInstructionalLevelId = (profileId: string, courseId: string, conceptType: string, isAssessment = true) =>
    `instructional-level-${profileId}-${courseId}-${conceptType}${isAssessment ? "" : "-practice"}`

/* 
    postgres computed column in ConceptMastered:
    only do this when progress === 1
    objectiveId -> conceptId -> objectivesByStudent -> totalMastered/total -> conceptMastered.progress
*/
export interface ConceptMastery extends BaseRecord {
    profileId: string
    conceptId: string
    progress: number
    mastered: boolean
    dateUpdatedMs: number
    dateCreatedMs: number
    dateMasteredMs: number | null
}

export interface TopicMastery extends BaseRecord {
    profileId: string
    topicId: string
    progress: number
    mastered: boolean
    dateUpdatedMs: number
    dateCreatedMs: number
    dateMasteredMs: number | null
}

export interface CourseMastery extends BaseRecord {
    profileId: string
    courseId: string
    progress: number
    mastered: boolean
    dateUpdatedMs: number
    dateCreatedMs: number
    dateMasteredMs: number | null
}
/*
    teacher offset? teacher can place student into higher level but it doesn't affect mastery
    What type of teacher override? sliding scale or custom
*/
export function isInPhonicsPracticeMode(props: {
    rollingAverage: number
    numAnswersSubmitted: number
    minimumAnswersToLeavePracticeMode: number
    objective?: Objective
    mastery?: ObjectiveMastery
}) {
    const isInPlacementMode = props.objective?.usedInPlacement && (!props.mastery || props.mastery.placementMode)
    if (isInPlacementMode) {
        return false
    }

    let { minimumAnswersToLeavePracticeMode } = props
    if (!minimumAnswersToLeavePracticeMode) {
        minimumAnswersToLeavePracticeMode = Math.floor(20 * 0.4)
    }

    const avgBelowPracticeThreshold = props.rollingAverage < 0.4
    return props.numAnswersSubmitted < minimumAnswersToLeavePracticeMode || avgBelowPracticeThreshold
}

export enum AUTO_ASSESSMENT_STATUS {
    NO_DATA = "NO_DATA",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE"
}

export enum CONCEPT_TYPE {
    LANGUAGE_AND_VOCABULARY = "language-and-vocabulary",
    READING_COMPREHENSION = "reading-comprehension",
    FLUENCY = "fluency",
    WRITING_OPINION = "opinion",
    WRITING_INFORMATIVE = "informative",
    WRITING_NARRATIVE = "narrative",
    WRITING_READING_RESPONSE = "reading-response",
    PHONICS = "phonics",
    NONE = "none"
}

export const WRITING_CONCEPT_TYPES = [
    CONCEPT_TYPE.WRITING_OPINION,
    CONCEPT_TYPE.WRITING_INFORMATIVE,
    CONCEPT_TYPE.WRITING_NARRATIVE,
    CONCEPT_TYPE.WRITING_READING_RESPONSE
]

export const ConceptTypeToTitle = {
    [CONCEPT_TYPE.LANGUAGE_AND_VOCABULARY]: "Language and Vocabulary",
    [CONCEPT_TYPE.READING_COMPREHENSION]: "Reading Comprehension",
    [CONCEPT_TYPE.FLUENCY]: "Fluency",
    [CONCEPT_TYPE.WRITING_OPINION]: "Writing Opinion",
    [CONCEPT_TYPE.WRITING_INFORMATIVE]: "Writing Informative",
    [CONCEPT_TYPE.WRITING_NARRATIVE]: "Writing Narrative",
    [CONCEPT_TYPE.WRITING_READING_RESPONSE]: "Writing Reading Response",
    [CONCEPT_TYPE.PHONICS]: "Phonics",
    [CONCEPT_TYPE.NONE]: "None"
}

export enum AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_STATUS {
    PASS = "PASS",
    FAIL = "FAIL",
    NEEDS_GRADE = "NEEDS_GRADE"
}

export const AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_COMPLETED_STATUSES: AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_STATUS[] = [
    AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_STATUS.PASS,
    AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_STATUS.FAIL
]

export interface CourseAndConceptType {
    courseId: string
    courseName: string
    courseType: COURSE_TYPE
    languageCode: LANGUAGE_CODE
    conceptType: CONCEPT_TYPE
    conceptTypeTitle: string
    courseAndConceptName: string
    conceptIds: string[]
    option: { label: string; value: string }
}

export interface AutoAssessmentAssignedAssessment {
    assessmentId: string
    assignedId: string // v3take id
    conceptSlug: CONCEPT_TYPE
    conceptId: string
    topicId: string
    dateCreatedMs: number
    dateSubmittedMs: number | null
    aidId?: string
    status: null | AUTO_ASSESSMENT_ASSIGNED_ASSESSMENT_STATUS
    score: null | number
    totalScore: null | number
    profileId?: string
    chosenVersion: number
    handwritten?: boolean
}

export interface AutoAssessmentRun extends BaseRecordWithMeta {
    status: AUTO_ASSESSMENT_STATUS
    isActive: boolean
    conceptSlug: CONCEPT_TYPE
    courseId: string
    productId: string
    profileId: string
    assignedAssessments: AutoAssessmentAssignedAssessment[]
    walkerOverride: boolean
    devNote: string
    preferTestVersion: number | null
}

export enum ASSESSMENT_LEVEL {
    ASSESSMENT = "ASSESSMENT", // an assessment test
    OBJECTIVE = "OBJECTIVE" // testing single objectives without a defined test (like phonics)
}

export interface V3InstructionalLevel {
    id: string
    profileId: string
    courseId: string
    conceptType: CONCEPT_TYPE
    conceptId: string
    isAssessment: boolean
}

export interface V3InstructionalLevelExt extends V3InstructionalLevel {
    topicId: string
    topicName: string
    courseType: COURSE_TYPE
}

export interface MiniLessonStatus extends BaseRecord {
    profileId: string
    miniLessonId: string
    progress: number
    isWatched: boolean
    isWatchedTeacherEdition: boolean
}
