import { BaseRecord } from "./baseTypes"

export enum LANGUAGE_ISO_CODE {
    CHINESE = "CMN",
    ENGLISH = "EN",
    SPANISH = "ES"
}

export enum LANGUAGE_FONT_PREFERENCE {
    SIMPLIFIED = "SIMPLIFIED",
    TRADITIONAL = "TRADITIONAL",
    SPANISH = "SPANISH"
}

export interface TranslationLanguage extends BaseRecord {
    title: string
    isoCode: LANGUAGE_ISO_CODE
    fontPreference: LANGUAGE_FONT_PREFERENCE
}
