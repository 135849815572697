import { ComponentsProps } from "@mui/material/styles"

const props: ComponentsProps = {
    MuiFab: {
        size: "small"
    },
    MuiButton: {
        variant: "contained",
        color: "primary"
    },
    MuiLinearProgress: {
        variant: "determinate"
    }
}

export default props
