/* eslint-disable @typescript-eslint/no-namespace */
import { Literal, Static, Union } from "runtypes"
import { BaseRecord, LANGUAGE } from "./baseTypes"
import { PROMPT_TYPE } from "./testTypes"

/* diphthongs
a sound formed by the combination of two vowels in a single syllable, 
in which the sound begins as one vowel and moves toward another 
(as in coin, loud, and side ).
*/

/* phoeneme
any of the perceptually distinct units of sound in a specified language that 
distinguish one word from another, for example p, b, d, 
and t in the English words pad, pat, bad, and bat.
*/

/* 
open syllable - ends with a vowel: ja
close syllable - a vowel followed by a consonant: on
*/

/*
    a-vi-ón: airplane
    a-be-ja: bee
    ar-di-lla: squirrel
    a-zul: blue
    a-ni-llo: ring
    o-cé-a-no: ocean
*/

/*
    game: choose image that contains the same syllable
    prompt: a-vi-ón: airplane
    options: ----on, ------, ------
    
    ón ==> on

*/

/* 
    do we need to write a fn to make sure cards dont have the same sound?? [if not capital/lowercase]

*/

/*
    we don't want to present b and v as options at the same time because they're so similar - basically, we don't want to test kids on spelling
     (is there a small enough amount that we can have an exceptions array without totally wreking us?)
*/

/* content team inputs */

/* maps to lesson in spreadsheet */

/*
objective a
     questionType - sound/image/text[] - [sound]
     answerType - sound/image/text[] - [text]
         phonetics deck 1 - syllable first - [duck, chicken]
         phonetics deck 2 - syllable end
 objective b
     questionType - sound/image/text[] - [image, text]
     answerType - sound/image/text[] - [text]
         phonetics deck 3 - syllable first - [duck, chicken]
         phonetics deck 4 - syllable end
*/

export const THRESHOLD_PHONICS_PRACTICE_MODE = 0.4
export const THRESHOLD_PHONICS_MASTERING = 0.7

export enum PHONICS_CARD_PROPERTY_TYPE {
    TEXT = "TEXT",
    LETTER = "LETTER",
    LETTER_SOUND = "LETTER_SOUND",
    SYLLABLE = "SYLLABLE",
    SYLLABLE_SOUND = "SYLLABLE_SOUND",
    ONSET_RIME = "ONSET_RIME",
    ONSET_RIME_SOUND = "ONSET_RIME_SOUND",
    COMPOUND_WORD = "COMPOUND_WORD"
}

export interface PhonicsCard extends BaseRecord {
    name: string
    images: string[]
    imagesS3: string[]

    englishAudio: string
    chineseAudio: string
    spanishAudio: string

    englishAudioS3: string
    chineseAudioS3: string
    spanishAudioS3: string

    englishText: string
    chineseText: string
    spanishText: string

    numWords: number

    englishSyllables: string[]
    englishSyllablesSound: string[]
    chineseSyllables: string[]
    chineseSyllablesSound: string[]
    spanishSyllables: string[]
    spanishSyllablesSound: string[]

    englishLetters: string[]
    englishLettersSound: string[]
    spanishLetters: string[]
    spanishLettersSound: string[]
    chineseLetters: string[]
    chineseLettersSound: string[]

    englishOnsetRime: string[]
    englishOnsetRimeSound: string[]
    englishCompoundWords: string[]
    spanishOnsetRime: string[]
    spanishOnsetRimeSound: string[]
    spanishCompoundWords: string[]
    chineseOnsetRime: string[]
    chineseOnsetRimeSound: string[]
    chineseCompoundWords: string[]

    generatorInjectedInfo?: {
        chosenLetter: string
        forceUpperCase: boolean
        forceLowerCase: boolean
        tangledRandomBoolean: boolean // this boolean is always random but guaranteed to be opposite in q and a cards.
        chosenProperty?: {
            type: PHONICS_CARD_PROPERTY_TYPE
            value: string
            sound: string
        }
    }
}

export interface PhonicsDeck extends BaseRecord {
    name: string
    contentMappingId: string // <-- PhonicsContentToGameContentMapping
    shortId: string
    cards: PhonicsCard[] // <-- choose cards from here
    redHerrings: PhonicsCard[]
    redHerringsByCard: {
        [cardId: string]: PhonicsCard[]
    }
    dontRandomize: boolean
    constraints: string[]
    answerGroups: PhonicsCard[][]
}

/*
  topic: Vowel A
    objectives:
     - recognize and name all upper and lowercase letters
     - count, pronounce, combine and segment spoen words into syllables
     - associate sounds (phonemes) with common spellings (graphemes) for the five vowels
        
*/

/* a prompt
   should the correct answer be an array? where they need to put a bunch of cards in an order?

    1 - decoding
        - PHONICS_IMAGE_STARTING_SYLLABLE_WITH_RESPONSES_IMAGES: present image as prompt. answers are also images. instructions: "pick the image which starts with the same syllable as the top image"
        - PHONICS_IMAGE_STARTING_SYLLABLE_WITH_RESPONSES_TEXT: present image as prompt. answers are also images. instructions: 
        - PHONICS_IMAGE_SHARES_SYLLABLE: present image as prompt. answers are also images. 
        - PHONICS_LISTEN_CHOOSE_TEXT: present audio as prompt. answers are text. instructions: "listen and pick the matching letter (or word)"
        - PHONICS_IMAGE_
        - identify variants (uppercase vs lowercase)
        - match starting vowel
*/

/* 
The question generator should choose this based on how experienced the kid is with the content.
if very experienced, choose 1 to place in the array for promptType, answerType
if mildy experienced, choose 2 
3 otherwise
*/
export enum PHONICS_CARD_DISPLAY_TYPE {
    IMAGE = "IMAGE",
    IMAGE_COMPOUND_WORDS = "IMAGE_COMPOUND_WORDS",

    AUDIO = "AUDIO",
    AUDIO_SYLLABLES = "AUDIO_SYLLABLES",
    AUDIO_SYLLABLES_SOUND = "AUDIO_SYLLABLES_SOUND",
    AUDIO_ONSET_RIME_SOUND = "AUDIO_ONSET_RIME_SOUND",
    AUDIO_COMPOUND_WORDS = "AUDIO_COMPOUND_WORDS",

    AUDIO_AUTOPLAY = "AUDIO_AUTOPLAY",
    AUDIO_LETTER = "AUDIO_LETTER",
    AUDIO_LETTER_SOUND = "AUDIO_LETTER_SOUND",

    TEXT_LETTER = "TEXT_LETTER",
    TEXT_PHRASE = "TEXT_PHRASE",
    TEXT_SCRAMBLED = "TEXT_SCRAMBLED",
    TEXT_REORDERED = "TEXT_REORDERED",
    TEXT_SYLLABLES = "TEXT_SYLLABLES",
    TEXT_ONSET_RIME = "TEXT_ONSET_RIME",

    TEXT_BACKWARDS = "TEXT_BACKWARDS",
    TEXT_UPPERCASE = "TEXT_UPPERCASE",
    TEXT_LOWERCASE = "TEXT_LOWERCASE",

    TEXT_CAPITALIZE = "TEXT_CAPITALIZE",
    TEXT_CAPITALIZE_ALTERNATE_QA = "TEXT_CAPITALIZE_ALTERNATE_QA",

    SHOW_MATCH = "SHOW_MATCH"
}

/* 
    9/28/2022 -> PHONICS_PROMPT_CONTENT_TYPE is determined by objective. 
    one objective can only have one game type,
    a game type may be associated with many objectives
*/

export enum PHONICS_PROMPT_CONTENT_TYPE {
    // question: A, options: a, E, I, O, U, e, i, o, u
    // question: Ni. options: ni, si, se
    // one offs
    PHONICS_EXACT_MATCH = "PHONICS_EXACT_MATCH", // can't pick the same card display type for question/answer???
    PHONICS_ANSWER_GROUP = "PHONICS_ANSWER_GROUP",

    PHONICS_BEGINS_WITH_LETTER = `PHONICS_BEGINS_WITH_LETTER`, // "pick the image which starts with the same Letter as the top image"
    PHONICS_BEGINS_WITH_LETTER_SOUND = "PHONICS_BEGINS_WITH_LETTER_SOUND",
    PHONICS_BEGINS_WITH_SYLLABLE = "PHONICS_BEGINS_WITH_SYLLABLE", // "pick the image which starts with the same syllable as the top image"
    PHONICS_BEGINS_WITH_SYLLABLE_SOUND = "PHONICS_BEGINS_WITH_SYLLABLE_SOUND",
    PHONICS_BEGINS_WITH_ONSET_RIME = "PHONICS_BEGINS_WITH_ONSET_RIME",
    PHONICS_BEGINS_WITH_ONSET_RIME_SOUND = "PHONICS_BEGINS_WITH_ONSET_RIME_SOUND",

    PHONICS_CONTAINS_LETTER = "PHONICS_CONTAINS_LETTER", // "pick the image that shares a Letter with this word"
    PHONICS_CONTAINS_LETTER_SOUND = "PHONICS_CONTAINS_LETTER_SOUND",
    PHONICS_CONTAINS_SYLLABLE = "PHONICS_CONTAINS_SYLLABLE", // "pick the image that shares a syllable with this word"
    PHONICS_CONTAINS_SYLLABLE_SOUND = "PHONICS_CONTAINS_SYLLABLE_SOUND",
    PHONICS_CONTAINS_ONSET_RIME = "PHONICS_CONTAINS_ONSET_RIME",
    PHONICS_CONTAINS_ONSET_RIME_SOUND = "PHONICS_CONTAINS_ONSET_RIME_SOUND",

    PHONICS_CONTAINS_NOT_1ST_LETTER = "PHONICS_CONTAINS_NOT_1ST_LETTER", // "pick the image that shares a Letter with this word"
    PHONICS_CONTAINS_NOT_1ST_LETTER_SOUND = "PHONICS_CONTAINS_NOT_1ST_LETTER_SOUND",
    PHONICS_CONTAINS_NOT_1ST_SYLLABLE = "PHONICS_CONTAINS_NOT_1ST_SYLLABLE", // "pick the image that shares a syllable with this word"
    PHONICS_CONTAINS_NOT_1ST_SYLLABLE_SOUND = "PHONICS_CONTAINS_NOT_1ST_SYLLABLE_SOUND",
    PHONICS_CONTAINS_NOT_1ST_ONSET_RIME = "PHONICS_CONTAINS_NOT_1ST_ONSET_RIME",
    PHONICS_CONTAINS_NOT_1ST_ONSET_RIME_SOUND = "PHONICS_CONTAINS_NOT_1ST_ONSET_RIME_SOUND",

    PHONICS_CONTAINS_ANYWHERE_LETTER = "PHONICS_CONTAINS_ANYWHERE_LETTER",
    PHONICS_CONTAINS_ANYWHERE_LETTER_SOUND = "PHONICS_CONTAINS_ANYWHERE_LETTER_SOUND",
    PHONICS_CONTAINS_ANYWHERE_SYLLABLE = "PHONICS_CONTAINS_ANYWHERE_SYLLABLE",
    PHONICS_CONTAINS_ANYWHERE_SYLLABLE_SOUND = "PHONICS_CONTAINS_ANYWHERE_SYLLABLE_SOUND",
    PHONICS_CONTAINS_ANYWHERE_ONSET_RIME = "PHONICS_CONTAINS_ANYWHERE_ONSET_RIME",
    PHONICS_CONTAINS_ANYWHERE_ONSET_RIME_SOUND = "PHONICS_CONTAINS_ANYWHERE_ONSET_RIME_SOUND",

    PHONICS_ENDS_WITH_LETTER = "PHONICS_ENDS_WITH_LETTER", // "pick the image which starts with the same syllable as the top image"
    PHONICS_ENDS_WITH_LETTER_SOUND = "PHONICS_ENDS_WITH_LETTER_SOUND",
    PHONICS_ENDS_WITH_SYLLABLE = "PHONICS_ENDS_WITH_SYLLABLE", // "pick the image which starts with the same syllable as the top image"
    PHONICS_ENDS_WITH_SYLLABLE_SOUND = "PHONICS_ENDS_WITH_SYLLABLE_SOUND",
    PHONICS_ENDS_WITH_ONSET_RIME = "PHONICS_ENDS_WITH_ONSET_RIME",
    PHONICS_ENDS_WITH_ONSET_RIME_SOUND = "PHONICS_ENDS_WITH_ONSET_RIME_SOUND",

    PHONICS_BOOLEAN_ENDS_WITH_LETTER = "PHONICS_BOOLEAN_ENDS_WITH_LETTER",
    PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE = "PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE",
    PHONICS_BOOLEAN_ENDS_WITH_LETTER_SOUND = "PHONICS_BOOLEAN_ENDS_WITH_LETTER_SOUND",
    PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE_SOUND = "PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE_SOUND",
    PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME = "PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME",
    PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME_SOUND = "PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME_SOUND",

    PHONICS_SHARE_A_LETTER = "PHONICS_SHARE_A_LETTER",
    PHONICS_SHARE_A_LETTER_SOUND = "PHONICS_SHARE_A_LETTER_SOUND",
    PHONICS_SHARE_A_SYLLABLE = "PHONICS_SHARE_A_SYLLABLE",
    PHONICS_SHARE_A_SYLLABLE_SOUND = "PHONICS_SHARE_A_SYLLABLE_SOUND",
    PHONICS_SHARE_A_ONSET_RIME = "PHONICS_SHARE_A_ONSET_RIME",
    PHONICS_SHARE_A_ONSET_RIME_SOUND = "PHONICS_SHARE_A_ONSET_RIME_SOUND",

    PHONICS_SHARE_OR_NOT_LETTER = "PHONICS_SHARE_OR_NOT_LETTER",
    PHONICS_SHARE_OR_NOT_LETTER_SOUND = "PHONICS_SHARE_OR_NOT_LETTER_SOUND",
    PHONICS_SHARE_OR_NOT_SYLLABLE = "PHONICS_SHARE_OR_NOT_SYLLABLE",
    PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND = "PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND",
    PHONICS_SHARE_OR_NOT_ONSET_RIME = "PHONICS_SHARE_OR_NOT_ONSET_RIME",
    PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND = "PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND",
    PHONICS_SHARE_OR_NOT_COMPOUND_WORDS = "PHONICS_SHARE_OR_NOT_COMPOUND_WORDS",

    PHONICS_SHARE_MAX_CONTIGOUS_LETTER = "PHONICS_SHARE_MAX_CONTIGOUS_LETTER",
    PHONICS_SHARE_MAX_CONTIGOUS_LETTER_SOUND = "PHONICS_SHARE_MAX_CONTIGOUS_LETTER_SOUND",
    PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE = "PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE",
    PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE_SOUND = "PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE_SOUND",
    PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME = "PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME",
    PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME_SOUND = "PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME_SOUND",

    PHONICS_SHARE_A_LETTER_RELAXED_RH = "PHONICS_SHARE_A_LETTER_RELAXED_RH",
    PHONICS_SHARE_A_LETTER_SOUND_RELAXED_RH = "PHONICS_SHARE_A_LETTER_SOUND_RELAXED_RH",
    PHONICS_SHARE_A_SYLLABLE_RELAXED_RH = "PHONICS_SHARE_A_SYLLABLE_RELAXED_RH",
    PHONICS_SHARE_A_SYLLABLE_SOUND_RELAXED_RH = "PHONICS_SHARE_A_SYLLABLE_SOUND_RELAXED_RH",
    PHONICS_SHARE_A_ONSET_RIME_RELAXED_RH = "PHONICS_SHARE_A_ONSET_RIME_RELAXED_RH",
    PHONICS_SHARE_A_ONSET_RIME_SOUND_RELAXED_RH = "PHONICS_SHARE_A_ONSET_RIME_SOUND_RELAXED_RH",

    PHONICS_SHARE_OR_NOT_LETTER_RELAXED_RH = "PHONICS_SHARE_OR_NOT_LETTER_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_LETTER_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_LETTER_SOUND_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_SYLLABLE_RELAXED_RH = "PHONICS_SHARE_OR_NOT_SYLLABLE_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_ONSET_RIME_RELAXED_RH = "PHONICS_SHARE_OR_NOT_ONSET_RIME_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND_RELAXED_RH",

    PHONICS_SHARE_FIRST_LETTER_RELAXED_RH = "PHONICS_SHARE_FIRST_LETTER_RELAXED_RH",
    PHONICS_SHARE_FIRST_LETTER_SOUND_RELAXED_RH = "PHONICS_SHARE_FIRST_LETTER_SOUND_RELAXED_RH",
    PHONICS_SHARE_FIRST_SYLLABLE_RELAXED_RH = "PHONICS_SHARE_FIRST_SYLLABLE_RELAXED_RH",
    PHONICS_SHARE_FIRST_SYLLABLE_SOUND_RELAXED_RH = "PHONICS_SHARE_FIRST_SYLLABLE_SOUND_RELAXED_RH",
    PHONICS_SHARE_FIRST_ONSET_RIME_RELAXED_RH = "PHONICS_SHARE_FIRST_ONSET_RIME_RELAXED_RH",
    PHONICS_SHARE_FIRST_ONSET_RIME_SOUND_RELAXED_RH = "PHONICS_SHARE_FIRST_ONSET_RIME_SOUND_RELAXED_RH",

    PHONICS_SHARE_OR_NOT_FIRST_LETTER_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_LETTER_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_FIRST_LETTER_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_LETTER_SOUND_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_SOUND_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_RELAXED_RH",
    PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_SOUND_RELAXED_RH = "PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_SOUND_RELAXED_RH",

    PHONICS_SHARE_MAX_LETTER = "PHONICS_SHARE_MAX_LETTER",
    PHONICS_SHARE_MAX_LETTER_SOUND = "PHONICS_SHARE_MAX_LETTER_SOUND",
    PHONICS_SHARE_MAX_SYLLABLE = "PHONICS_SHARE_MAX_SYLLABLE",
    PHONICS_SHARE_MAX_SYLLABLE_SOUND = "PHONICS_SHARE_MAX_SYLLABLE_SOUND",
    PHONICS_SHARE_MAX_ONSET_RIME = "PHONICS_SHARE_MAX_ONSET_RIME",
    PHONICS_SHARE_MAX_ONSET_RIME_SOUND = "PHONICS_SHARE_MAX_ONSET_RIME_SOUND",

    PHONICS_COUNT_LETTERS = "PHONICS_COUNT_LETTERS",
    PHONICS_COUNT_SYLLABLES = "PHONICS_COUNT_SYLLABLES", // count the number of syllables in a word,
    PHONICS_COUNT_WORDS = "PHONICS_COUNT_WORDS", // count number of words in a phrase

    PHONICS_PICK_MISSING_LETTER_FIRST = "PHONICS_PICK_MISSING_LETTER_FIRST",
    PHONICS_PICK_MISSING_SYLLABLE_FIRST = "PHONICS_PICK_MISSING_SYLLABLE_FIRST",

    PHONICS_PICK_MISSING_LETTER_2ND = "PHONICS_PICK_MISSING_LETTER_2ND",
    PHONICS_PICK_MISSING_SYLLABLE_2ND = "PHONICS_PICK_MISSING_SYLLABLE_2ND",

    PHONICS_PICK_MISSING_LETTER_LAST = "PHONICS_PICK_MISSING_LETTER_LAST",
    PHONICS_PICK_MISSING_SYLLABLE_LAST = "PHONICS_PICK_MISSING_SYLLABLE_LAST",

    PHONICS_PICK_MISSING_LETTER_ANYWHERE = "PHONICS_PICK_MISSING_LETTER_ANYWHERE",
    PHONICS_PICK_MISSING_SYLLABLE_ANYWHERE = "PHONICS_PICK_MISSING_SYLLABLE_ANYWHERE",

    PHONICS_CHANGE_THE_FIRST_LETTER = "PHONICS_CHANGE_THE_FIRST_LETTER",
    PHONICS_CHANGE_THE_FIRST_SYLLABLE = "PHONICS_CHANGE_THE_FIRST_SYLLABLE",

    PHONICS_CHANGE_INNER_LETTER = "PHONICS_CHANGE_INNER_LETTER",
    PHONICS_CHANGE_INNER_SYLLABLE = "PHONICS_CHANGE_INNER_SYLLABLE",

    PHONICS_CHANGE_THE_LAST_LETTER = "PHONICS_CHANGE_THE_LAST_LETTER",
    PHONICS_CHANGE_THE_LAST_SYLLABLE = "PHONICS_CHANGE_THE_LAST_SYLLABLE"
}

// Created on the fly by the server
export interface PhonicsPrompt extends BaseRecord {
    // isEncoding: boolean <-- put back later
    type: PROMPT_TYPE.PHONICS
    language: LANGUAGE
    contentType: PHONICS_PROMPT_CONTENT_TYPE
    displayType: {
        question: PHONICS_CARD_DISPLAY_TYPE[]
        answer: PHONICS_CARD_DISPLAY_TYPE[]
    }
    practiceModeDisplayType: {
        question: PHONICS_CARD_DISPLAY_TYPE[]
        answer: PHONICS_CARD_DISPLAY_TYPE[]
    }
    promptDifficulty: number // multiplier derived from the difficulty of the herring 'distance' from the correct answer
    instructions: string // eg. "pick the image which starts with the same syllable as the top image"
    questionCard: PhonicsCard | PhonicsCard[] // ???
    questionCardIdx: number
    options: PhonicsCard[]
    correctAnswer: PhonicsCard[]
    objectiveId: string
}

// const cards: PhonicsCard[] = [
//     {
//         id: "",
//         images: [],
//         englishAudio: "s3://levellearning.com/spanish/mesa.mp3",
//         spanishAudio: "s3://levellearning.com/spanish/mesa.mp3",
//         chineseAudio: "s3://levellearning.com/spanish/mesa.mp3",
//         englishSyllables: ["me", "sa"]
//     },
//     {
//         id: "",
//         images: [],
//         englishAudio: "s3://levellearning.com/spanish/me.mp3",
//         spanishAudio: "s3://levellearning.com/spanish/me.mp3",
//         chineseAudio: "s3://levellearning.com/spanish/me.mp3",
//         englishSyllables: ["me"]
//     },
//     {
//         id: "",
//         images: [],
//         englishAudio: "s3://levellearning.com/spanish/sa.mp3",
//         spanishAudio: "s3://levellearning.com/spanish/sa.mp3",
//         chineseAudio: "s3://levellearning.com/spanish/sa.mp3",
//         englishSyllables: ["sa"]
//     }
// ].map(BASE_BLANK.PHONICS_CARD)

// const getCard = (text: string): PhonicsCard => {
//     return { ...cards[0], chineseText: text, englishText: text, spanishText: text }
// }

// const prompts: PhonicsPrompt[] = [
//     {
//         /*
//             1) pick a polysyllabic card: we picked lim-ón
//             2) for each syllable in card, get the syllable cards
//             3) populate options with syllable cards and herrings
//         */
//         id: "upper_lower_case",
//         type: PROMPT_TYPE.PHONICS,
//         language: LANGUAGE.SPANISH,
//         contentType: PHONICS_PROMPT_CONTENT_TYPE.PHONICS_MATCH_UPPER_LOWER_CASE,
//         displayType: {
//             // determined by the student's settings
//             question: [PHONICS_CARD_DISPLAY_TYPE.TEXT],
//             answer: [PHONICS_CARD_DISPLAY_TYPE.TEXT]
//         },
//         promptDifficulty: 0.5, // multiplier derived from the difficulty of the herring 'distance' from the correct answer
//         instructions: "choose the lower case version of the syllable",
//         questionCard: getCard("Ni"), // "Ni" card
//         options: [...mockGenerateRedHerrings(cards[0]), getCard("ni"), getCard("ne"), getCard("sa")], // "ni", "ne", "sa"
//         correctAnswer: [cards[1], cards[2]],
//         objectiveId: "123"
//     },
//     {
//         /*
//             1) pick a polysyllabic card: we picked lim-ón
//             2) for each syllable in card, get the syllable cards
//             3) populate options with syllable cards and herrings
//         */
//         id: "exact_match_array",
//         type: PROMPT_TYPE.PHONICS,
//         language: LANGUAGE.SPANISH,
//         contentType: PHONICS_PROMPT_CONTENT_TYPE.PHONICS_EXACT_MATCH,
//         displayType: {
//             // determined by the student's settings
//             question: [PHONICS_CARD_DISPLAY_TYPE.IMAGE],
//             answer: [PHONICS_CARD_DISPLAY_TYPE.TEXT]
//         },
//         promptDifficulty: 0.5, // multiplier derived from the difficulty of the herring 'distance' from the correct answer
//         instructions: "choose the cards in order that spell the word in the image",
//         questionCard: getCard("mesa"), // mesa card
//         options: [...mockGenerateRedHerrings(cards[0]), cards[1], cards[2]],
//         correctAnswer: [cards[1], cards[2]],
//         objectiveId: "123"
//     },
//     {
//         /*
//             1) pick a card: mesa
//             2) choose question type, answer type. they cannot be the same
//             3) generate some red herrings & populate options
//         */
//         id: "exact_match_1",
//         type: PROMPT_TYPE.PHONICS,
//         language: LANGUAGE.SPANISH,
//         contentType: PHONICS_PROMPT_CONTENT_TYPE.PHONICS_EXACT_MATCH,
//         displayType: {
//             question: [PHONICS_CARD_DISPLAY_TYPE.TEXT],
//             answer: [PHONICS_CARD_DISPLAY_TYPE.IMAGE]
//         },
//         promptDifficulty: 0.5, // multiplier derived from the difficulty of the herring 'distance' from the correct answer
//         instructions: "choose the image that corresponds to the word",
//         questionCard: getCard("mesa"), // mesa card
//         options: [...mockGenerateRedHerrings(cards[0]), cards[0]],
//         correctAnswer: [cards[0]],
//         objectiveId: "123"
//     },
//     {
//         /*
//             1) based on objective, get the letter card
//             2) pick upper/lower as the question and the opposite as the answer
//             3) get other single letter cards to fill in as red herrings
//         */
//         id: "single_letter",
//         type: PROMPT_TYPE.PHONICS,
//         language: LANGUAGE.SPANISH,
//         contentType: PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SINGLE_LETTER,
//         displayType: {
//             question: [PHONICS_CARD_DISPLAY_TYPE.TEXT],
//             answer: [PHONICS_CARD_DISPLAY_TYPE.TEXT]
//         },
//         promptDifficulty: 0.5, // multiplier derived from the difficulty of the herring 'distance' from the correct answer
//         instructions: "choose matching (lowercase|uppercase) letter",
//         questionCard: getCard("A"), // mesa card
//         options: [getCard("a"), ...mockGenerateRedHerrings(getCard("a"))],
//         correctAnswer: [getCard("a")],
//         objectiveId: "123"
//     },
//     {
//         /*
//             1) query for card containing vowel
//             2) choose a card with the vowel appearing in the middle (not start, or end)
//             3) choose other random cards as red herrings
//         */
//         id: "contains_vowel",
//         type: PROMPT_TYPE.PHONICS,
//         language: LANGUAGE.SPANISH,
//         contentType: PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_VOWEL,
//         displayType: {
//             question: [PHONICS_CARD_DISPLAY_TYPE.TEXT],
//             answer: [PHONICS_CARD_DISPLAY_TYPE.TEXT]
//         },
//         promptDifficulty: 0.5, // multiplier derived from the difficulty of the herring 'distance' from the correct answer
//         instructions: "choose the card that contains 'es'",
//         questionCard: getCard("es"),
//         options: [getCard("amesa"), ...mockGenerateRedHerrings(getCard("amesa"))],
//         correctAnswer: [getCard("amesa")],
//         objectiveId: "123"
//     }
// ]

// Teacher weighted objectives are always *handwave* 66.6667% 😈 *handwave* of the cards selected
// i.e. if they have 1 objective, its 60% (9) of the cards, if they have 2 each objective is 30% of the deck
// so the more objectives picked the more wild it is... like not a focus?

// Bottom 30% is review material, weighted towards ones that are closer to being mastered and their multipliers
//      are larger to help them move towards mastery
// Mid 66.6667% 😈 is the current content they are working on
// Top 10% Challenge comes in at a certain threshold (like .7 mastered), otherwise it pulls from mid

// current content = anything that is purple (not 1)

// fresh student with no objectives mastered so far:

// Phase 2: Phonics word / content pack => like at 0.5 they get pack A and 0.7 pack B etc (to allow for vocab growth)

export interface PhonicsWorldParticle {
    image: string
    title: "CherryBlossom" | "Bubble"
    gravity: number
    size: number
    speed: number
    direction: "up" | "down" | "left" | "right"
    lifeTimeMs: number
    maxCount: number
}

export interface PhonicsWorldParticleCTX extends Omit<PhonicsWorldParticle, "image"> {
    image: CanvasImageSource
}

export interface PhonicsWorld extends BaseRecord {
    name: string
    active: boolean
    titlescreen: string
    background: {
        images: string[]
        speed: number
    }
    midground: {
        images: string[]
        speed: number
    }
    foreground: {
        images: string[]
        speed: number
    }
    particles: PhonicsWorldParticle[]
}

export interface PhonicsContentToGameContentMapping {
    id: string
    name: string
    instructions: {
        [LANGUAGE.ENGLISH]: string
        [LANGUAGE.SPANISH]: string
        [LANGUAGE.CHINESE]: string
    }
    gameContentType: PHONICS_PROMPT_CONTENT_TYPE
    displayType: {
        question: PHONICS_CARD_DISPLAY_TYPE[]
        answer: PHONICS_CARD_DISPLAY_TYPE[]
    }
    practiceModeDisplayType: {
        question: PHONICS_CARD_DISPLAY_TYPE[]
        answer: PHONICS_CARD_DISPLAY_TYPE[]
    }
    defaultNumAnswers: number
    similarContentTypeIds: string[]
    doNotServePreviousDecksWithThis: boolean
    preferQnASameCard: boolean
    forceOneQuestionOnly: boolean
}

/* 
    on response we need amount of time it took from question reveal => answer
*/

/*
    content lens:
     - query which objectives have been mastered for student
     - 
*/

export const TEACHER_ENFORCED_FOCUS_NUM_QUESTIONS = 30
export const TEACHER_ENFORCED_FOCUS_NUM_QUESTIONS_PRACTICE = 7

const beginsWith = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BEGINS_WITH_ONSET_RIME_SOUND)
)

const contains = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ONSET_RIME_SOUND)
)

const containsNot1st = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_NOT_1ST_ONSET_RIME_SOUND)
)

const containsAnywhere = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CONTAINS_ANYWHERE_ONSET_RIME_SOUND)
)

const endsWith = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_ENDS_WITH_ONSET_RIME_SOUND)
)

const booleanEndsWith = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_BOOLEAN_ENDS_WITH_ONSET_RIME_SOUND)
)

const share = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_ONSET_RIME_SOUND)
)

const shareOrNot = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_COMPOUND_WORDS)
)

const shareRelaxedRh = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_LETTER_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_LETTER_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_SYLLABLE_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_SYLLABLE_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_ONSET_RIME_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_A_ONSET_RIME_SOUND_RELAXED_RH)
)

const shareOrNotRelaxedRh = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_LETTER_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_LETTER_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_SYLLABLE_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_SYLLABLE_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_ONSET_RIME_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_ONSET_RIME_SOUND_RELAXED_RH)
)

const shareFirstRelaxedRh = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_LETTER_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_LETTER_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_SYLLABLE_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_SYLLABLE_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_ONSET_RIME_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_FIRST_ONSET_RIME_SOUND_RELAXED_RH)
)

const shareFirstOrNotRelaxedRh = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_LETTER_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_LETTER_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_SYLLABLE_SOUND_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_RELAXED_RH),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_OR_NOT_FIRST_ONSET_RIME_SOUND_RELAXED_RH)
)

const shareMaxContigous = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_CONTIGOUS_ONSET_RIME_SOUND)
)

const shareMax = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_LETTER_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_SYLLABLE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_SYLLABLE_SOUND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_ONSET_RIME),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_SHARE_MAX_ONSET_RIME_SOUND)
)

const count = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_COUNT_LETTERS),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_COUNT_WORDS),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_COUNT_SYLLABLES)
)

const pickMissingFirst = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_LETTER_FIRST),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_SYLLABLE_FIRST)
)

const pickMissing2nd = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_LETTER_2ND),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_SYLLABLE_2ND)
)

const pickMissingLast = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_LETTER_LAST),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_SYLLABLE_LAST)
)

const pickMissingAnywhere = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_LETTER_ANYWHERE),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_PICK_MISSING_SYLLABLE_ANYWHERE)
)

const changeTheFirst = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_THE_FIRST_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_THE_FIRST_SYLLABLE)
)

const changeInner = Union(Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_INNER_LETTER), Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_INNER_SYLLABLE))

const changeLast = Union(
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_THE_LAST_LETTER),
    Literal(PHONICS_PROMPT_CONTENT_TYPE.PHONICS_CHANGE_THE_LAST_SYLLABLE)
)

export const PhonicsPromptContentRunTypes = {
    beginsWith,
    contains,
    containsNot1st,
    containsAnywhere,
    endsWith,
    booleanEndsWith,
    share,
    shareOrNot,
    shareRelaxedRh,
    shareOrNotRelaxedRh,
    shareMaxContigous,
    shareMax,
    shareFirstRelaxedRh,
    shareFirstOrNotRelaxedRh,
    count,
    pickMissingFirst,
    pickMissing2nd,
    pickMissingLast,
    pickMissingAnywhere,
    changeTheFirst,
    changeInner,
    changeLast
}

export namespace PhonicsPromptContentTypes {
    export type beginsWith = Static<typeof beginsWith>
    export type contains = Static<typeof contains>
    export type containsNot1st = Static<typeof containsNot1st>
    export type containsAnywhere = Static<typeof containsAnywhere>
    export type endsWith = Static<typeof endsWith>
    export type booleanEndsWith = Static<typeof booleanEndsWith>
    export type share = Static<typeof share>
    export type shareOrNot = Static<typeof shareOrNot>
    export type shareRelaxedRh = Static<typeof shareRelaxedRh>
    export type shareOrNotRelaxedRh = Static<typeof shareOrNotRelaxedRh>
    export type shareMaxContigous = Static<typeof shareMaxContigous>
    export type shareMax = Static<typeof shareMax>
    export type shareFirstRelaxedRh = Static<typeof shareFirstRelaxedRh>
    export type shareFirstOrNotRelaxedRh = Static<typeof shareFirstOrNotRelaxedRh>
    export type count = Static<typeof count>
    export type pickMissingFirst = Static<typeof pickMissingFirst>
    export type pickMissing2nd = Static<typeof pickMissing2nd>
    export type pickMissingLast = Static<typeof pickMissingLast>
    export type pickMissingAnywhere = Static<typeof pickMissingAnywhere>
    export type changeTheFirst = Static<typeof changeTheFirst>
    export type changeInner = Static<typeof changeInner>
    export type changeLast = Static<typeof changeLast>
}
export const PHONICS_DEFAULT_RESPONSE_TIME_MS = 1000 * 30 // 30 seconds
