import {
    AppTuning,
    AppTuningLevelInfo,
    AppTuningParrotMode,
    AppTuningQuest,
    AppTuningReading,
    AppTuningWriting,
    GAME_INSTANCE_TRACKED_STAT
} from "./types/appTuningTypes"

const level: AppTuningLevelInfo[] = [
    { xp: 0, bonusCoins: 50 },
    { xp: 50, bonusCoins: 50 },
    { xp: 125, bonusCoins: 50 },
    { xp: 200, bonusCoins: 50 },
    { xp: 275, bonusCoins: 50 },
    { xp: 350, bonusCoins: 75 },
    { xp: 425, bonusCoins: 75 },
    { xp: 525, bonusCoins: 75 },
    { xp: 625, bonusCoins: 75 },
    { xp: 725, bonusCoins: 75 },
    { xp: 825, bonusCoins: 75 },
    { xp: 925, bonusCoins: 100 },
    { xp: 1075, bonusCoins: 100 },
    { xp: 1225, bonusCoins: 100 },
    { xp: 1375, bonusCoins: 100 },
    { xp: 1575, bonusCoins: 100 },
    { xp: 1775, bonusCoins: 100 },
    { xp: 1975, bonusCoins: 250 },
    { xp: 2175, bonusCoins: 250 },
    { xp: 2425, bonusCoins: 250 },
    { xp: 2675, bonusCoins: 250 },
    { xp: 2925, bonusCoins: 250 },
    { xp: 3275, bonusCoins: 250 },
    { xp: 3625, bonusCoins: 250 },
    { xp: 3975, bonusCoins: 250 },
    { xp: 4325, bonusCoins: 250 },
    { xp: 4825, bonusCoins: 500 },
    { xp: 5325, bonusCoins: 500 },
    { xp: 5825, bonusCoins: 500 },
    { xp: 6325, bonusCoins: 500 },
    { xp: 6825, bonusCoins: 500 },
    { xp: 7325, bonusCoins: 500 },
    { xp: 7825, bonusCoins: 500 },
    { xp: 8325, bonusCoins: 500 },
    { xp: 8825, bonusCoins: 500 },
    { xp: 9325, bonusCoins: 500 },
    { xp: 9825, bonusCoins: 500 },
    { xp: 10325, bonusCoins: 500 },
    { xp: 10825, bonusCoins: 500 },
    { xp: 11325, bonusCoins: 500 },
    { xp: 11825, bonusCoins: 500 },
    { xp: 12325, bonusCoins: 500 },
    { xp: 13325, bonusCoins: 500 },
    { xp: 13825, bonusCoins: 750 },
    { xp: 14325, bonusCoins: 750 },
    { xp: 14825, bonusCoins: 750 },
    { xp: 15325, bonusCoins: 750 },
    { xp: 15825, bonusCoins: 750 },
    { xp: 16325, bonusCoins: 750 },
    { xp: 16825, bonusCoins: 750 },
    { xp: 17825, bonusCoins: 750 },
    { xp: 18825, bonusCoins: 750 },
    { xp: 19825, bonusCoins: 750 },
    { xp: 20825, bonusCoins: 750 },
    { xp: 21825, bonusCoins: 750 },
    { xp: 22825, bonusCoins: 750 },
    { xp: 23825, bonusCoins: 750 },
    { xp: 24825, bonusCoins: 750 },
    { xp: 25825, bonusCoins: 1000 },
    { xp: 26825, bonusCoins: 1000 },
    { xp: 27825, bonusCoins: 1000 },
    { xp: 28825, bonusCoins: 1000 },
    { xp: 29825, bonusCoins: 1000 },
    { xp: 30825, bonusCoins: 1000 },
    { xp: 31825, bonusCoins: 1000 },
    { xp: 32825, bonusCoins: 1000 },
    { xp: 33825, bonusCoins: 1000 },
    { xp: 34825, bonusCoins: 1000 },
    { xp: 35825, bonusCoins: 1000 },
    { xp: 36825, bonusCoins: 1000 },
    { xp: 39325, bonusCoins: 1000 },
    { xp: 41825, bonusCoins: 1000 },
    { xp: 44325, bonusCoins: 1000 },
    { xp: 46825, bonusCoins: 1000 },
    { xp: 49325, bonusCoins: 1000 },
    { xp: 51825, bonusCoins: 1000 },
    { xp: 54325, bonusCoins: 1000 },
    { xp: 56825, bonusCoins: 1000 },
    { xp: 59325, bonusCoins: 1000 },
    { xp: 61825, bonusCoins: 1000 },
    { xp: 68825, bonusCoins: 1000 },
    { xp: 71825, bonusCoins: 1000 },
    { xp: 76825, bonusCoins: 1000 },
    { xp: 81825, bonusCoins: 1000 },
    { xp: 86825, bonusCoins: 1000 },
    { xp: 91825, bonusCoins: 1000 },
    { xp: 96825, bonusCoins: 1000 },
    { xp: 101825, bonusCoins: 2500 },
    { xp: 106825, bonusCoins: 2500 },
    { xp: 116825, bonusCoins: 2500 },
    { xp: 126825, bonusCoins: 2500 },
    { xp: 141825, bonusCoins: 2500 },
    { xp: 156825, bonusCoins: 2500 },
    { xp: 176825, bonusCoins: 2500 },
    { xp: 196825, bonusCoins: 2500 },
    { xp: 196825, bonusCoins: 2500 },
    { xp: 216825, bonusCoins: 2500 },
    { xp: 266825, bonusCoins: 2500 },
    { xp: 316825, bonusCoins: 2500 },
    { xp: 366825, bonusCoins: 2500 }
]

const parrotMode: AppTuningParrotMode = {
    secondsPerTurn: 60,
    duringGame: {
        correctListening: {
            pts: "x * 0.25",
            xp: "x * 0",
            coins: "x * 0",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_LISTENING
        },
        correctListeningTime: {
            pts: "x * 0.25",
            xp: "x * 0.25",
            coins: "x * 0",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_SECONDS_REMAINING
        },
        correctSpeaking: {
            pts: "x * 10",
            xp: "x * 10",
            coins: "x * 0",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_SPEAKING
        }
    },
    endOfGameBonuses: {
        correctListening: {
            pts: "x * 5",
            xp: "x * 5",
            coins: "x * 1",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_LISTENING,
            chainedPercentageBonus: {
                pts: "(v * 0.2) * x^2",
                xp: "(v * 0.2) * x^2",
                coins: "(v * 0.02) * x^2",
                trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_LISTENING
            }
        },
        correctListeningTime: {
            pts: "x * 0.25",
            xp: "x * 0.25",
            coins: "x * 0.025",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_SECONDS_REMAINING,
            chainedPercentageBonus: {
                pts: "(v * 0.2) * x^2",
                xp: "(v * 0.2) * x^2",
                coins: "(v * 0.02) * x^2",
                trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_SECONDS_REMAINING
            }
        },
        correctSpeaking: {
            pts: "x * 5",
            xp: "x * 5",
            coins: "x * 1",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_SPEAKING,
            chainedPercentageBonus: {
                pts: "(v * 0.2) * x^2",
                xp: "(v * 0.2) * x^2",
                coins: "(v * 0.02) * x^2",
                trackedStat: GAME_INSTANCE_TRACKED_STAT.PARROT_CORRECT_SPEAKING
            }
        }
    }
}

const quest: AppTuningQuest = {
    secondsPerGoal: 300,
    duringGame: {
        correct: {
            pts: "x * 15",
            xp: "x * 15",
            coins: "x * 0",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.GOAL_CORRECT
        }
    },
    endOfGameBonuses: {
        correct: {
            pts: "x * 10",
            xp: "x * 10",
            coins: "x * 10",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.GOAL_CORRECT,
            chainedPercentageBonus: {
                pts: "(v * 0.2) * x^10",
                xp: "(v * 0.2) * x^10",
                coins: "(v * 0.2) * x^10",
                trackedStat: GAME_INSTANCE_TRACKED_STAT.GOAL_CORRECT
            }
        },
        time: {
            pts: "x * 0.25",
            xp: "x * 0.05",
            coins: "x * 0.025",
            trackedStat: GAME_INSTANCE_TRACKED_STAT.GOAL_SECONDS_REMAINING,
            chainedPercentageBonus: {
                pts: "(v * 0.2) * x^10",
                xp: "(v * 0.2) * x^10",
                coins: "(v * 0.2) * x^10",
                trackedStat: GAME_INSTANCE_TRACKED_STAT.GOAL_SECONDS_REMAINING
            }
        }
    }
}

const reading: AppTuningReading = {
    maxBooksPerWeek: 999,
    flat: {
        xp: 5,
        coin: 1
    },
    percentage: {
        coin: 10,
        xp: 50
    },
    fullCorrect: {
        coin: 25,
        xp: 100
    }
}

const writing: AppTuningWriting = {
    flat: {
        xp: 25,
        coins: 1
    },
    percentage: {
        coin: 10,
        xp: 50
    },
    fullCorrect: {
        coin: 25,
        xp: 100
    }
}

export const appTuningValue: AppTuning = {
    maxXpToMaxStar: "x * 7",
    percXpToStars: [0.15, 0.45],
    level,
    parrotMode,
    quest,
    reading,
    writing,
    phonics: {
        coins: { min: 7, max: 12 },
        xp: { min: 10, max: 30 }
    }
}
