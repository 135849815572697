import { clamp } from "lodash"
import { appTuningValue as tuning } from "./appTuning"

export class AppTuningSingleton {
    static Value = tuning

    static Level = {
        xpToLevel: (xp: number) => {
            const lastLevelIdx = tuning.level.length - 1
            const lastLevel = tuning.level[lastLevelIdx]
            if (xp > lastLevel.xp) {
                return lastLevelIdx
            }

            const idx = tuning.level.findIndex(l => l.xp > xp)
            return Math.max(idx - 1, 0)
        },
        xpToNextLevel: (xp: number) => {
            const currentLevel = AppTuningSingleton.Level.xpToLevel(xp)
            if (currentLevel === tuning.level.length - 1) {
                return 0
            }

            const idx = tuning.level.findIndex(l => l.xp > xp)
            if (idx === -1) {
                return Number.POSITIVE_INFINITY
            }
            return tuning.level[idx].xp - xp
        },
        progressToNextLevel: (xp: number) => {
            const currentLevel = AppTuningSingleton.Level.xpToLevel(xp)
            if (currentLevel === tuning.level.length - 1) {
                return 1
            }

            const thisLevelXp = tuning.level[currentLevel].xp
            const nextLevelXp = tuning.level[currentLevel + 1].xp

            const val = (xp - thisLevelXp) / (nextLevelXp - thisLevelXp)
            return clamp(val, 0, 1)
        },
        xpAboveCurrentLevel: (xp: number) => {
            const currentLevel = AppTuningSingleton.Level.xpToLevel(xp)
            const currentLevelInfo = tuning.level[currentLevel]
            return Math.max(xp - currentLevelInfo.xp, 0)
        },
        totalXpToNextLevel: (xp: number) => {
            const currentLevel = AppTuningSingleton.Level.xpToLevel(xp)
            const currentLevelInfo = tuning.level[currentLevel]
            const nextLevelInfo = tuning.level[currentLevel + 1]
            if (!nextLevelInfo) {
                return xp
            }

            return nextLevelInfo.xp - currentLevelInfo.xp
        },
        willLevelUp: (currentXp: number, additionalXp: number) => {
            const l1 = AppTuningSingleton.Level.xpToLevel(currentXp)
            const l2 = AppTuningSingleton.Level.xpToLevel(currentXp + additionalXp)
            return l1 !== l2
        }
    }

    static Stars = {
        getNumStars: (progress: number) => {
            if (progress <= 0) {
                return 0
            }
            if (progress >= 1) {
                return tuning.percXpToStars.length + 1
            }

            return tuning.percXpToStars.filter(val => progress >= val).length
        },
        starPositions: () => [...tuning.percXpToStars, 1],
        maxStars: () => tuning.percXpToStars.length + 1
    }
}
