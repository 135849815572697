export function getSizedS3MediaUrl(src: string, width?: string | number, height?: string | number) {
    let url = src
    if (url.startsWith("//")) {
        url = `https:${url}`
    }

    if (width && height) {
        // example
        // https://masterytrack-media-production.s3.us-west-2.amazonaws.com/game-assets/images/00041487-c3a3-42e0-8077-48bb71c21fdd-image/aa2d8fd961a6e13c71e11c0dd592005b/School-D-D3-3__128x128.png

        // see if valid extension at the end
        const arr = src.split(".")
        const beginning = arr.slice(0, -1).join(".")
        const ext = arr[arr.length - 1]

        // only request different sizes if it is one of these. svgs are innately resizable
        if ([`apng`, `png`, `avif`, `gif`, `jpg`, `jpeg`, `jfif`, `pjpeg`, `pjp`, `png`, `webp`].includes(ext)) {
            url = `${beginning}__${width}x${height}.${ext}`
        }
    }

    return url
}
