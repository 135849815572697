import permutation from "./arrPermutation"
import { uniq, toNumber } from "lodash"

export default function combination<T>(array: T[], n?: number): T[][] {
    const result = permutation(array, n)
    const indices = result.map(entries => {
        return entries
            .map(entry => array.indexOf(entry))
            .sort()
            .join("-")
    })

    return uniq(indices).map(str =>
        str
            .split("-")
            .map(toNumber)
            .map(idx => array[idx])
    )
}
