interface TimerParams {
    fn: () => any
    interval: number
}

const fn = (params: TimerParams): (() => void) => {
    let ended = false
    let timeoutId: NodeJS.Timeout | undefined = undefined

    const stopTimer = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        ended = true
    }

    const looper = () => {
        if (ended) {
            return
        }
        params.fn()
        timeoutId = setTimeout(looper, params.interval)
    }
    looper()

    return stopTimer
}

export default fn
