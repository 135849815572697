import { BaseRecord, LANGUAGE_CODE } from "./baseTypes"
import { CONCEPT_TYPE } from "./masteryTypes"
import { CatalogTopic } from "./studentTypes"

export interface Objective extends BaseRecord {
    name: string
    slug: string
    is_active: boolean
    shortIdentifier: string
    translatedTitle: string
    order: number
    conceptId: string
    masteryThreshold: number
    masteryThresholdPractice: number
    usedInPlacement: boolean

    createdAtMs: number
    updatedAtMs: number
    createdById: string | null
    updatedById: string | null
    uuid: string | null
    assignmentMode: string
}

export enum CATALOG_TAG_NAME {
    MASTERY_CONCEPT = "Mastery:Concept",
    MASTERY_EXCLUDE = "Mastery:Exclude",
    FLUENCY = "Fluency",
    Levels = "Levels"
}
export interface CatalogTag extends BaseRecord {
    isActive: boolean
    name: CATALOG_TAG_NAME
}

export interface CatalogMetadata extends BaseRecord {
    tagId: string
    catalogItemTypeId: string
    catalogItemId: string
}

export interface CourseConceptFlat extends BaseRecord {
    name: string
    slug: string
    is_active: boolean
    topicId: string
    masteryThreshold: number
    order: number
    uuid: string

    createdAtMs: number
    updatedAtMs: number
    createdById: string | null
    updatedById: string | null

    approachingMasteryThrehsold: number
    shortId: string
    assignmentMode: string
    type: CONCEPT_TYPE

    tags?: CATALOG_TAG_NAME[]
}

export interface CourseConcept extends CourseConceptFlat {
    objectives: Objective[]
}

export interface CourseTopicWithConcepts extends CatalogTopic {
    concepts: CourseConcept[]
}

export interface CourseUnitFlat extends BaseRecord {
    name: string
    slug: string
    is_active: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string | null
    updatedById: string | null
    uuid: string
    courseId: string
    order: number
    masteryThreshold: number
}
export interface CourseUnit extends CourseUnitFlat {
    topics: CourseTopicWithConcepts[]
}

export interface Subject extends BaseRecord {
    name: string
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    slug: string
    uuid: string
    isActive: boolean
    order: number
}

export interface Course extends BaseRecord {
    name: string
    slug: string
    is_active: boolean
    nothing_mastered_label: string
    masteryThreshold: number
    order: number

    createdAtMs: number
    updatedAtMs: number
    createdById: string | null
    updatedById: string | null
    uuid: string
    subjectId: string

    showLearningCenter: boolean
    isPremium: boolean
    assessmentTierId: string
    languageId: string
    courseAdminId: string

    orderedUnits: boolean
    orderedTopics: boolean
    orderedConcepts: boolean
    orderedObjectives: boolean
    sendToEdfi: boolean
}

export interface CourseWithUnits extends Course {
    units: CourseUnit[]
}

export interface LearningResourceFlat extends BaseRecord {
    isActive: boolean
    dateCreated: string
    dateUpdated: string
    slug: string
    title: string | null
    titleTranslation: string | null
    type: LEARNING_RESOURCE_TYPE
    description: string | null
    hasWritingPrompts: boolean
    hasReadingQuiz: boolean

    numPages: number | null
    startPage: number | null
    endPage: number | null
    startPageCount: number | null
    endPageCount: number | null
    countPerPdfPage: number | null

    productId: string
    topicId: string | null
    thumbnailId: string | null
    bookFileId: string | null
    docFileId: string | null
    publisherId: string | null
    bookSeriesId: string | null
    textTypeId: string | null
    multiLevelSetId: string | null
}

export interface LearningResource extends LearningResourceFlat {
    tagsIds: string[]
    thumbnailUrl: string
    topic: CatalogTopic
}

export enum LEARNING_RESOURCE_TYPE {
    BOOK = "book",
    ASSESSMENT_BOOK = "assessment-book"
}

export interface Book extends LearningResource {
    type: LEARNING_RESOURCE_TYPE.BOOK | LEARNING_RESOURCE_TYPE.ASSESSMENT_BOOK
}

export interface BookTopics {
    topic: string
    translation: string
    type: string
}
export interface BookObjectDeep extends Book {
    level: string
    types: string[]
    topics: BookTopics[]
    series: BookTopics
    publisher: string
    publisherTranslation: string
    quizId: string
    quizObjectiveIds: string[]
    quizObjectiveStaticIds: string[]
    audioPageIds: string[]
    writingPromptIds: string[]
}

export enum LEARNING_RESOURCE_TAG_TYPE {
    TOPIC = "topic",
    INCLUDES = "includes",
    AGE = "age",
    CCSS_SOCIAL_STUDIES = "ccss_ss",
    CCSS_SOCIAL_STUDIES_DESC = "CCSS Social Studies",
    CCSS_SCIENCE = "ccss_sci",
    CCSS_SCIENCE_DESC = "CCSS Science",
    PYP_STRANDS = "pyp_strands",
    PYP_STRANDS_DESC = "PYP Strands",
    SYLLABLE = "syllable",
    SYLLABLE_DESC = "Syllables",
    PHONEME = "phoneme",
    PHONEME_DESC = "Phonemes"
}

export enum LEARNING_RESOURCE_ATTRIBUTE_TYPE {
    ATTRIBUTE_TYPE_BOOK_SERIES = "book-series",
    ATTRIBUTE_TYPE_PUBLISHER = "publisher",
    ATTRIBUTE_TYPE_TEXT_TYPE = "text-type",
    ATTRIBUTE_TYPE_MULTI_LEVEL_SET = "multi-level-set"
}

interface LRTagBase extends BaseRecord {
    name: string
    shortName: string
    translatedName: string // not sure why this is a thing but I guess it is???
    esName: string // spanish name
    zhCnName: string //simple chinese name
    zhHkName: string // traditional chinese name
    dateUpdated: number
    dateCreated: number
    referenceId: string
}
export interface LearningResourceTag extends LRTagBase {
    type: LEARNING_RESOURCE_TAG_TYPE
}

export interface LearningResourceAttribute extends LRTagBase {
    type: LEARNING_RESOURCE_ATTRIBUTE_TYPE
}

export enum ASSESSMENT_EXTERNAL_QUESTION_SOURCE_TYPE {
    PDF = "pdf",
    KEYBOARD = "keyboard"
}

export interface AssessmentExternalQuestionSource extends BaseRecord {
    name: string
    isActive: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    slug: string
    type: ASSESSMENT_EXTERNAL_QUESTION_SOURCE_TYPE
}

export interface AssessmentGenericQuestionSource extends BaseRecord {
    isActive: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    name: string
    slug: string
    textSizing: string
    passage: string
    passageAudio: string
    preserveQuestionOrder: boolean
}

export interface AssessmentFluencyAssessment extends BaseRecord {
    isActive: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    name: string
    slug: string
    instructions: string
    passage: string
    runningRecord: string
    errorCountFieldNumber: number | null
    characterCount: number | null
    multipleObjectives: boolean
}

export interface AssessmentMultipleChoiceAssessment extends BaseRecord {
    isActive: boolean
    createdAtMs: number
    updatedAtMs: number
    createdById: string
    updatedById: string
    name: string
    slug: string
    passage: string
    passageAudio: string
    textSizing: string
}

export interface Assessment extends BaseRecord {
    name: string
    catalogItemId: string
    catalogItemTypeId: string
    slug: string
    isActive: boolean
    timeLimit: number
    type: string
    updatedAtMs: number
    updatedById: string
    versionDescription: string
    versionSortOrder: number
    questionSourceTypeId: string
    numberOfQuestions: number
    passingScore: number
    questionSourceId: string
    instructionsAudio: string
    instructionsAlternate: string
    instructions: string
    createdAtMs: number
    createdById: string
    approachingMasteryScore?: number
}

export enum LEARNING_CENTER_MEDIAFILE_TYPE {
    PDF = "pdf",
    THUMBNAIL = "thumbnail"
}

export interface LearningCenterMediafile extends BaseRecord {
    createdAtMs: number
    updatedAtMs: number
    file: string
    type: string
}

export enum MASTERY_STATUS {
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    NOT_MASTERED = "not-mastered",
    NEAR_MASTERED = "near-mastered",
    MASTERED = "mastered",
    SUFFICIENT = "sufficient"
}

export interface LearningCenterRelatedResources extends BaseRecord {
    fromId: string
    toId: string
}

export interface AssessmentBankedQuestion extends BaseRecord {
    bankId: string
    content: string
    metaData: string
    weight: string
    generatorTypeId: string
    generatorId: string
    question_audio: string
    image: string
}
export interface AssessmentBankedQuestionsObjectivesTested {
    id: string
    bankedQuestionId: string
    objectiveId: string
}

export interface AssessmentResponseOption extends BaseRecord {
    id: string
    bankedQuestionId: string
    content: string
    key: string
    image: string
    response_audio: string
}

export enum LEARNING_CENTER_ASSIGNMENT_STATUS {
    ASSIGNED = "assigned",
    DONE = "done",
    IN_PROGRESS = "in-progress"
}

export interface LearningCenterQuizQuestionDataComposite {
    question: AssessmentBankedQuestion
    answers: AssessmentResponseOption[]
    correctAnswer: AssessmentResponseOption | undefined
    questionText: string
}
export interface LearningCenterQuizComposite extends Assessment {
    questionData: LearningCenterQuizQuestionDataComposite[]
}

export interface LearningResourceTagJoinTable extends BaseRecord {
    learningResourceId: string
    resourceTagId: string
}

export interface LearningCenterBookUsage extends BaseRecord {
    createdAtMs: number
    updatedAtMs: number
    lastReadMs: number | null
    bookId: string
    status: string
    publisherId: string
    userId: string
    pageViewCount: number
    resumeAt: number
    furthestProgress: number
    isBackfilled: boolean | null
    coinsEarned: number | null
    xpEarned: number | null
    rewardMessage: BOOK_USAGE_REWARD_MESSAGE
    v3TakeId: string
}

export enum BOOK_USAGE_STATUS {
    IN_PROGRESS = "in-progress",
    DONE = "done",
    ASSIGNMENT_ASSIGNED = "assignment-assigned",
    ASSIGNMENT_INPROGRESS = "assignment-in-progress",
    ASSIGNMENT_DONE = "assignment-done"
}

export const REWARD_VALUES = {
    MAX_BOOKS_PER_WEEK: 10,
    COINS_PER_BOOK: 1,
    XP_PER_BOOK: 25
}

export enum BOOK_USAGE_REWARD_MESSAGE {
    REWARD_NONE = "reward-none",
    REWARD_CAP_REACHED = "reward-cap-reached",
    REWARD_BOOK_ALREADY_READ = "reward-book-already-read",
    REWARD_GIVEN = "reward-given"
}

export interface LearningCenterPageAudio extends BaseRecord {
    createdAtMs: number
    updatedAtMs: number
    resourceId: string
    pageNumber: number
    file: string
}

export interface LearningResourceQuizObjective extends BaseRecord {
    learningResourceId: string
    objectiveId: string
}
export interface LearningCenterLearningResourceFavoritedBy {
    id: string
    learningResourceId: string
    profileId: string
}

export enum COURSE_TYPE {
    FICTION = "Fiction",
    NON_FICTION = "Non Fiction"
}

export interface LLCatalog {
    courses: Course[]
    units: CourseUnitFlat[]
    topics: CatalogTopic[]
    concepts: CourseConceptFlat[]
    objectives: Objective[]
}

export const BLANK_RESPONSE = "N/A"

export interface LearningResourceUrls {
    slug: string
    book: string
    cover: string
    pageAudio: { pageNumber: number; url: string }[]
}

export interface MiniLesson {
    id: string
    name: string
    languageCode: LANGUAGE_CODE
    type: string
    hasPdfActivities: boolean
    hasPdfTeacherGuide: boolean
    hasVideo: boolean
    hasVideoTeacher: boolean
    validFolderNaming: boolean
    objectiveShortIds: string[]
    objectiveUUIDs: string[]
    valid: boolean
    errors: string[]
    isActive: boolean
    isPlaceholderVideo: boolean
    text: {
        [LANGUAGE_CODE.ENGLISH]: string
        [LANGUAGE_CODE.SPANISH]: string
        [LANGUAGE_CODE.CHINESE_SIMPLIFIED]: string
        [LANGUAGE_CODE.CHINESE_TRADITIONAL]: string
    }
}

export interface GetFilteredCatalogParams {
    objectiveIds?: string[]
    objectiveUUIDs?: string[]
    conceptIds?: string[]
    courseSlug?: string
    topicIds?: string[]
    courseId?: string
    courseIds?: string[]
    languageCode?: LANGUAGE_CODE
    conceptTypes?: CONCEPT_TYPE[]
}
