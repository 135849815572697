export const SpecialColors = {
    sunrise: "linear-gradient(180deg, #FFF500 0%, #FAE210 52.08%, #E79800 100%)",
    flairGold: "linear-gradient(138.72deg, #FFC70B 10.84%, #FFFCAC 91.38%)",
    xp: "#B640FF",
    actionCard: "#FF9900",
    busy: "#61B3FF",
    available: "#00CBA1",
    p1Shadow: "#000dFF",
    p2Shadow: "#D4331E",
    strokeGlow: {
        boxShadow: "2px 2px 2px #FBE07F",
        filter: "blur(1px)",
        borderRadius: 4
    },
    goldBorder: {
        border: "10px solid",
        borderImageSlice: 1,
        borderWidth: 5,
        borderImageSource: "linear-gradient(138.72deg, #FFC70B 10.84%, #FFFCAC 91.38%)"
    },
    chatBackground: "linear-gradient(180deg, #0E78DA 0%, #57AEFF 100%)",
    disabledText: "#757575"
}
