import { difference, flatten } from "lodash"

export default function permutation<T>(array: T[], n?: number, chosen: T[] = []): T[][] {
    const size = n === undefined ? array.length : n
    const remaining = difference(array, chosen)
    if (size <= 0) {
        return [chosen]
    }
    if (remaining.length < size) {
        return []
    }
    if (remaining.length === n) {
        return [[...chosen, ...remaining]]
    }

    const results = remaining.map(value => permutation(array, size - 1, [...chosen, value]))
    return flatten(results)
}
