import { BarDatum, BarTooltipProps } from "@nivo/bar"
import { ReactElement } from "react"
import { BaseRecord, LEARNING_MODULE_SKILL } from "./baseTypes"
import { Objective } from "./learningTypes"
import { CONCEPT_TYPE, V3_MASTERY_STATUS } from "./masteryTypes"
import { CatalogTopic, GameGroup, Student } from "./studentTypes"
import { TakeResponse, V3Prompt, V3Take } from "./testTypes"

export type ESObjective = Pick<Objective, "id" | "name" | "shortIdentifier">

export type AnalyticsChartType = "stacked-bar" | "bar" | "line" | "pie" | "doughnut" | "radar" | "polarArea" | "bubble" | "scatter" | "horizontalBar"

export enum ANALYTICS_CHART_TYPE {
    STACKED_BAR = "stacked-bar",
    BAR = "bar",
    LINE = "line",
    PIE = "pie",
    DOUGHNUT = "doughnut",
    RADAR = "radar",
    POLAR_AREA = "polarArea",
    BUBBLE = "bubble",
    SCATTER = "scatter",
    HORIZONTAL_BAR = "horizontalBar"
}

export interface AnalyticsFilter {
    schoolIds: string[]
    gradeIds: string[]
    courses: { courseId: string; conceptType: CONCEPT_TYPE }[]
    groupIds: string[]
    dateRanges: UserDefinedDateRange[]
}

export interface AnalyticsDateComparison {
    dateStartMs: number
    dateEndMs: number
}

export interface AnalyticsChartData {
    labels: string[]
    yLabel: string
    title: string
    datasets: AnalyticsChartDataset[]
    subtitle: string
    tooltip?: React.FC<BarTooltipProps<BarDatum>>
}
export interface ReportData {
    title: string
    subheading: string
    ChartData: AnalyticsChartData[] | ReportData[]
}

export interface AnalyticsDisplayChartData {
    chartData: AnalyticsChartData
    title: string
    subtitle: string
}
export interface AnalyticsChartDataset {
    label: string
    data: number[]
    backgroundColor: string
    borderColor?: string
    borderWidth?: number
    hoverBackgroundColor?: string
    hoverBorderColor?: string
}

export interface AnalyticsChartOptions {
    margin?: Partial<{
        top: number
        right: number
        bottom: number
        left: number
    }>
    height?: number
    tooltip?: (params: { id: string | number; value: number; color: string }) => ReactElement
    labelFormat?: (v: number | string) => string
    valueFormat?: (v: number) => string
}

export enum ANALYTICS_MASTERY_STATUS_CHANGE_EVENT_CAUSE_TYPE {
    AUTO_GRADE = "auto-grade",
    MANUAL = "manual",
    RESTORE = "restore"
}

export enum ANALYTICS_MASTERY_STATUS_CHANGE_EVENT_ITEM_TYPE {
    CONCEPT = "concept",
    OBJECTIVE = "objective"
}

export interface AnalyticsMasteryStatusChangeEvent {
    id: string
    studentUUID: string | null
    itemUUID: string
    masteryStatus: string
    eventTimeMs: number
    takeId: string | null
    itemType: ANALYTICS_MASTERY_STATUS_CHANGE_EVENT_ITEM_TYPE | null
    causeType: ANALYTICS_MASTERY_STATUS_CHANGE_EVENT_CAUSE_TYPE | null
}

export enum ANALYTICS_EVENT_TYPE {
    UPDATE = "UP",
    DELETE = "DE"
}

export interface AnalyticsGroupChangeEvent {
    id: string
    eventType: ANALYTICS_EVENT_TYPE
    eventUUID: string
    groupUUID: string
    eventTimeMs: number
}

export interface AnalyticsGroupFaculty {
    id: string
    eventUUID: string
    facultyUUID: string
}

export interface AnalyticsGroupStudent {
    id: string
    eventUUID: string
    studentUUID: string
}

export interface AnalyticsGroupCourse {
    id: string
    eventUUID: string
    courseUUID: string
}

export interface AnalyticsGroupDetail {
    id: string
    eventUUID: string
    groupName: string
    schoolUUID: string
    schoolName: string
    districtUUID: string
    districtName: string
}

export enum TEST_TYPE {
    ASSESSMENT = "ASSESSMENT",
    ASSIGNMENT = "ASSIGNMENT"
}

export interface CourseToTopic {
    name: string
    id: string
    topics: string[]
}
export interface CourseToTopicDetailed {
    name: string
    id: string
    topics: Pick<CatalogTopic, "name" | "id">[]
}

export interface CourseToTopicMap {
    [courseId: string]: CourseToTopicDetailed
}

export interface UserDefinedDateRange extends BaseRecord {
    districtId: string
    districtName: string
    dateStartMs: number
    dateEndMs: number
    name: string
    createdById: string
    createdAtMs: number
}

export interface GradeBenchmark extends BaseRecord {
    districtId: string
    courseId: string
    gradeId: string
    topicId: string
    conceptType: CONCEPT_TYPE
    createdById: string
    createdAtMs: number
    dateRangeId: string | null
}

export interface BenchmarkWithCourseId extends GradeBenchmark {
    courseId: string
}

export interface Grade extends BaseRecord {
    id: string
    name: string
    number: number
    createdAtMs: number
    createdById: string
    order: number
}

export interface TopicMasteryByGrade {
    gradeId: string
    topicId: string
    topicName: string
    numStudentsInGrade: number
    numStudentsMastered: number
    numStudentsApproaching: number
    numStudentsInstructional: number
    numStudentsBelow: number
    numStudentsExceeds: number
}

export interface AnalyticsIdNamePair extends BaseRecord {
    name: string
}

export enum TOPIC_STATUS {
    APPROACHING = "APPROACHING",
    EXCEEDED = "EXCEEDED",
    BELOW = "BELOW",
    MET = "MET",
    INCOMPLETE = "INCOMPLETE",
    NO_RECORD = "NO_RECORD",
    NO_LEVEL = "NO_LEVEL"
}

export enum CATALOG_LEVEL {
    OBJECTIVE = "OBJECTIVE",
    CONCEPT = "CONCEPT",
    TOPIC = "TOPIC",
    COURSE = "COURSE"
}

// intermediary type
// server will generate this from either RDS or opensearch
// this is so we can swap sources if we can just change this part
// then GROUP_BY functions below can use this same input
export interface AnalyticsMastery2 {
    dateRangeId: string
    dateRangeName: string

    // student info
    studentId: string
    studentFirstName: string
    studentLastName: string
    gradeId: string
    gradeName: string
    teachers: AnalyticsIdNamePair[]
    groupId: string
    groupName: string
    segmentId: string
    schoolId: string
    schoolName: string
    districtId: string
    districtName: string

    // mastery info
    dateMs: number
    mastered: boolean
    dateUpdatedMs: number
    approachingMastery: boolean

    catalogLevel: CATALOG_LEVEL

    objectiveId: string
    objectiveName: string
    objectiveNameTranslated: string
    objectiveShortIdentifier: string
    objectiveOrder: number

    conceptId: string
    conceptName: string

    topicId: string
    topicName: string
    topicOrder: number
    instructionalLevelId: string
    instructionalLevelName: string
    instructionalLevelStatus: TOPIC_STATUS
    benchmarkId: string
    benchmarkStatus: TOPIC_STATUS

    courseId: string
    courseName: string

    stale: boolean
}

export interface AnalyticsMastery extends V3MasteryStatusChangeEvent {
    dateRangeId: string
    dateRangeName: string

    // mastery info
    mastered: boolean
    approachingMastery: boolean

    studentId: string
    objectiveId: string
    conceptId: string
    topicId: string
    courseId: string
    // teacherIds: string[]
    schoolId: string
    districtId: string
    groupId: string
    groupName: string

    objectiveNameTranslated: string
    objectiveShortIdentifier: string
    objectiveOrder: number

    topicOrder: number

    instructionalLevelId: string
    instructionalLevelName: string
    instructionalLevelStatus: TOPIC_STATUS

    benchmarkId: string
    benchmarkStatus: TOPIC_STATUS
}

export enum ANALYTICS_GROUP_BY {
    GRADE = "GRADE",
    INSTRUCTIONAL_LEVEL = "INSTRUCTIONAL_LEVEL"
}

export interface AnalyticsResponse<T> {
    totalStudents: number
    data: T
}

export interface AnalyticsResult<T> {
    students: Student[]
    data: T
}

export interface AnalyticsHighestLevelMasteredData {
    dateRange?: UserDefinedDateRange
    dateRangeId: string
    topicId: string
    topicName: string
    courseId: string
    conceptType: CONCEPT_TYPE
    studentId: string
}

export interface AnalyticsHighestLevelMasteredDataGrouped {
    dateRange?: UserDefinedDateRange
    dateRangeId: string
    topicId: string
    topicName: string
    courseId: string
    conceptType: CONCEPT_TYPE
    groupId: string
    groupName: string
    groupSize: number
    numStudents: number
}

export interface AnalyticsHighestLevelMasteredDataGroupedProgress {
    dateRangeIds: string[]
    numLevelsProgressed: number
    courseId: string
    conceptType: CONCEPT_TYPE
    groupId: string
    groupName: string
    groupSize: number
    numStudents: number
}

export interface AnalyticsMasteryGrouped {
    bucketId: string
    bucketName: string
    dateRangeId: string
    dateRangeName: string
    groupedBy: ANALYTICS_GROUP_BY
    topicId: string
    topicName: string
    courseId: string
    courseName: string
    conceptType: CONCEPT_TYPE
    objectiveId: string
    objectiveName: string
    objectiveNameTranslated: string
    objectiveShortIdentifier: string
    numStudentsInBucket: number
    numStudentsMastered: number
    numStudentsNotMastered: number
    numStudentsApproaching: number
    numStudentsInstructional: number
    numStudentsBelow: number
    numStudentsExceeds: number
    numStudentsIncomplete: number
    numStudentsNoData: number
}

export interface ObjectiveMasteryByInstructionalLevel {
    topicId: string
    topicName: string
    courseId: string
    objectiveMastery: {
        objectiveId: string
        objectiveName: string
        numStudentsMastered: number
        numStudentsNotMastered: number
    }[]

    numStudentsInTopic: number
}

export interface RangeMasteryData {
    dateRange: UserDefinedDateRange
    courseId: string
    topicMastery: TopicMasteryByGrade[]
    objectiveMastery: TopicMasteryByGrade[]
}

export interface AnalyticsQuery {
    filter: AnalyticsFilter
    groupedBy: ANALYTICS_GROUP_BY
}

export interface AnalyticsQueryResult<T> {
    data: T
    studentIds: string[]
}

export interface V3MasteryStatusChangeEvent extends BaseRecord {
    studentUUID: string
    studentName: string
    studentFirstName: string
    studentLastName: string
    studentCohort: number | null
    schoolUUID: string
    schoolName: string
    districtUUID: string
    districtName: string
    groupUUIDs: string[]
    groupUUID: string
    groupNames: string[]
    gradeId: string
    gradeName: string
    segmentIds: string[]
    segmentNames: string[]
    teacherUUIDs: string[]
    teacherNames: string[]

    // i forget what this was supposed to be
    // testType: string

    dateMs: number
    skill: LEARNING_MODULE_SKILL
    productId: string
    productName: string

    masteryStatus: V3_MASTERY_STATUS
    catalogLevel: CATALOG_LEVEL

    // catalog info
    objectiveUUID: string
    objectiveName: string
    conceptUUID: string
    conceptName: string
    conceptType: CONCEPT_TYPE
    topicUUID: string
    topicName: string
    unitUUID: string
    unitName: string
    courseUUID: string
    courseName: string

    // book info
    sourceId: string
    sourceName: string

    // prompt info
    v3PromptId: string
    v3Prompt?: V3Prompt
    v3Response?: TakeResponse

    v3TakeId: string

    takeScore: number
    takeTotalPossibleScore: number
    takePassed: boolean

    v3TestId: string
    v3TestName: string

    highestLevelMasteredConceptUUID: string
    highestLevelMasteredConceptName: string

    instructionalLevelConceptUUID: string
    instructionalLevelConceptName: string

    highestLevelMasteredTopicUUID: string
    highestLevelMasteredTopicName: string

    instructionalLevelTopicUUID: string
    instructionalLevelTopicName: string

    isInstructionalLevelEvent: boolean
}

type V3GroupChangeEventAttributes = Pick<
    GameGroup,
    "name" | "slug" | "schoolYear" | "classroom" | "gradeId" | "identifier" | "remoteName" | "remoteIdentifier" | "isActive"
>
export interface V3GroupChangeEvent extends BaseRecord {
    id: string
    groupUUID: string
    facultyUUIDs: string[]
    facultyNames: string[]
    studentUUIDs: string[]
    studentNames: string[]
    schoolUUID: string
    schoolName: string
    districtUUID: string
    districtName: string
    productIds: string[]
    productNames: string[]
    courseUUIDs: string[]
    courseNames: string[]
    dateMs: number
    attributes: V3GroupChangeEventAttributes
}

export interface AddResultParams {
    objectiveId: string
    profileId: string
    totalCorrect: number
    totalQuestions: number
    dateMs?: number
    timeTakenMs?: number
    v3Take?: V3Take
    v3Prompt?: V3Prompt
    v3Response?: TakeResponse
    isAssessment?: boolean // purely for FLP Tool use. Overrides the need for v3Take??
}

export interface AddResultsParams extends Omit<AddResultParams, "totalCorrect" | "totalQuestions"> {
    results: number[]
}

export interface AnalyticsStudentMastery {
    courseId: string
    conceptType: string
    studentId: string
    instructionalLevelConceptId: string
    highestLevelConceptId: string
    objectivesNotMasteredIds: string[]
    objectivesMasteredIds: string[]
}

export interface AnalyticsInstructionalLevelMastery {
    instructionalLevelConceptId: string
    numStudentsInInstructionalLevel: number
    objectiveId: string
    numStudentsNotMastered: number
}

export interface AnalyticsInstructionalLevelMasteryCourse {
    courseId: string
    conceptType: string
    numStudents: number
    data: AnalyticsInstructionalLevelMastery[]
}

export interface AnalyticsCompliance {
    courseId: string
    conceptType: CONCEPT_TYPE
    studentIdsAssessedInDateRange: string[]
    studentIdsNotAssessedInDateRange: string[]
}
