import { isNaN, isNumber, isString, sortBy } from "lodash"

export function toDate(d: Date | string | number) {
    let date: Date
    if (typeof d === "string") {
        const a = d.split(/\D+/).map(d => parseInt(d, 10))
        const dateFromIsoString = new Date(Date.UTC(a[0], a[1] - 1, a[2], a[3], a[4], a[5], a[6]))
        const ms = dateFromIsoString.getTime()
        if (isNaN(ms)) {
            throw new Error(`Invalid ISO string provided: ${d}`)
        }
        date = dateFromIsoString
    } else {
        date = new Date()
        date.setTime(typeof d === "number" ? d : d.getTime())
    }
    return date
}

function dateStr(date: Date) {
    return date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" })
}

function timeStr(date: Date) {
    return date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
}

export default function formatDate(d: Date | string | number) {
    const date = toDate(d)
    return [dateStr(date), timeStr(date)].join(" ")
}

export function formatDateRange(d1: Date | string | number, d2: Date | string | number) {
    const [first, second] = sortBy([d1, d2].map(toDate), d => d.getTime())
    const sameYear = first.getFullYear() === second.getFullYear()
    const sameMonth = sameYear && first.getMonth() === second.getMonth()
    const sameDate = sameMonth && first.getDate() === second.getDate()
    if (sameDate) {
        const timeStr = [first, second].map(f => f.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })).join(" - ")
        return [dateStr(first), timeStr].join(" ")
    } else {
        return [first, second].map(formatDate).join(" - ")
    }
}

export function formatTime(timeInSeconds: number) {
    if (!timeInSeconds) {
        return "00:00"
    }

    const min = Math.floor(timeInSeconds / 60)
    const sec = Math.floor(timeInSeconds % 60).toString()
    return `${min}:${sec.padStart(2, "0")}`
}

export const dateToFormattedString_mmddyyyy = (date: string | number | Date) => {
    let d: Date
    if (isString(date) || isNumber(date) || date instanceof Date) {
        d = new Date(date)
        if (isNaN(d.getTime())) {
            d = toDate(date)
        }
    } else {
        d = date
    }

    const year = d.getFullYear()
    const month = (1 + d.getMonth()).toString().padStart(2, "0")
    const day = d.getDate().toString().padStart(2, "0")
    return [month, day, year].join("/")
}

export const dateToFormattedString_mmmyyyy = (date: string | number | Date) => {
    let d: Date
    if (isString(date) || isNumber(date) || date instanceof Date) {
        d = new Date(date)
        if (isNaN(d.getTime())) {
            d = toDate(date)
        }
    } else {
        d = date
    }

    const year = d.getFullYear()
    const month = d.toLocaleString("default", { month: "long" })
    return [month, year].join(" ")
}
