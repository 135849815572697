import { every } from "lodash"

export function arrPickRoundRobin<T>(arrays: T[][], numToPick: number): T[] {
    const result: T[] = []

    const arrs = arrays.map(v => v.slice())
    const arrsDrained = () => every(arrs, a => !a.length)

    let arrIdx = 0
    while (result.length < numToPick && !arrsDrained()) {
        const item = arrs[arrIdx].shift()
        if (item !== undefined) {
            result.push(item)
        }

        arrIdx++
        if (arrIdx >= arrs.length) {
            arrIdx = 0
        }
    }

    return result
}
