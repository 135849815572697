interface TimerParams {
    fn: () => Promise<any>
    interval: number
    exitOnError?: boolean
}

const fn = (params: TimerParams): (() => void) => {
    let ended = false
    let timeoutId: NodeJS.Timeout | undefined = undefined

    const stopTimer = () => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        ended = true
    }

    const looper = async () => {
        if (ended) {
            return
        }
        try {
            await params.fn()
        } catch (e) {
            if (params.exitOnError) {
                throw e
            } else {
                console.error(e)
            }
        }
        timeoutId = setTimeout(looper, params.interval)
    }
    looper()

    return stopTimer
}

export default fn
