export async function retryFunction<T>(fn: () => Promise<T>, retries: number = 1, attempts: number = 0, errors: Array<any> = []): Promise<T> {
    if (attempts > retries) {
        errors.push(new Error("Reached max retries on fn"))
        throw errors
    }
    try {
        const result = await fn()
        return result
    } catch (emAll) {
        errors.push(emAll)
        return retryFunction(fn, retries, attempts + 1, errors)
    }
}

export function retryFunctionWrapper<T>(fn: () => Promise<T>, retries: number = 1): () => Promise<T> {
    return () => retryFunction(fn, retries)
}
