export enum PRODUCT_ID {
    READING_CHINESE_SIMPLIFIED = "1",
    READING_CHINESE_TRADITIONAL = "2",
    SPEAKING_CHINESE = "3",
    WRITING_CHINESE_SIMPLIFIED = "4",
    WRITING_CHINESE_TRADITIONAL = "5",
    READING_SPANISH = "6",
    READING_SPANISH_AID = "7",
    WRITING_SPANISH = "8",
    READING_ENGLISH = "9",
    PHONICS_SPANISH = "10",
    PHONICS_ENGLISH = "11",
    PHONICS_CHINESE_SIMPLIFIED = "12",
    PHONICS_CHINESE_TRADITIONAL = "13",
    READING_ENGLISH_AID = "14",
    READING_CHINESE_SIMPLIFIED_AID = "15",
    READING_CHINESE_TRADITIONAL_AID = "16",
    WRITING_ENGLISH = "17",
    SPEAKING_ENGLISH = "18",
    SPEAKING_SPANISH = "19"
}

export const PHONICS_PRODUCT_IDS = [
    PRODUCT_ID.PHONICS_CHINESE_SIMPLIFIED,
    PRODUCT_ID.PHONICS_CHINESE_TRADITIONAL,
    PRODUCT_ID.PHONICS_ENGLISH,
    PRODUCT_ID.PHONICS_SPANISH
]
