import { LANGUAGE_CODE } from "./base/languages"
import { Course, CourseWithUnits } from "./learningTypes"
import { CatalogTopic } from "./studentTypes"

export enum PROF_LEVEL {
    NONE = "NONE",
    NOVICE_A = "NOVICE_A",
    NOVICE_B = "NOVICE_B",
    NOVICE_C = "NOVICE_C",
    NOVICE_D = "NOVICE_D",
    NOVICE_E = "NOVICE_E",

    // TODO: leave as is for now.
    INTERMEDIATE_F = "INTERMEDIATE_F",
    INTERMEDIATE_G = "INTERMEDIATE_G",
    INTERMEDIATE_H = "INTERMEDIATE_H",
    INTERMEDIATE_I = "INTERMEDIATE_I",
    INTERMEDIATE_J = "INTERMEDIATE_J",
    INTERMEDIATE_K = "INTERMEDIATE_K",
    INTERMEDIATE_L = "INTERMEDIATE_L",
    INTERMEDIATE_M = "INTERMEDIATE_M",
    INTERMEDIATE_N = "INTERMEDIATE_N",
    INTERMEDIATE_O = "INTERMEDIATE_O"
}

export enum GLOSSARY_TYPE {
    VOCAB = "VOCAB",
    EXAMPLES = "EXAMPLES"
}

export interface WithImageAndAudio {
    image: string
    englishAudio: string
    chineseAudio: string
    spanishAudio: string
}

export interface Card extends WithImageAndAudio {
    id: string
    internalName: string

    englishPhrase: string
    chinesePhrase: string
    spanishPhrase: string

    glossaryEnglishAudio: string
    glossaryChineseAudio: string
    glossarySpanishAudio: string

    tags: string[]
    original: any

    facedown?: boolean
}

export interface BaseRecord {
    id: string
}

export enum LEARNING_MODULE_SKILL {
    READING = "reading",
    SPEAKING = "speaking",
    WRITING = "writing"
}

export interface GenericAsset {
    id: string
    image: string
    assetTitle: string
    vimeoEmbedCode_cn: string
    vimeoEmbedCode_en: string
    vimeoEmbedCode_es: string
    audio_cn: string
    audio_en: string
    audio_es: string
}

export interface CourseConfig {
    courseId: string
    displayName: string
}
interface ProductBase extends BaseRecord {
    name: string
    skill: LEARNING_MODULE_SKILL
    relatedCoursesConfig?: CourseConfig[]
    languageCode: LANGUAGE_CODE
}
export interface Product extends ProductBase {
    relatedCourses?: Course[]
}

export interface ProductWithCoursesDeep extends ProductBase {
    relatedCourses?: CourseWithUnits[]
}

export interface PaidSeatsProduct {
    productId: string
    product: Product
    total: number
    used: number
    studentIds?: string[]
}
export interface PaidSeatsCourse {
    courseId: string
    course: Course
    total: number
    used: number
    studentIds?: string[]
}

export interface MetaData {
    dateCreatedMs: number
    dateUpdatedMs: number
    districtId: string | null
    schoolId: string | null
    classroomId: string | null
    createdByUserId: string | null
    updatedByUserId: string | null
    deleted: boolean
}

export interface BaseRecordWithMeta extends BaseRecord {
    metaData: MetaData
}

export interface StateLocation extends BaseRecord {
    name: string
    abbreviation: string
}

export interface ProductTopicCategory extends BaseRecord {
    productId: string
    name: string
    isActive: boolean
    order: number
}
export interface ProductTopicCategoryWithTopics extends ProductTopicCategory {
    topics: CatalogTopic[]
}

export interface ProductTopicCategoryTopic extends BaseRecord {
    productTopicCategoryId: string
    topicId: string
}

export enum SETTINGS_AND_FEATURE_FLAGS {
    FIREBASE_EXPERIMENTAL_AUTODETECT_LONG_POLLING = "FIREBASE_EXPERIMENTAL_AUTODETECT_LONG_POLLING",
    V3_ANALYTICS = "V3_ANALYTICS",
    STALE_DATA_THRESHOLD_DAYS = "STALE_DATA_THRESHOLD_DAYS",
    PHONICS = "PHONICS",
    ASSIGNMENT_POLLING_INTERVAL_MS = "ASSIGNMENT_POLLING_INTERVAL_MS",
    PROFILE_SETTINGS = "PROFILE_SETTINGS",
    SHOW_AUTO_ASSESSMENT_IL_CONTROL = "SHOW_AUTO_ASSESSMENT_IL_CONTROL",
    ASSESSMENTS_VISIBLE = "ASSESSMENTS_VISIBLE", // students
    INCLUDE_FLUENCY = "INCLUDE_FLUENCY", // student
    INCLUDE_AUDIO = "INCLUDE_AUDIO", // student
    INDEPENDENT_LEARNING_SETTING_UNLOCKED = "INDEPENDENT_LEARNING_SETTING_UNLOCKED", // teacher
    INDEPENDENT_LEARNING_ENABLED = "INDEPENDENT_LEARNING_ENABLED" // student
}

export interface ProfileSettings {
    ADVANCED_TABLES?: boolean
}

export interface SettingsAndFeatureFlags extends BaseRecord {
    id: string
    settings: null | {
        [SETTINGS_AND_FEATURE_FLAGS.FIREBASE_EXPERIMENTAL_AUTODETECT_LONG_POLLING]?: boolean
        [SETTINGS_AND_FEATURE_FLAGS.V3_ANALYTICS]?: boolean
        [SETTINGS_AND_FEATURE_FLAGS.STALE_DATA_THRESHOLD_DAYS]?: number
        [SETTINGS_AND_FEATURE_FLAGS.PHONICS]?: boolean
        [SETTINGS_AND_FEATURE_FLAGS.ASSIGNMENT_POLLING_INTERVAL_MS]?: number
        [SETTINGS_AND_FEATURE_FLAGS.PROFILE_SETTINGS]?: ProfileSettings
        [SETTINGS_AND_FEATURE_FLAGS.SHOW_AUTO_ASSESSMENT_IL_CONTROL]?: boolean // school level
        [SETTINGS_AND_FEATURE_FLAGS.ASSESSMENTS_VISIBLE]?: boolean // student level
        [SETTINGS_AND_FEATURE_FLAGS.INCLUDE_FLUENCY]?: boolean // student level
        [SETTINGS_AND_FEATURE_FLAGS.INCLUDE_AUDIO]?: boolean // student level
        [SETTINGS_AND_FEATURE_FLAGS.INDEPENDENT_LEARNING_SETTING_UNLOCKED]?: boolean // student level
        [SETTINGS_AND_FEATURE_FLAGS.INDEPENDENT_LEARNING_ENABLED]?: boolean // student level
        /* Add more here */
    }
    districtId: string | null
    groupId: string | null
    productId: string | null
    profileId: string | null
    schoolId: string | null
}

export interface DistributedLock extends BaseRecord {
    dateCreatedMs: number
    dateUpdatedMs: number
    dateExpirationMs: number
}

export enum S3_USER_DATA_FOLDER {
    SPEAKING = "SCOPED/SPEAKING",
    WRITING = "SCOPED/WRITING",
    HANDWRITTEN_RESPONSES = "SCOPED/manual_grading",
    PROFILE_UPLOADS = `PROFILE_UPLOADS`
}

export * from "./base/languages"
export * from "./base/products"

export const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

export const DEMO_SCHOOL_NAMES = ["Boost EL Demo", "Avanza-Boost DLI Demo", "Mandarin DLI Demo"]
