import { adaptV4Theme, createTheme } from "@mui/material"
import breakpoints from "../config/breakpoints"
import components from "../config/components"
import props from "../config/props"
import spacing from "../config/spacing"

export default createTheme({
    ...adaptV4Theme({
        typography: {
            body1: {
                fontFamily: "Proxima Nova, sans-serif",
                fontSize: 14,
                weight: "normal"
            },
            h2: {
                fontFamily: "Proxima Nova, sans-serif",
                fontWeight: "bold",
                fontSize: 22
            },
            h3: {
                fontFamily: "Proxima Nova, sans-serif",
                fontSize: 20
            },
            h4: {
                fontFamily: "Proxima Nova, sans-serif",
                fontSize: 18,
                textTransform: "none"
            },
            subtitle1: {
                fontFamily: "Proxima Nova, sans-serif",
                fontSize: 16,
                fontWeight: 600
            },
            button: {
                textTransform: "none"
            }
        },
        palette: {
            primary: {
                main: "#00548b",
                light: "#616161",
                dark: "#003c6b",
                contrastText: "#ffffff"
            },
            secondary: {
                main: "#424242",
                contrastText: "#212121"
            },
            error: {
                main: "#EB5757"
            },
            background: {
                default: "#f9f9f9"
            },
            text: {
                primary: "#444444",
                secondary: "#424242",
                disabled: "#9e9e9e"
            },
            divider: "#424242"
        },
        shape: {
            borderRadius: 4
        },
        breakpoints,
        spacing,
        overrides: {
            MuiListItemIcon: {
                root: {
                    minWidth: 40
                }
            },
            MuiListItem: {
                root: {
                    padding: 0
                }
            }
        },
        props
    }),
    components
})
