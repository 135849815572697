import { CONCEPT_TYPE, LANGUAGE_CODE } from "../types"
import { GetFilteredCatalogParams, LLCatalog } from "../types/learningTypes"
import { languageIdToLanguageCode } from "./base/languages"

export function getObjectiveOrder(objectiveId: string, catalog: LLCatalog) {
    const objective = catalog.objectives.find(o => o.id === objectiveId)
    const concept = catalog.concepts.find(c => c.id === objective?.conceptId)
    const topic = catalog.topics.find(t => t.id === concept?.topicId)
    const unit = catalog.units.find(u => u.id === topic?.unitId)
    const course = catalog.courses.find(c => c.id === unit?.courseId)

    const courseOrder = (course?.order || 0) * 100000
    const unitOrder = (unit?.order || 0) * 10000
    const topicOrder = (topic?.order || 0) * 1000
    const conceptOrder = (concept?.order || 0) * 100

    return courseOrder + unitOrder + topicOrder + conceptOrder + (objective?.order || 0)
}

export function getConceptOrder(conceptId: string, catalog: LLCatalog) {
    const concept = catalog.concepts.find(c => c.id === conceptId)
    const topic = catalog.topics.find(t => t.id === concept?.topicId)
    const unit = catalog.units.find(u => u.id === topic?.unitId)
    const course = catalog.courses.find(c => c.id === unit?.courseId)

    const courseOrder = (course?.order || 0) * 100000
    const unitOrder = (unit?.order || 0) * 10000
    const topicOrder = (topic?.order || 0) * 1000

    return courseOrder + unitOrder + topicOrder + (concept?.order || 0)
}

export function getTopicOrder(topicId: string, catalog: LLCatalog) {
    const topic = catalog.topics.find(t => t.id === topicId)
    const unit = catalog.units.find(u => u.id === topic?.unitId)
    const course = catalog.courses.find(c => c.id === unit?.courseId)

    const courseOrder = (course?.order || 0) * 100000
    const unitOrder = (unit?.order || 0) * 10000

    return courseOrder + unitOrder + (topic?.order || 0)
}

export function getUnitOrder(unitId: string, catalog: LLCatalog) {
    const unit = catalog.units.find(u => u.id === unitId)
    const course = catalog.courses.find(c => c.id === unit?.courseId)

    const courseOrder = (course?.order || 0) * 100000
    return courseOrder + (unit?.order || 0)
}

const filters = {
    byCourseSlug: (catalog: LLCatalog, courseSlug: string): LLCatalog => {
        const filteredCourses = catalog.courses.filter(v => courseSlug && courseSlug === v.slug)
        const filteredCourseIds = filteredCourses.map(v => v.id)

        const filteredUnits = catalog.units.filter(v => filteredCourseIds.includes(v.courseId))
        const unitIds = filteredUnits.map(v => v.id)

        const filteredTopics = catalog.topics.filter(t => unitIds.includes(t.unitId))
        const topicIds = filteredTopics.map(v => v.id)

        const filteredConcepts = catalog.concepts.filter(c => topicIds.includes(c.topicId))
        const conceptIds = filteredConcepts.map(v => v.id)

        const filteredObjectives = catalog.objectives.filter(v => conceptIds.includes(v.conceptId))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byCourseIds: (catalog: LLCatalog, courseIds: string[]): LLCatalog => {
        const filteredCourses = catalog.courses.filter(v => courseIds && courseIds.includes(v.id))
        const filteredCourseIds = filteredCourses.map(v => v.id)

        const filteredUnits = catalog.units.filter(v => filteredCourseIds.includes(v.courseId))
        const unitIds = filteredUnits.map(v => v.id)

        const filteredTopics = catalog.topics.filter(t => unitIds.includes(t.unitId))
        const topicIds = filteredTopics.map(v => v.id)

        const filteredConcepts = catalog.concepts.filter(c => topicIds.includes(c.topicId))
        const conceptIds = filteredConcepts.map(v => v.id)

        const filteredObjectives = catalog.objectives.filter(v => conceptIds.includes(v.conceptId))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byObjectiveUUIDs: (catalog: LLCatalog, objectiveUUIDs: string[]): LLCatalog => {
        const filteredObjectives = catalog.objectives.filter(o => o.uuid && objectiveUUIDs.includes(o.uuid))
        const conceptIds = filteredObjectives.map(o => o.conceptId)

        const filteredConcepts = catalog.concepts.filter(c => conceptIds.includes(c.id))
        const topicIds = filteredConcepts.map(c => c.topicId)

        const filteredTopics = catalog.topics.filter(t => topicIds.includes(t.id))
        const unitIds = filteredTopics.map(t => t.unitId)

        const filteredUnits = catalog.units.filter(u => unitIds.includes(u.id))
        const courseIds = filteredUnits.map(u => u.courseId)

        const filteredCourses = catalog.courses.filter(c => courseIds.includes(c.id))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byObjectiveIds: (catalog: LLCatalog, objectiveIds: string[]): LLCatalog => {
        const filteredObjectives = catalog.objectives.filter(o => objectiveIds.includes(o.id))
        const conceptIds = filteredObjectives.map(o => o.conceptId)

        const filteredConcepts = catalog.concepts.filter(c => conceptIds.includes(c.id))
        const topicIds = filteredConcepts.map(c => c.topicId)

        const filteredTopics = catalog.topics.filter(t => topicIds.includes(t.id))
        const unitIds = filteredTopics.map(t => t.unitId)

        const filteredUnits = catalog.units.filter(u => unitIds.includes(u.id))
        const courseIds = filteredUnits.map(u => u.courseId)

        const filteredCourses = catalog.courses.filter(c => courseIds.includes(c.id))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byConceptIds: (catalog: LLCatalog, conceptIds: string[]): LLCatalog => {
        const filteredObjectives = catalog.objectives.filter(o => conceptIds.includes(o.conceptId))
        const filteredConcepts = catalog.concepts.filter(c => conceptIds.includes(c.id))
        const topicIds = filteredConcepts.map(c => c.topicId)

        const filteredTopics = catalog.topics.filter(t => topicIds.includes(t.id))
        const unitIds = filteredTopics.map(t => t.unitId)

        const filteredUnits = catalog.units.filter(u => unitIds.includes(u.id))
        const courseIds = filteredUnits.map(u => u.courseId)

        const filteredCourses = catalog.courses.filter(c => courseIds.includes(c.id))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byTopicIds: (catalog: LLCatalog, topicIds: string[]): LLCatalog => {
        const filteredTopics = catalog.topics.filter(t => topicIds.includes(t.id))

        const filteredConcepts = catalog.concepts.filter(c => topicIds.includes(c.topicId))
        const conceptIds = filteredConcepts.map(v => v.id)

        const filteredObjectives = catalog.objectives.filter(o => conceptIds.includes(o.conceptId))

        const unitIds = filteredTopics.map(t => t.unitId)
        const filteredUnits = catalog.units.filter(u => unitIds.includes(u.id))

        const courseIds = filteredUnits.map(u => u.courseId)
        const filteredCourses = catalog.courses.filter(c => courseIds.includes(c.id))

        return {
            objectives: filteredObjectives,
            concepts: filteredConcepts,
            topics: filteredTopics,
            units: filteredUnits,
            courses: filteredCourses
        }
    },
    byConceptTypes: (catalog: LLCatalog, conceptTypes: CONCEPT_TYPE[]): LLCatalog => {
        const concepts = catalog.concepts.filter(v => conceptTypes.includes(v.type))
        const conceptIds = concepts.map(v => v.id)

        const topicIds = concepts.map(c => c.topicId)
        const topics = catalog.topics.filter(t => topicIds.includes(t.id))

        const unitIds = topics.map(t => t.unitId)
        const units = catalog.units.filter(u => unitIds.includes(u.id))

        const courseIds = units.map(u => u.courseId)
        const courses = catalog.courses.filter(c => courseIds.includes(c.id))

        const objectives = catalog.objectives.filter(v => conceptIds.includes(v.conceptId))

        return {
            courses,
            units,
            topics,
            concepts,
            objectives
        }
    },
    byLangCode: (catalog: LLCatalog, langCode: LANGUAGE_CODE): LLCatalog => {
        const courses = catalog.courses.filter(v => languageIdToLanguageCode(v.languageId) === langCode)
        const courseIds = courses.map(v => v.id)
        return filters.byCourseIds(catalog, courseIds)
    }
}

export function getFilteredCatalog(catalog: LLCatalog, filterParams?: GetFilteredCatalogParams): LLCatalog {
    // making this fn where any combination is acceptable
    let filteredCatalog = catalog

    if (filterParams?.courseSlug) {
        filteredCatalog = filters.byCourseSlug(filteredCatalog, filterParams.courseSlug)
    }
    if (filterParams?.courseIds) {
        filteredCatalog = filters.byCourseIds(filteredCatalog, filterParams.courseIds)
    }
    if (filterParams?.courseId) {
        filteredCatalog = filters.byCourseIds(filteredCatalog, [filterParams.courseId])
    }
    if (filterParams?.languageCode) {
        filteredCatalog = filters.byLangCode(filteredCatalog, filterParams.languageCode)
    }
    if (filterParams?.topicIds) {
        filteredCatalog = filters.byTopicIds(filteredCatalog, filterParams.topicIds)
    }
    if (filterParams?.conceptTypes) {
        filteredCatalog = filters.byConceptTypes(filteredCatalog, filterParams.conceptTypes)
    }
    if (filterParams?.conceptIds) {
        filteredCatalog = filters.byConceptIds(filteredCatalog, filterParams?.conceptIds)
    }
    if (filterParams?.objectiveIds) {
        filteredCatalog = filters.byObjectiveIds(filteredCatalog, filterParams?.objectiveIds)
    }
    if (filterParams?.objectiveUUIDs) {
        filteredCatalog = filters.byObjectiveUUIDs(filteredCatalog, filterParams?.objectiveUUIDs)
    }

    return filteredCatalog
}
