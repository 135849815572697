import { breakpoints } from "style"

export const getDeviceSize = () => {
    const { sm, md, lg } = breakpoints.values || {}
    if (window.matchMedia(`(max-width: ${sm}px)`).matches) {
        return "Mobile"
    }
    if (window.matchMedia(`(min-width: ${sm}px) and (max-width: ${md}px)`).matches) {
        return "Tablet"
    }
    if (window.matchMedia(`(min-width: ${md}px) and (max-width: ${lg}px)`).matches) {
        return "Laptop"
    }
    return "Desktop"
}

export const getDeviceOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
        return "Portrait"
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
        return "Landscape"
    }
    return "Unknown"
}

export const setRootDeviceClass = () => {
    const deviceSize = getDeviceSize()
    const deviceOrientation = getDeviceOrientation()
    document.body.classList.remove("Mobile", "Tablet", "Laptop", "Desktop", "Portrait", "Landscape", "Unknown")
    document.body.classList.add(deviceSize, deviceOrientation)
}
