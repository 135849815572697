import { Dictionary, difference, flatMap, uniq } from "lodash"

export interface GenericMembership {
    id: string
    belongsTo: string[]
}

export const genericMembershipDifference = (existingObjects: GenericMembership[], setContainerIds: string[]) => {
    const add: Dictionary<string[]> = {}
    const remove: Dictionary<string[]> = {}

    const containerIdsAfter = uniq(setContainerIds.sort())

    existingObjects.forEach(obj => {
        const containerIdsBefore = obj.belongsTo

        const containerIdsAdded = difference(containerIdsAfter, containerIdsBefore)
        containerIdsAdded.forEach(c => {
            add[c] = add[c] || []
            add[c].push(obj.id)
        })

        const containerIdsRemoved = difference(containerIdsBefore, containerIdsAfter)
        containerIdsRemoved.forEach(c => {
            remove[c] = remove[c] || []
            remove[c].push(obj.id)
        })
    })

    return {
        add,
        remove
    }
}

export const membershipByContainer = (membership: GenericMembership[]) => {
    const containers: Dictionary<string[]> = {}
    const uniqContainerIds = uniq(flatMap(membership, m => m.belongsTo))
    uniqContainerIds.forEach(containerId => {
        containers[containerId] = membership.filter(membership => membership.belongsTo.includes(containerId)).map(m => m.id)
    })
    return containers
}
